// layout
// $screen-xs-max: 				576px;
// $screen-sm-max:				768px;
// $screen-md-max:				992px;
// $screen-lg-max:				1200px;
$screen-xs-max: 600px;
$screen-sm-max: 900px;
$screen-md-max: 1200px;
$screen-lg-max: 1600px;

$screen-sm-min: $screen-xs-max;
$screen-md-min: $screen-sm-max;
$screen-lg-min: $screen-md-max;
$screen-xl-min: $screen-lg-max;

// Opacity
$opaque: 0.9;
$half-opacity: 0.6;
$transparent: 0.4;

// Colors
$white-opaque: rgba(255, 255, 255, $opaque);
$white-half-opacity: rgba(255, 255, 255, $half-opacity);
$white-transparent: rgba(255, 255, 255, $transparent);
$black-opaque: rgba(0, 0, 0, $opaque);
$black-transparent: rgba(0, 0, 0, $transparent);
$black-half-opacity: rgba(0, 0, 0, $half-opacity);

$black: #000000;
$grey-180: #111112;
$grey-160: #212224;
$grey-140: #3a3c3f; // .darker-grey (and .c4btn.dark-grey)
$grey-130: #35363a; // .darker-grey (and .c4btn.dark-grey)
$grey-120: #424548; // .dark-grey
$grey-100: #53565a;
$grey-80: #75787b;
$grey-60: #989a9c;
$grey-40: #babbbd;
$grey-20: #ddddde; // .grey, c4btn.grey
$grey-15: #e5e6e6; // .bg-grey-15
$grey-10: #edeeee; // .bg-grey-10, input
$grey-5: #f6f6f7; // .bg-grey-5, .light-grey
$white: #ffffff; // .white

$red: #c32032;
$dark-red: #8a2432;
$orange: rgb(246, 146, 30);
$dark-orange: rgb(185, 110, 23);
$blue: #3083a2;
$dark-blue: #20576c;
$light-green: #baaf3a;
$green: #8b832b;
$dark-green: #5d581d;

// C4 Colors
$control4-dark-red: #8a2432;
$control4-red: #c32032;

// Pakedge Colors
$pakedge-blue: #418fde;
$pakedge-dark-blue: #0071ce;
$pakedge-green: #97d700;

// Triad Colors
$triad-blue: #0057b8;
$triad-gold-108: #876726;
$triad-gold: #9a7611;
$triad-gold-80: #ab8a49;
$triad-gold-60: #bda16f;

// Chart Colors
$chart-blue: #49a0bf;
$chart-green: #948b23;
$chart-brown: #da5c29;
$chart-purple: #ac8eb3;
$chart-yellow: #f8982f;

// Alert And Status Colors
$success-text: #343809;
$success-icon: #c5b92f;
$success-bg: #e8e3ac;
$warning-text: #414245;
$warning-icon: #e7b538;
$warning-bg: #fbe2a5;
$danger-icon: $control4-dark-red;
$danger-bg: $control4-red;
$autorenew-icon: #49a0bf;
$autorenew-bg: #c8e8f3;

// Dealer Status Colors
$color-authorized: #c32032;
$color-gold: #9a7611;
$color-platinum: #000;
$color-pinnacle: #0071ce;

// Social Media Colors
$color-blog: rgb(246, 146, 30);
$color-facebook: rgb(59, 89, 152);
$color-twitter: rgb(29, 202, 255);
$color-youtube: rgb(184, 32, 56);
$color-pinterest: rgb(203, 32, 39);
$color-instagram: rgb(81, 127, 164);
$color-linkedin: rgb(0, 123, 182);
$color-google-plus: rgb(221, 75, 57);

// CC Colors
$color-visa: #1a1f71;
$color-mastercard: #ff9900;
$color-amex: #007cc3;
$color-discover: #ff6600;

// Standard Unit
$c4unit: 25px;

// Text
$text-color: $black;
$text-dark-grey: $grey-120;
$text-grey: $grey-80;

$sans-serif: "Montserrat", "Gotham", "HanSansSC", "Gotham SSm A", "Gotham SSm B",
	"Helvetica Neue", Calibri, Arial;
$serif: "Georgia", "Garamond Pro", "Palatino Linotype", "Book Antiqua";
$icons: "Font Awesome\ 5 Pro", FontAwesome;

$font-size-base: 15px;
$font-size-small: 12px;

$font-size-ratio-lgdesktop: 1.8;
$font-size-ratio-desktop: 1.7;
$font-size-ratio-tablet: 1.5;
$font-size-ratio-mobile: 1.3;

$header-font-weight-normal: 200;
$font-weight-normal: 400;
$font-weight-bold: 600;
// $font-weight-bold:			700;
// $header-font-weight-bold:	900;

$font-weight-icon-fal: 300;
$font-weight-icon-far: 600;
$font-weight-icon-fas: 900;

@mixin font-reset() {
	color: $text-color;
	font-family: $sans-serif;
	font-size: $font-size-base;
	font-style: normal;
	font-weight: $font-weight-normal;
	letter-spacing: normal;
	line-height: $c4unit;
	text-transform: none;
}

@mixin caps() {
	font-family: $sans-serif;
	font-style: normal;
	text-transform: uppercase;
}

@mixin small-caps() {
	font-family: $sans-serif;
	font-size: $font-size-small;
	font-style: normal;
	font-weight: $font-weight-bold;
	text-transform: uppercase;
}

// Elements
$border-radius: 0px;
$border-style: rgba(0, 0, 0, 0.1);
$shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.2);
$shadow-inset: inset 0px 2px 0px rgba(0, 0, 0, 0.1);

// Animation
$duration-slow: 1s;
$duration-fast: 0.1s;
