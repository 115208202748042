@import "global";

/* Progress bars */

progress[value] {
	width: 100%;
	height: 8px;
	-webkit-appearance: none;
	appearance: none;
	-webkit-border-radius: $border-radius;
	border-radius: $border-radius;
	overflow: hidden;
	border: none;
	background-color: $grey-10;
	color: $grey-100;
	
	&.no-border-radius-left {
		-webkit-border-top-left-radius: 0;
		-webkit-border-bottom-left-radius: 0;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
	
	&.no-border-radius-right {
		-webkit-border-top-right-radius: 0;
		-webkit-border-bottom-right-radius: 0;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}

	&::-webkit-progress-value {
		background-color: $grey-100;
	}

	&::-webkit-progress-bar {
		background-color: $grey-10;
	}
	
	&::-moz-progress-bar { 
		background-color: $grey-100;
	}

	&.authorized,
	&.danger {
		color: $color-authorized;

		&::-webkit-progress-value {
			background-color: $color-authorized;
		}
		&::-webkit-progress-bar {
			background-color: $grey-10;
		}
		&::-moz-progress-bar { 
			background-color: $color-authorized;
		}
	}
	
	&.gold {
		color: $color-gold;

		&::-webkit-progress-value {
			background-color: $color-gold;
		}
		&::-webkit-progress-bar {
			background-color: $grey-10;
		}
		&::-moz-progress-bar { 
			background-color: $color-gold;
		}
	}
	
	&.platinum {
		color: $color-platinum;

		&::-webkit-progress-value {
			background-color: $color-platinum;
		}
		&::-webkit-progress-bar {
			background-color: $grey-10;
		}
		&::-moz-progress-bar { 
			background-color: $color-platinum;
		}
	}
	
	&.pinnacle {
		color: $color-pinnacle;

		&::-webkit-progress-value {
			background-color: $color-pinnacle;
		}
		&::-webkit-progress-bar {
			background-color: $grey-10;
		}
		&::-moz-progress-bar { 
			background-color: $color-pinnacle;
		}
	}

	&.diamond {
		color: $color-pinnacle;

		&::-webkit-progress-value {
			background-color: $color-pinnacle;
		}
		&::-webkit-progress-bar {
			background-color: $grey-10;
		}
		&::-moz-progress-bar { 
			background-color: $color-pinnacle;
		}
	}
	
	&.green,
	&.success {
		color: $light-green;

		&::-webkit-progress-value {
			background-color: $light-green;
			transition-duration: 200ms;
		}
		&::-webkit-progress-bar {
			background-color: $grey-10;
		}
		&::-moz-progress-bar { 
			background-color: $light-green;
		}
	}

	&.warning {
		color: rgba(231, 181, 56, 0.9) !important;

		&::-webkit-progress-value {
			background-color: rgba(231, 181, 56, 0.9) !important;
		}
		&::-webkit-progress-bar {
			background-color: $grey-10;
		}
		&::-moz-progress-bar { 
			background-color: rgba(231, 181, 56, 0.9) !important;
		}
	}

	&.grey {
		color: $grey-100;
		
		&::-webkit-progress-value {
			background-color: $grey-100;
		}
		&::-webkit-progress-bar {
			background-color: $grey-10;
		}
		&::-moz-progress-bar { 
			background-color: $grey-100;
		}
	}
}