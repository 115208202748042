@import "global";

.placeholder-wrapper {
// 	overflow: hidden;

	.placeholder-text {
		position: absolute;
		top: 0;
		left: 0;
		left: 16px;
		opacity: 0;
		color: $text-grey;
		@include small-caps();
		font-size: 11px;
		z-index: 2;

		transition: opacity $duration-fast;
	}

	input {
		transition: all 1s;
	}

	&.placeholder-visible {

		.placeholder-text {
			opacity: 1;
		}

		input {
			padding-top: 16px !important;
			line-height: 34px !important;
		}
	}
}
