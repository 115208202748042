@import "global";
@import "../plugins/bootstrap-4.2.1/bootstrap-grid.modified";

* {
	box-sizing: border-box;
	
	&:before,
	&:after {
		box-sizing: border-box;
	}
}

html.mobile-browser .container {
	padding-left: $c4unit / 2;
	padding-right: $c4unit / 2;
}

body .row,
body .flex-row {
	margin-right: -($c4unit / 2);
	margin-left: -($c4unit / 2);

	&.flex-row {
		display: flex;
		flex-wrap: wrap;
	}

	@media (max-width: $screen-sm-max) {
		&.flex-row {
			flex-direction: column !important;
		}
	}

	[class^="col-"],
	[class*=" col-"],
	[class^="flex-col"],
	[class*=" flex-col-"] {
		padding-right: $c4unit / 2;
		padding-left: $c4unit / 2;

		&[class^="flex-col"],
		&[class*=" flex-col-"] {
			flex: 1 1 auto;

			&.flex-col-1 {
				flex-basis: 100%*1/12;
			}
			&.flex-col-2 {
				flex-basis: 100%*2/12;
			}
			&.flex-col-3 {
				flex-basis: 100%*3/12;
			}
			&.flex-col-4 {
				flex-basis: 100%*4/12;
			}
			&.flex-col-5 {
				flex-basis: 100%*5/12;
			}
			&.flex-col-6 {
				flex-basis: 100%*6/12;
			}
			&.flex-col-7 {
				flex-basis: 100%*7/12;
			}
			&.flex-col-8 {
				flex-basis: 100%*8/12;
			}
			&.flex-col-9 {
				flex-basis: 100%*9/12;
			}
			&.flex-col-10 {
				flex-basis: 100%*10/12;
			}
			&.flex-col-11 {
				flex-basis: 100%*11/12;
			}
			&.flex-col-12 {
				flex-basis: 100%;
			}
		}
	}
	
	&.no-margin {
		margin-right: 0;
		margin-left: 0;
	
		> [class^="col-"],
		> [class*=" col-"],
		> [class^="flex-col"],
		> [class*=" flex-col-"] {
			padding-right: 0 !important;
			padding-left: 0 !important;

			.row {
				margin-right: -($c4unit / 2);
				margin-left: -($c4unit / 2);

				[class^="col-"],
				[class*=" col-"] {
					padding-right: $c4unit / 2;
					padding-left: $c4unit / 2;
				}

				&.no-margin {
					margin-right: 0;
					margin-left: 0;
				
					[class^="col-"],
					[class*=" col-"] {
						padding-right: 0;
						padding-left: 0;
					}
				}
			}
		}
	}
}

.row.show-grid [class^=col-] {
	padding-top: 10px;
	padding-bottom: 10px;
	background-color: #eee;
	background-color: rgba(86,61,124,.15);
	border: 1px solid #ddd;
	border: 1px solid rgba(86,61,124,.2);
}
