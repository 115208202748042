.widget {
	min-height: 0;
}

#livechat-compact-container {
	display: none;
}

.ace_editor  {
height : 400px;
}

.pull-right {
	float: right;
}

/*-------------------------
Dropbox Element
--------------------------*/

#dropbox {
	border-radius: 8px;
	border: red 1px solid;
	position: relative;
	min-height: 290px;
	overflow: hidden;
	padding-bottom: 40px;
	box-shadow: 0 0 4px rgba(0,0,0,1.3) inset,0 6px 2px rgba(0,0,0,0.1);
}

	#dropbox .message {
			font-size: 11px;
			text-align: center;
			display: block;
			color: #ccc;
			font-size: 10px;
	}

	#dropbox:before {
			border-radius: 3px 3px 0 0;
	}

	/*-------------------------
Image Previews
--------------------------*/

	#dropbox .preview {
			width: 120px;
			float: left;
			margin: 25px 0 0 30px;
			position: relative;
			text-align: center;
	}

			#dropbox .preview img {
					max-width: 240px;
					max-height: 180px;
					border: 3px solid #fff;
					display: block;
					box-shadow: 0 0 2px #000;
			}

	#dropbox .imageHolder {
			display: inline-block;
			position: relative;
	}

	#dropbox .uploaded {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			background: url("https://res.cloudinary.com/control4/image/upload/done.png") no-repeat center center rgba(255,255,255,0.5);
			display: none;
	}

	#dropbox .preview.done .uploaded {
			display: block;
	}

	/*-------------------------
Progress Bars
--------------------------*/

	#dropbox .progressHolder {
			position: absolute;
			background-color: #252f38;
			height: 12px;
			width: 100%;
			left: 0;
			bottom: 0;
			box-shadow: 0 0 2px #000;
	}

	#dropbox .progress {
			background-color: #2586d0;
			position: absolute;
			height: 100%;
			left: 0;
			width: 0;
			box-shadow: 0 0 1px rgba(255, 255, 255, 0.4) inset;
			-moz-transition: 0.25s;
			-webkit-transition: 0.25s;
			-o-transition: 0.25s;
			transition: 0.25s;
	}

	#dropbox .preview.done .progress {
			width: 100% !important;
	}
/*-------------------------
Keypad Confirmation
--------------------------*/
.confirmKeypad {
	font-family: calibri, sans-serif;
	font-size: 11pt;
	letter-spacing: 0px;
}

.column2 {
	-webkit-column-count: 2;
	width: 100%;
	-webkit-column-gap: 2em;
}

	.column2 input {
			margin-right: 5px;
			margin-top: 0px;
	}

.swatch {
	width: 20px;
	height: 20px;
	border: solid 1px #B8B0A5;
	float: left;
	margin: 4px;
}

.wht {
	background-color: #fff;
}

.btn .wht {
	color: #000;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
	background-color: #fff;
	background-image: -moz-linear-gradient(top, #fff, #9e9e9e);
	background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fff), to(#9e9e9e));
	background-image: -webkit-linear-gradient(top, #fff, #9e9e9e);
	background-image: -o-linear-gradient(top, #fff, #9e9e9e);
	background-image: linear-gradient(to bottom, #fff, #9e9e9e);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff', endColorstr='#9e9e9e', GradientType=0);
	border-color: #9e9e9e #fff #9e9e9e;
	border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
	*background-color: #fff;
	filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}

	.btn .wht:active, .btn .wht.active {
			background-color: #fff;
	}

.ivy {
	background-color: #ede6d3;
}

.btn.blk {
	color: #000;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
	background-color: #ede6d3;
	background-image: -moz-linear-gradient(top, #9e9e9e, #000);
	background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#9e9e9e), to(#000));
	background-image: -webkit-linear-gradient(top, #9e9e9e, #000);
	background-image: -o-linear-gradient(top, #9e9e9e, #000);
	background-image: linear-gradient(to bottom, #9e9e9e, #000);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#9e9e9e', endColorstr='#000', GradientType=0);
	border-color: #000 #9e9e9e #000;
	border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
	*background-color: #9e9e9e;
	filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}

	.btn.blk:active, .btn.blk.active {
			background-color: #ede6d3;
	}

.lal {
	background-color: #f7f4ef;
}

.alm {
	background-color: #f4f4ea;
}

.btn.alm {
	color: #000;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
	background-color: #f4f4ea;
	background-image: -moz-linear-gradient(top, #f4f4ea, #e2e2d7);
	background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f4f4ea), to(#e2e2d7));
	background-image: -webkit-linear-gradient(top, #f4f4ea, #e2e2d7);
	background-image: -o-linear-gradient(top, #f4f4ea, #e2e2d7);
	background-image: linear-gradient(to bottom, #f4f4ea, #e2e2d7);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f4f4ea', endColorstr='#e2e2d7', GradientType=0);
	border-color: #e2e2d7 #f4f4ea #e2e2d7;
	border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
	*background-color: #f4f4ea;
	filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}

	.btn.alm:active, .btn.alm.active {
			background-color: #f4f4ea;
	}

.brn {
	background-color: #845016;
}

.btn.brn {
	color: #ffffff;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
	background-color: #845016;
	background-image: -moz-linear-gradient(top, #845016, #724413);
	background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#845016), to(#724413));
	background-image: -webkit-linear-gradient(top, #845016, #724413);
	background-image: -o-linear-gradient(top, #845016, #724413);
	background-image: linear-gradient(to bottom, #845016, #724413);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#845016', endColorstr='#724413', GradientType=0);
	border-color: #724413 #845016 #724413;
	border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
	*background-color: #845016;
	filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}

	.btn.brn:active, .btn.brn.active {
			background-color: #845016;
	}

	.btn.brn:hover {
			background-color: #724413;
			*background-color: #724413;
	}


.blk {
	background-color: #000;
}

.btn.blk {
	color: #ffffff;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
	background-color: #999;
	background-image: -moz-linear-gradient(top, #9e9e9e, #000);
	background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#9e9e9e), to(#000));
	background-image: -webkit-linear-gradient(top, #9e9e9e, #000);
	background-image: -o-linear-gradient(top, #9e9e9e, #000);
	background-image: linear-gradient(to bottom, #9e9e9e, #000);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#9e9e9e', endColorstr='#000', GradientType=0);
	border-color: #000 #9e9e9e #000;
	border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
	*background-color: #9e9e9e;
	filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}

	.btn.blk:active, .btn.blk.active {
			background-color: #999;
	}

	.btn.blk:hover {
			background-color: #000;
			*background-color: #000;
	}

/* Jordan custom colors */
.bbr {
	background-color: #F1CE96;
}

.mbl {
	background-color: #1C1C1C;
}

.swh {
	background-color: #E5E6E4;
}

.alu {
	background-color: #C5C8C9;
}

.bis {
	background-color: #EAE4D3;
}

.pbr {
	background: url("/images/polished_brass.jpg");
}

.snk {
	background: url("/images/satin_nickel.jpg");
}

.sls {
	background: url("/images/stainless_steel.jpg");
}

.sbl {
}

.dob {
	background: url("/images/distress_oil_rubbed_bronze.jpg");
}

.lpb {
	background: url("/images/lifetime_polished_brass.jpg");
}

.lsn {
	background: url("/images/lifetime_satin_nickel.jpg");
}

.orb {
	background: url("/images/oil_rubbed_bronze.jpg");
}

.vbr {
	background: url("/images/venetian_bronze.jpg");
}

.scr {
	background: url("/images/satin_chrome.jpg");
}

.pcr {
	background: url("/images/polished_chrome.jpg");
}

.ble {
	background-color: #0000FF;
}

.org {
	background-color: #FE9A2E;
}

.ylw {
	background-color: #FFFF00;
}

.swatch.red {
	background-color: #FF0000;
}

.grn {
	background-color: #3ADF00;
}

.gry {
	background-color: #A4A4A4;
}

.prp {
	background-color: #FF00FF;
}

.tel {
	background-color: #00FFFF;
}

div.selected {
	border: solid 1px #C00232;
}
/* RowClasses Jojo*/
.productThumbnail {
	max-width: 100px;
	max-height: 100px;
}

.categoryItem {
	height: 200px;
}

.categoryImage {
	margin-top: -20px;
	margin-bottom: 20px;
	position: relative;
	float: left;
}

div.productDisplayOnly {
	margin-right: auto;
	max-width: 700px;
}

div.productDetailImage {
	float: left;
	max-width: 300px;
	max-height: 228px;
	margin-top: 10px;
}

.darkgrey {
	color: #fff;
	background-color: #888;
	background-image: url(/Images/noisefilter.png);
	background-position: right bottom, top left;
	background-repeat: no-repeat, repeat;
	background-attachment: fixed, fixed;
}

.configuratorImage {
	max-width: 284px;
}

.configuratorDropdown {
	width: 200px;
}

.productDetailImage {
	max-width: 500px;
	max-height: 228px;
}

.description {
}

	.description img {
			max-width: 650px;
	}

.cartRow {
	display: inline-block;
	margin-bottom: 12px;
	border-bottom: solid 1px #9797AC;
}

	.cartRow div {
			display: inline-block;
			vertical-align: middle;
	}

.cartImage {
	max-height: 90px;
	max-width: 140px;
}

/* roundedCorners Jojo*/
.roundedCorners, .button {
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	border: solid 1px #999;
	behavior: url(/Scripts/pie/PIE.htc);
	position: relative;
}

.roundedTopCorners {
	-moz-border-radius-topleft: 8px;
	-moz-border-radius-topright: 8px;
	-moz-border-radius-bottomright: 0px;
	-moz-border-radius-bottomleft: 0px;
	-webkit-border-radius: 8px 8px 0px 0px;
	border-radius: 8px 8px 0px 0px;
	position: relative;
	behavior: url(/Scripts/pie/PIE.htc);
}

.roundedBottomCorners {
	-moz-border-radius-bottomright: 8px;
	-moz-border-radius-bottomleft: 8px;
	-moz-border-radius-topleft: 0px;
	-moz-border-radius-topleft: 0px;
	-webkit-border-radius: 0px 0px 8px 8px;
	border-radius: 0px 0px 8px 8px;
	position: relative;
	behavior: url(/Scripts/pie/PIE.htc);
}

.dashed {
	position: relative;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	border: 2px dashed #CCC;
	background-color: #fff;
	behavior: url(/Scripts/pie/PIE.htc);
}

.ui-autocomplete {
	-webkit-box-shadow: 3px 3px 7px #777;
	-moz-box-shadow: 3px 3px 7px #777;
	box-shadow: 3px 3px 3px #777;
	background: rgba(255, 255, 255, 0.9);
	list-style: none;
	padding-left: 5px;
	padding-top: 5px;
	width: 250px;
	padding-bottom: 5px;
	z-index: 1001;
	-pie-background: rgba(255, 255, 255, 0.9);
	behavior: url(/Scripts/pie/PIE.htc);
}

	.ui-autocomplete li {
			padding: 4px;
			cursor: pointer;
			border: 1px solid #ccc;
			width: 88%;
			list-style: none;
			margin: 5px auto;
	}

			.ui-autocomplete li:hover, #ui-active-menuitem {
					background: rgba(120, 120, 120, 0.8);
					color: rgba(255, 255, 255, 0.90);
					behavior: url(/Scripts/pie/PIE.htc);
			}

div.autocomplete #ui-active-menuitem {
}

.checkedDiv {
	background-image: url('/images/checkmark.png');
	background-repeat: no-repeat;
}
/* ------------------------------ */
/* ------- STORE NAV -------- */
/* ------------------------------ */
.arrowlistmenu {
	width: 180px; /*width of accordion menu*/
}

	.arrowlistmenu .menuheader { /*CSS class for menu headers in general (expanding or not!)*/
			color: #7c7f84;
			margin-bottom: 5px; /*bottom spacing between header and rest of content*/
			padding: 0px 0px 0px 7px; /*header text is indented 10px*/
			cursor: pointer;
	}

			.arrowlistmenu .menuheader:hover {
					border-left: 2px solid #A81E28;
					padding: 0 0 0 5px;
			}

	.arrowlistmenu .openheader { /*CSS class to apply to expandable header when it's expanded*/
	}

	.arrowlistmenu ul { /*CSS for UL of each sub menu*/
			list-style-type: none;
			margin: 0;
			padding: 0;
			margin-bottom: 8px; /*bottom spacing between each UL and rest of content*/
	}


			.arrowlistmenu ul li {
					padding-bottom: 4px; /*bottom spacing between menu items*/
			}

					.arrowlistmenu ul li:before, .ui-tabs ul li:before, .roundedCorners ul li:before, .roundedBottomCorners ul li:before {
							content: "";
					}

					.arrowlistmenu ul li a {
							color: #7c7f84;
							display: block;
							margin: 0 0 0 18px;
							padding: 0 0 0 5px;
							text-decoration: none;
					}

							.arrowlistmenu ul li a:visited {
							}

							.arrowlistmenu ul li a:hover { /*hover state CSS*/
									border-left: 2px solid #A81E28;
									margin: 0 0 0 16px;
									padding: 0 0 0 5px;
							}

					.arrowlistmenu ul li.active a {
							border-left: 2px solid #A81E28;
							margin: 0 0 0 16px;
							padding: 0 0 0 5px;
					}

.current {
	color: black;
}

.balanceHeaderRow {
	font-weight: 500;
	background-color: white;
	padding: 0px;
	height: 21px;
}

.balanceHeaderCell {
	width: 110px;
	float: left;
}

.balanceRow {
	background-color: white;
	height: 21px;
	padding: 3px 0px;
	border-bottom: 1px solid rgba(234, 234, 234, 1.0);
	margin: 0px;
}

	.balanceRow:hover {
			background-color: #e7e5dd;
	}

.balanceCell {
	width: 105px;
	float: left;
	text-overflow: ellipsis;
	height: 21px;
	overflow: hidden;
	white-space: nowrap;
	padding-right: 5px;
}

/*Recommended Products*/
.recommnededWrapper {
	max-width: 770px;
	overflow-x: auto;
	overflow-y: hidden;
	min-height: 167px;
	max-height: 208px;
	background-color: #eee;
	padding: 10px;
	white-space: nowrap;
}

ul.sortable {
	padding-left: 0;
	margin-bottom: 0;
}

	ul.sortable li:first-of-type {
			margin-left: 0;
	}

.sortable-placeholder {
	border: 1px dashed #999;
	padding: 5px;
	display: inline-block;
	margin: 0 5px;
	vertical-align: top;
	width: 140px;
	height: 155px;
}

.recommendedDelete {
	position: absolute;
	right: -14px;
	top: -18px;
	width: 28px;
	height: 28px;
}

.recommendedContainer:before {
	content: "" !important;
}

.recommendedContainer {
	padding: 5px;
	display: inline-block;
	margin: 0 5px;
	vertical-align: top;
	width: 140px;
	min-height: 155px;
	background-color: white;
	border: solid 1px #999;
}

	.recommendedContainer div {
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
	}

	.recommendedContainer img {
			max-width: 100px;
			max-height: 86px;
			margin-top: 7px;
	}

	.recommendedContainer div a span {
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
	}

	.recommendedContainer span {
			font-size: 9px;
	}

.editorPreview {
	padding: 10px;
	clear: both;
	width: 92%;
	display: block;
	margin: 10px auto;
	border: solid 1px #ccc;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	overflow-y: auto;
}

/* Keycap Symbols Font */
@font-face {
font-family: 'c4keycaps';
src: url('/Content/fonts/keycaps/c4symbols-webfont.eot');
src: url('/Content/fonts/keycaps/c4symbols-webfont.eot?#iefix') format('embedded-opentype'),url('/Content/fonts/keycaps/c4symbols-webfont.ttf') format('font/opentype'),url('/Content/fonts/keycaps/c4symbols-webfont.woff') format('woff'),url('/Content/fonts/keycaps/c4symbols-webfont.svg#webfontregular') format('image/svg+xml');
font-weight: 300;
font-style: normal;
letter-spacing: 0;
}

.symbol {
font-family: 'c4keycaps';
}

.keycapInput {
font-family: 'c4keycaps';
}

.dropshadow {
-webkit-box-shadow: 0px 6px 8px rgba(50, 50, 50, 0.75);
-moz-box-shadow: 0px 6px 8px rgba(50, 50, 50, 0.75);
box-shadow: 0px 6px 8px rgba(50, 50, 50, 0.75);
behavior: url(/Scripts/pie/PIE.htc);
}

.symbol {
	min-width: 20px;
	padding-left: 3px;
	padding-right: 3px;
	font-size: 14pt;
}

.symbols {
display: inline-block;
width: 32px;
height: 32px;
line-height: 14px;
vertical-align: text-top;
background-image: url("https://res.cloudinary.com/control4/image/upload/rpe_etching_map.png");
background-position: 14px 14px;
background-repeat: no-repeat;
margin-top: 1px;
background-size: 568px;
position: relative;
}

li.blk div.symbols {
	background-image: url("https://res.cloudinary.com/control4/image/upload/rpe_etching_map_white.png");
}

.symbols-1 {
background-position: -16px -18px
}

.symbols-2 {
background-position: -89px -18px
}

.symbols-3 {
background-position: -160px -18px
}

.symbols-4 {
background-position: -230px -18px
}

.symbols-5 {
background-position: -300px -18px
}

.symbols-6 {
background-position: -373px -18px
}

.symbols-7 {
background-position: -444px -18px
}

.symbols-8 {
background-position: -514px -18px
}

.symbols-9 {
background-position: -16px -89px
}

.symbols-10 {
background-position: -89px -89px
}

.symbols-11 {
background-position: -160px -89px
}

.symbols-12 {
background-position: -230px -89px
}

.symbols-13 {
background-position: -300px -89px
}

.symbols-14 {
background-position: -373px -89px
}

.symbols-15 {
background-position: -444px -89px
}

.symbols-16 {
background-position: -514px -89px
}

.symbols-17 {
background-position: -16px -160px
}

.symbols-18 {
background-position: -89px -160px
}

.symbols-19 {
background-position: -160px -160px
}

.symbols-20 {
background-position: -230px -160px
}

.symbols-21 {
background-position: -300px -160px
}

.symbols-22 {
background-position: -373px -160px
}

.symbols-23 {
background-position: -444px -160px
}

.symbols-24 {
background-position: -514px -160px
}

.symbols-25 {
background-position: -16px -231px
}

.symbols-26 {
background-position: -89px -231px
}

.symbols-27 {
background-position: -160px -231px
}

.symbols-28 {
background-position: -230px -231px
}

.symbols-29 {
background-position: -300px -231px
}

.symbols-30 {
background-position: -373px -231px
}

.symbols-31 {
background-position: -444px -231px
}

.symbols-32 {
background-position: -514px -231px
}

.symbols-33 {
background-position: -16px -302px
}

.symbols-34 {
background-position: -89px -302px
}

.symbols-35 {
background-position: -160px -302px
}

.symbols-36 {
background-position: -230px -302px
}

.symbols-37 {
background-position: -300px -302px
}

.symbols-38 {
background-position: -373px -302px
}

.symbols-39 {
background-position: -444px -302px
}

.symbols-40 {
background-position: -514px -302px
}

.symbols-41 {
background-position: -16px -373px
}

.symbols-42 {
background-position: -89px -373px
}

.symbols-43 {
background-position: -160px -373px
}

.symbols-44 {
background-position: -230px -373px
}

.symbols-45 {
background-position: -300px -373px
}

.symbols-46 {
background-position: -373px -373px
}

.symbols-47 {
background-position: -444px -373px
}

.symbols-48 {
background-position: -514px -373px
}

.bookend {
	color: #333;
	border: 0px solid red;
	-webkit-transform: rotate(-90deg);
	-moz-transform: rotate(-90deg);
	-o-transform: rotate(-90deg);
	white-space: nowrap;
	display: block;
	width: 20px;
	font-size: 24px;
	font-weight: 300;
	text-shadow: 0px 0px 1px #333;
	margin: 0px;
	padding: 0px;
	/* IE9 */
	-ms-transform: rotate(-90deg);
	-ms-transform-origin: 50% 50%;
	/* IE10 and other modern browsers that do not need vendor prefixes */
	transform: rotate(-90deg);
	transform-origin: 50% 50%;
}

/*-------------------------
	 Animation Classes
--------------------------*/
.slide.ng-enter, .slide.ng-leave, .slide.ng-add, .slide.ng-remove {
	-webkit-transition: all cubic-bezier(0.250, 0.460, 0.450, 0.940) 1s;
	-moz-transition: all cubic-bezier(0.250, 0.460, 0.450, 0.940) 1s;
	-o-transition: all cubic-bezier(0.250, 0.460, 0.450, 0.940) 1s;
	transition: all cubic-bezier(0.250, 0.460, 0.450, 0.940) 1s;
}

.slide.ng-enter, .slide.ng-add {
	opacity: 0;
}

.slide.ng-enter-active, .slide.ng-add-active {
	opacity: 1;
}

.slide.ng-leave, .slide.ng-remove {
	opacity: 1;
}

.slide.ng-leave-active, .slide.ng-remove-active {
	opacity: 0;
}

@keyframes kf_fade_in_out {
	from {
			opacity: 1;
	}

	to {
			opacity: 0;
	}
}

@-webkit-keyframes kf_fade_in_out {
	from {
			opacity: 1;
	}

	to {
			opacity: 0;
	}
}

@-moz-keyframes kf_fade_in_out {
	from {
			opacity: 1;
	}

	to {
			opacity: 0;
	}
}

@-o-keyframes kf_fade_in_out {
	from {
			opacity: 1;
	}

	to {
			opacity: 0;
	}
}

/*--------------------
			Other
--------------------*/


.tileLink {
	text-overflow: ellipsis;
	overflow: hidden;
	display: inline-block;
	white-space: nowrap;
	width: 208px;
	color: #000000;
}

	.tileLink a {
			color: #000000;
	}

			.tileLink a:hover {
					color: #fff;
			}


blockquote {
	padding: 10px 20px;
	margin: 0 0 20px;
	border-left: 5px solid #eeeeee;
}

	blockquote p {
			font-size: 17.5px;
			font-weight: 300;
			line-height: 1.25;
	}

			blockquote p:last-child {
					margin-bottom: 0;
			}

	blockquote small {
			display: block;
			line-height: 1.428571429;
			color: #999999;
	}

			blockquote small:before {
					content: '\2014 \00A0';
			}

	blockquote.pull-right {
			padding-right: 15px;
			padding-left: 0;
			border-right: 5px solid #eeeeee;
			border-left: 0;
	}

			blockquote.pull-right p,
			blockquote.pull-right small {
					text-align: right;
			}

					blockquote.pull-right small:before {
							content: '';
					}

					blockquote.pull-right small:after {
							content: '\00A0 \2014';
					}

	q:before,
	q:after,
	blockquote:before,
	blockquote:after {
			content: "";
	}

.fileinput-button {
	position: relative;
	overflow: hidden;
}

	.fileinput-button input {
			position: absolute;
			top: 0;
			right: 0;
			margin: 0;
			opacity: 0;
			filter: alpha(opacity=0);
			transform: translate(-300px, 0) scale(4);
			font-size: 23px;
			direction: ltr;
			cursor: pointer;
	}

/*Bootstrap 3.0 styels*/
.has-error {
	border-color: #a94442;
	-webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
	box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
}
.radio,
.checkbox {
	display: block;
	min-height: 20px;
	padding-left: 20px;
	margin-top: 10px;
	margin-bottom: 10px;
	vertical-align: middle;
}

	.radio label,
	.checkbox label {
			display: inline;
			margin-bottom: 0;
			font-weight: 300;
			cursor: pointer;
	}

	.radio input[type="radio"],
	.radio-inline input[type="radio"],
	.checkbox input[type="checkbox"],
	.checkbox-inline input[type="checkbox"] {
			float: left;
			margin-left: -20px;
	}

	.radio + .radio,
	.checkbox + .checkbox {
			margin-top: -5px;
	}

	.radio-inline,
	.checkbox-inline {
			display: inline-block;
			padding-left: 20px;
			margin-bottom: 0;
			font-weight: 300;
			vertical-align: middle;
			cursor: pointer;
	}

	.radio-inline + .radio-inline,
	.radio-inline + .checkbox-inline,
	.checkbox-inline + .radio-inline,
	.checkbox-inline + .checkbox-inline {
			margin-top: 0;
			margin-left: 10px;
	}

	input[type="radio"][disabled],
	input[type="checkbox"][disabled],
	.radio[disabled],
	.radio-inline[disabled],
	.checkbox[disabled],
	.checkbox-inline[disabled],
	fieldset[disabled] input[type="radio"],
	fieldset[disabled] input[type="checkbox"],
	fieldset[disabled] .radio,
	fieldset[disabled] .radio-inline,
	fieldset[disabled] .checkbox,
	fieldset[disabled] .checkbox-inline {
			cursor: not-allowed;
	}

ul li.list-group-item:before {
	content: none;
}

.list-group {
	padding-left: 0;
	margin-bottom: 20px;
}

.list-group-item {
	position: relative;
	display: block;
	padding: 10px 15px;
	margin-bottom: -1px;
	background-color: #ffffff;
	border: 1px solid #dddddd;
}

	.list-group-item:first-child {
			border-top-right-radius: 4px;
			border-top-left-radius: 4px;
	}

	.list-group-item:last-child {
			margin-bottom: 0;
			border-bottom-right-radius: 4px;
			border-bottom-left-radius: 4px;
	}

	.list-group-item > .badge {
			float: right;
	}

			.list-group-item > .badge + .badge {
					margin-right: 5px;
			}

a.list-group-item {
	color: #555555;
}

	a.list-group-item .list-group-item-heading {
			color: #333333;
	}

	a.list-group-item:hover,
	a.list-group-item:focus {
			text-decoration: none;
			background-color: #f5f5f5;
	}

	a.list-group-item.active,
	a.list-group-item.active:hover,
	a.list-group-item.active:focus {
			z-index: 2;
			color: #ffffff;
			background-color: #428bca;
			border-color: #428bca;
	}

			a.list-group-item.active .list-group-item-heading,
			a.list-group-item.active:hover .list-group-item-heading,
			a.list-group-item.active:focus .list-group-item-heading {
					color: inherit;
			}

			a.list-group-item.active .list-group-item-text,
			a.list-group-item.active:hover .list-group-item-text,
			a.list-group-item.active:focus .list-group-item-text {
					color: #e1edf7;
			}

.list-group-item-heading {
	margin-top: 0;
	margin-bottom: 5px;
}

.list-group-item-text {
	margin-bottom: 0;
	line-height: 1.3;
}

#cmsTabs > li > a {
	color: black;
	font-size: 11px;
}

.clickable {
	cursor: pointer;
}

.add-on-alone {
	display: inline-block;
	width: auto;
	height: 20px;
	min-width: 16px;
	padding: 4px 5px;
	font-size: 13px;
	font-weight: 300;
	line-height: 20px;
	text-align: center;
	text-shadow: 0 1px 0 #ffffff;
	background-color: #eeeeee;
	border: 1px solid #ccc;
	margin: 0px -1px 0px -6px;
	top: -5px;
	position: relative;
	z-index: 1;
}

.span-alone {
	width: auto !important;
	position: relative !important;
	margin: 0px;
	top: 0px;
	left: -4px;
	float: right;
	-webkit-border-radius: 0px 4px 4px 0px;
	-moz-border-radius: 0px 4px 4px 0px;
	border-radius: 0px 4px 4px 0px;
	height: 20px !important;
}

.growl-container {
	position: fixed;
	top: 75px;
	right: 25px;
	width: calc(100% - 50px);
	max-width: 400px;
	z-index: 9999;
}
.growl-container > .growl-item.alert {
	margin: 0px 0px 25px 0px;
	padding: 0px;
	color: inherit;
}
.growl-item button.close {
	display: none;
}
.growl-item.icon > .growl-message {
margin: 0;
}

.muted {
	color: #999;
	font-style: italic;
}

/************************************************/
/**                Bootstrap 3.0 styels         */
/************************************************/

.modal-title {
	margin: 0;
	line-height: 1.428571429;
}

.modal-body {
	position: relative;
	padding: 20px;
}

@media (min-width: 992px) {
	.modal-lg {
			width: 900px;
	}
}


/************************************************/
/**                Effects                      */
/************************************************/
.animated.grow {
	transition: width 0.5s ease-in-out;
	-webkit-transition: width 0.5s ease-in-out;
	-moz-transition: width 0.5s ease-in-out;
	-o-transition: width 0.5s ease-in-out;
	-ms-transition: width 0.5s ease-in-out;
}

	.animated.grow:focus {
			width: 500px !important;
	}

.animate-show {
	opacity: 1;
}

	.animate-show.ng-hide-add,
	.animate-show.ng-hide-remove {
			display: block !important;
	}

			.animate-show.ng-hide-add.ng-hide-add-active,
			.animate-show.ng-hide-remove.ng-hide-remove-active {
					-webkit-transition: all linear 0.2s;
					transition: all linear 0.2s;
			}

	.animate-show.ng-hide {
			opacity: 0;
	max-height: 0;
	}

.force-font span[style] {
	font-family: 'Gotham SSm A', 'Gotham SSm B', "Helvetica Neue", "Calibri", "Arial" !important;
}

/* CMS Stuffs */
.uploadify-button {
	display: block;
	height: 50px;
	width: 100%;
	padding: 0 15px;
	margin: 0 0 10px 0;
	color: #373737;
	font-size: 16px;
	line-height: 50px;
	background-color: #eee;
	border-width: 1px;
	border-style: solid;
	border-color: transparent;
	-webkit-border-radius: 3px;
	border-radius: 3px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	transition: 0.1s;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

.dropdown {
	position: relative;
}

.dropdown-toggle:focus {
	outline: 0;
}

.dropdown-menu {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 1000;
	display: none;
	float: left;
	padding: 5px 0;
	margin: 2px 0 0;
	font-size: 14px;
	list-style: none;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ccc;
	border: 1px solid rgba(0, 0, 0, .15);
	border-radius: 4px;
	-webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
	box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
}

	.dropdown-menu.pull-right {
			right: 0;
			left: auto;
	}

	.dropdown-menu .divider {
			height: 1px;
			margin: 9px 0;
			overflow: hidden;
			background-color: #e5e5e5;
	}

	.dropdown-menu > li > a {
			display: block;
			padding: 3px 5px;
			clear: both;
			font-weight: normal;
			line-height: 1.42857143;
			color: #333;
			white-space: nowrap;
			text-align: left;
	}

ul.dropdown-menu > li:before {
	content: none;
}

.dropdown-menu > li.selected,
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
	color: #262626;
	text-decoration: none;
	background-color: #f5f5f5;
}

.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
	color: #fff;
	text-decoration: none;
	background-color: #428bca;
	outline: 0;
}

.dropdown-menu > .disabled > a,
.dropdown-menu > .disabled > a:hover,
.dropdown-menu > .disabled > a:focus {
	color: #999;
}

	.dropdown-menu > .disabled > a:hover,
	.dropdown-menu > .disabled > a:focus {
			text-decoration: none;
			cursor: not-allowed;
			background-color: transparent;
			background-image: none;
			filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
	}

.open > .dropdown-menu {
	display: block;
}

.open > a {
	outline: 0;
}

.dropdown-menu-right {
	right: 0;
	left: auto;
}

.dropdown-menu-left {
	right: auto;
	left: 0;
}

.dropdown-header {
	display: block;
	padding: 3px 20px;
	font-size: 12px;
	line-height: 1.42857143;
	color: #999;
}

.dropdown-backdrop {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 990;
}

.pull-right > .dropdown-menu {
	right: 0;
	left: auto;
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
	content: "";
	border-top: 0;
	border-bottom: 4px solid;
}

.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
	top: auto;
	bottom: 100%;
	margin-bottom: 1px;
}

@media (min-width: 768px) {
	.navbar-right .dropdown-menu {
			right: 0;
			left: auto;
	}

	.navbar-right .dropdown-menu-left {
			right: auto;
			left: 0;
	}
}

.btn-group,
.btn-group-vertical {
	position: relative;
	display: inline-block;
	vertical-align: middle;
}

	.btn-group > .btn,
	.btn-group-vertical > .btn {
			position: relative;
			float: left;
	}

			.btn-group > .btn:hover,
			.btn-group-vertical > .btn:hover,
			.btn-group > .btn:focus,
			.btn-group-vertical > .btn:focus,
			.btn-group > .btn:active,
			.btn-group-vertical > .btn:active,
			.btn-group > .btn.active,
			.btn-group-vertical > .btn.active {
					z-index: 2;
			}

			.btn-group > .btn:focus,
			.btn-group-vertical > .btn:focus {
					outline: none;
			}

	.btn-group .btn + .btn,
	.btn-group .btn + .btn-group,
	.btn-group .btn-group + .btn,
	.btn-group .btn-group + .btn-group {
			margin-left: -1px;
	}

.btn-toolbar {
	margin-left: -5px;
}

	.btn-toolbar .btn-group,
	.btn-toolbar .input-group {
			float: left;
	}

	.btn-toolbar > .btn,
	.btn-toolbar > .btn-group,
	.btn-toolbar > .input-group {
			margin-left: 5px;
	}

.btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
	border-radius: 0;
}

.btn-group > .btn:first-child {
	margin-left: 0;
}

	.btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
	}

.btn-group > .btn:last-child:not(:first-child),
.btn-group > .dropdown-toggle:not(:first-child) {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.btn-group > .btn-group {
	float: left;
}

	.btn-group > .btn-group:not(:first-child):not(:last-child) > .btn {
			border-radius: 0;
	}

	.btn-group > .btn-group:first-child > .btn:last-child,
	.btn-group > .btn-group:first-child > .dropdown-toggle {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
	}

	.btn-group > .btn-group:last-child > .btn:first-child {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
	}

.btn-group .dropdown-toggle:active,
.btn-group.open .dropdown-toggle {
	outline: 0;
}

.btn-group > .btn + .dropdown-toggle {
	padding-right: 8px;
	padding-left: 8px;
}

.btn-group > .btn-lg + .dropdown-toggle {
	padding-right: 12px;
	padding-left: 12px;
}

.btn-group.open .dropdown-toggle {
	-webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
	box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
}

	.btn-group.open .dropdown-toggle.btn-link {
			-webkit-box-shadow: none;
			box-shadow: none;
	}

.btn .caret {
	margin-left: 0;
}

.btn-lg .caret {
	border-width: 5px 5px 0;
	border-bottom-width: 0;
}

.dropup .btn-lg .caret {
	border-width: 0 5px 5px;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group,
.btn-group-vertical > .btn-group > .btn {
	display: block;
	float: none;
	width: 100%;
	max-width: 100%;
}

	.btn-group-vertical > .btn-group > .btn {
			float: none;
	}

	.btn-group-vertical > .btn + .btn,
	.btn-group-vertical > .btn + .btn-group,
	.btn-group-vertical > .btn-group + .btn,
	.btn-group-vertical > .btn-group + .btn-group {
			margin-top: -1px;
			margin-left: 0;
	}

	.btn-group-vertical > .btn:not(:first-child):not(:last-child) {
			border-radius: 0;
	}

	.btn-group-vertical > .btn:first-child:not(:last-child) {
			border-top-right-radius: 4px;
			border-bottom-right-radius: 0;
			border-bottom-left-radius: 0;
	}

	.btn-group-vertical > .btn:last-child:not(:first-child) {
			border-top-left-radius: 0;
			border-top-right-radius: 0;
			border-bottom-left-radius: 4px;
	}

	.btn-group-vertical > .btn-group:not(:first-child):not(:last-child) > .btn {
			border-radius: 0;
	}

	.btn-group-vertical > .btn-group:first-child:not(:last-child) > .btn:last-child,
	.btn-group-vertical > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
			border-bottom-right-radius: 0;
			border-bottom-left-radius: 0;
	}

	.btn-group-vertical > .btn-group:last-child:not(:first-child) > .btn:first-child {
			border-top-left-radius: 0;
			border-top-right-radius: 0;
	}

.btn-group-justified {
	display: table;
	width: 100%;
	table-layout: fixed;
	border-collapse: separate;
}

	.btn-group-justified > .btn,
	.btn-group-justified > .btn-group {
			display: table-cell;
			float: none;
			width: 1%;
	}

			.btn-group-justified > .btn-group .btn {
					width: 100%;
			}

[data-toggle="buttons"] > .btn > input[type="radio"],
[data-toggle="buttons"] > .btn > input[type="checkbox"] {
	display: none;
}

.input-group {
	position: relative;
	display: table;
	border-collapse: separate;
}

	.input-group[class*="col-"] {
			float: none;
			padding-right: 0;
			padding-left: 0;
	}

	.input-group .form-control {
			position: relative;
			z-index: 2;
			float: left;
			width: 100%;
			margin-bottom: 0;
	}

.input-group-lg > .form-control,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn {
	height: 46px;
	padding: 10px 16px;
	font-size: 18px;
	line-height: 1.33;
	border-radius: 6px;
}

select.input-group-lg > .form-control,
select.input-group-lg > .input-group-addon,
select.input-group-lg > .input-group-btn > .btn {
	height: 46px;
	line-height: 46px;
}

textarea.input-group-lg > .form-control,
textarea.input-group-lg > .input-group-addon,
textarea.input-group-lg > .input-group-btn > .btn,
select[multiple].input-group-lg > .form-control,
select[multiple].input-group-lg > .input-group-addon,
select[multiple].input-group-lg > .input-group-btn > .btn {
	height: auto;
}

.input-group-sm > .form-control,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .btn {
	height: 30px;
	padding: 5px 10px;
	font-size: 12px;
	line-height: 1.5;
	border-radius: 3px;
}

select.input-group-sm > .form-control,
select.input-group-sm > .input-group-addon,
select.input-group-sm > .input-group-btn > .btn {
	height: 30px;
	line-height: 30px;
}

textarea.input-group-sm > .form-control,
textarea.input-group-sm > .input-group-addon,
textarea.input-group-sm > .input-group-btn > .btn,
select[multiple].input-group-sm > .form-control,
select[multiple].input-group-sm > .input-group-addon,
select[multiple].input-group-sm > .input-group-btn > .btn {
	height: auto;
}

.input-group-addon,
.input-group-btn,
.input-group .form-control {
	display: table-cell;
}

	.input-group-addon:not(:first-child):not(:last-child),
	.input-group-btn:not(:first-child):not(:last-child),
	.input-group .form-control:not(:first-child):not(:last-child) {
			border-radius: 0;
	}

.input-group-addon,
.input-group-btn {
	width: 1%;
	white-space: nowrap;
	vertical-align: middle;
}

.input-group-addon {
	padding: 6px 12px;
	font-size: 14px;
	font-weight: normal;
	line-height: 1;
	color: #555;
	text-align: center;
	background-color: #eee;
	border: 1px solid #ccc;
	border-radius: 4px;
}

	.input-group-addon.input-sm {
			padding: 5px 10px;
			font-size: 12px;
			border-radius: 3px;
	}

	.input-group-addon.input-lg {
			padding: 10px 16px;
			font-size: 18px;
			border-radius: 6px;
	}

	.input-group-addon input[type="radio"],
	.input-group-addon input[type="checkbox"] {
			margin-top: 0;
	}

	.input-group .form-control:first-child,
	.input-group-addon:first-child,
	.input-group-btn:first-child > .btn,
	.input-group-btn:first-child > .btn-group > .btn,
	.input-group-btn:first-child > .dropdown-toggle,
	.input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle),
	.input-group-btn:last-child > .btn-group:not(:last-child) > .btn {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
	}

	.input-group-addon:first-child {
			border-right: 0;
	}

	.input-group .form-control:last-child,
	.input-group-addon:last-child,
	.input-group-btn:last-child > .btn,
	.input-group-btn:last-child > .btn-group > .btn,
	.input-group-btn:last-child > .dropdown-toggle,
	.input-group-btn:first-child > .btn:not(:first-child),
	.input-group-btn:first-child > .btn-group:not(:first-child) > .btn {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
	}

	.input-group-addon:last-child {
			border-left: 0;
	}

.input-group-btn {
	position: relative;
	font-size: 0;
	white-space: nowrap;
}

	.input-group-btn > .btn {
			position: relative;
	}

			.input-group-btn > .btn + .btn {
					margin-left: -1px;
			}

			.input-group-btn > .btn:hover,
			.input-group-btn > .btn:focus,
			.input-group-btn > .btn:active {
					z-index: 2;
			}

	.input-group-btn:first-child > .btn,
	.input-group-btn:first-child > .btn-group {
			margin-right: -1px;
	}

	.input-group-btn:last-child > .btn,
	.input-group-btn:last-child > .btn-group {
			margin-left: -1px;
	}

.inlinesparkline {
	top: 40px;
}

/***
	Missing Bootstrap css
	***/
.pager {
	padding-left: 0;
	margin: 20px 0;
	text-align: center;
	list-style: none;
}

	.pager li {
			display: inline;
	}

			.pager li > a,
			.pager li > span {
					display: inline-block;
					padding: 5px 14px;
					background-color: #fff;
					border: 1px solid #ddd;
					border-radius: 15px;
			}

					.pager li > a:hover,
					.pager li > a:focus {
							text-decoration: none;
							background-color: #eee;
					}

	.pager .next > a,
	.pager .next > span {
			float: right;
	}

	.pager .previous > a,
	.pager .previous > span {
			float: left;
	}

	.pager .disabled > a,
	.pager .disabled > a:hover,
	.pager .disabled > a:focus,
	.pager .disabled > span {
			color: #777;
			cursor: not-allowed;
			background-color: #fff;
	}
