@import "./shared-assets/scss/global";
/*!
 * Bootstrap Grid v4.2.1 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
/*# sourceMappingURL=bootstrap-grid.min.css.map */
html {
	box-sizing: border-box;
	-ms-overflow-style: scrollbar;
}
* {
	box-sizing: inherit;
	&::after {
	box-sizing: inherit;
	}
	&::before {
		box-sizing: inherit;
	}
}

.container {
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
}
.container-fluid {
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
}
.row {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: -15px;
	margin-left: -15px;
}
.no-gutters {
	margin-right: 0;
	margin-left: 0;
	>.col {
		padding-right: 0;
		padding-left: 0;
	}
	>[class*=col-] {
		padding-right: 0;
		padding-left: 0;
	}
}
.col {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	-ms-flex-preferred-size: 0;
	flex-basis: 0;
	-ms-flex-positive: 1;
	flex-grow: 1;
	max-width: 100%;
}
.col-1 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	-ms-flex: 0 0 8.333333%;
	flex: 0 0 8.333333%;
	max-width: 8.333333%;
}
.col-10 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	-ms-flex: 0 0 83.333333%;
	flex: 0 0 83.333333%;
	max-width: 83.333333%;
}
.col-11 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	-ms-flex: 0 0 91.666667%;
	flex: 0 0 91.666667%;
	max-width: 91.666667%;
}
.col-12 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	-ms-flex: 0 0 100%;
	flex: 0 0 100%;
	max-width: 100%;
}
.col-2 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	-ms-flex: 0 0 16.666667%;
	flex: 0 0 16.666667%;
	max-width: 16.666667%;
}
.col-3 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	-ms-flex: 0 0 25%;
	flex: 0 0 25%;
	max-width: 25%;
}
.col-4 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	-ms-flex: 0 0 33.333333%;
	flex: 0 0 33.333333%;
	max-width: 33.333333%;
}
.col-5 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	-ms-flex: 0 0 41.666667%;
	flex: 0 0 41.666667%;
	max-width: 41.666667%;
}
.col-6 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	-ms-flex: 0 0 50%;
	flex: 0 0 50%;
	max-width: 50%;
}
.col-7 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	-ms-flex: 0 0 58.333333%;
	flex: 0 0 58.333333%;
	max-width: 58.333333%;
}
.col-8 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	-ms-flex: 0 0 66.666667%;
	flex: 0 0 66.666667%;
	max-width: 66.666667%;
}
.col-9 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	-ms-flex: 0 0 75%;
	flex: 0 0 75%;
	max-width: 75%;
}
.col-auto {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	width: auto;
	max-width: 100%;
}
.col-lg {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-lg-1 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-lg-10 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-lg-11 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-lg-12 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-lg-2 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-lg-3 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-lg-4 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-lg-5 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-lg-6 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-lg-7 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-lg-8 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-lg-9 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-lg-auto {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-md {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-md-1 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-md-10 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-md-11 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-md-12 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-md-2 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-md-3 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-md-4 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-md-5 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-md-6 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-md-7 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-md-8 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-md-9 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-md-auto {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-sm {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-sm-1 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-sm-10 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-sm-11 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-sm-12 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-sm-2 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-sm-3 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-sm-4 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-sm-5 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-sm-6 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-sm-7 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-sm-8 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-sm-9 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-sm-auto {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-xl {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-xl-1 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-xl-10 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-xl-11 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-xl-12 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-xl-2 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-xl-3 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-xl-4 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-xl-5 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-xl-6 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-xl-7 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-xl-8 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-xl-9 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-xl-auto {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.order-first {
	-ms-flex-order: -1;
	order: -1;
}
.order-last {
	-ms-flex-order: 13;
	order: 13;
}
.order-0 {
	-ms-flex-order: 0;
	order: 0;
}
.order-1 {
	-ms-flex-order: 1;
	order: 1;
}
.order-2 {
	-ms-flex-order: 2;
	order: 2;
}
.order-3 {
	-ms-flex-order: 3;
	order: 3;
}
.order-4 {
	-ms-flex-order: 4;
	order: 4;
}
.order-5 {
	-ms-flex-order: 5;
	order: 5;
}
.order-6 {
	-ms-flex-order: 6;
	order: 6;
}
.order-7 {
	-ms-flex-order: 7;
	order: 7;
}
.order-8 {
	-ms-flex-order: 8;
	order: 8;
}
.order-9 {
	-ms-flex-order: 9;
	order: 9;
}
.order-10 {
	-ms-flex-order: 10;
	order: 10;
}
.order-11 {
	-ms-flex-order: 11;
	order: 11;
}
.order-12 {
	-ms-flex-order: 12;
	order: 12;
}
.offset-1 {
	margin-left: 8.333333%;
}
.offset-2 {
	margin-left: 16.666667%;
}
.offset-3 {
	margin-left: 25%;
}
.offset-4 {
	margin-left: 33.333333%;
}
.offset-5 {
	margin-left: 41.666667%;
}
.offset-6 {
	margin-left: 50%;
}
.offset-7 {
	margin-left: 58.333333%;
}
.offset-8 {
	margin-left: 66.666667%;
}
.offset-9 {
	margin-left: 75%;
}
.offset-10 {
	margin-left: 83.333333%;
}
.offset-11 {
	margin-left: 91.666667%;
}
.d-none {
	display: none !important;
}
.d-inline {
	display: inline !important;
}
.d-inline-block {
	display: inline-block !important;
}
.d-block {
	display: block !important;
}
.d-table {
	display: table !important;
}
.d-table-row {
	display: table-row !important;
}
.d-table-cell {
	display: table-cell !important;
}
.d-flex {
	display: -ms-flexbox !important;
	display: flex !important;
}
.d-inline-flex {
	display: -ms-inline-flexbox !important;
	display: inline-flex !important;
}
.flex-row {
	-ms-flex-direction: row !important;
	flex-direction: row !important;
}
.flex-column {
	-ms-flex-direction: column !important;
	flex-direction: column !important;
}
.flex-row-reverse {
	-ms-flex-direction: row-reverse !important;
	flex-direction: row-reverse !important;
}
.flex-column-reverse {
	-ms-flex-direction: column-reverse !important;
	flex-direction: column-reverse !important;
}
.flex-wrap {
	-ms-flex-wrap: wrap !important;
	flex-wrap: wrap !important;
}
.flex-nowrap {
	-ms-flex-wrap: nowrap !important;
	flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
	-ms-flex-wrap: wrap-reverse !important;
	flex-wrap: wrap-reverse !important;
}
.flex-fill {
	-ms-flex: 1 1 auto !important;
	flex: 1 1 auto !important;
}
.flex-grow-0 {
	-ms-flex-positive: 0 !important;
	flex-grow: 0 !important;
}
.flex-grow-1 {
	-ms-flex-positive: 1 !important;
	flex-grow: 1 !important;
}
.flex-shrink-0 {
	-ms-flex-negative: 0 !important;
	flex-shrink: 0 !important;
}
.flex-shrink-1 {
	-ms-flex-negative: 1 !important;
	flex-shrink: 1 !important;
}
.justify-content-start {
	-ms-flex-pack: start !important;
	justify-content: flex-start !important;
}
.justify-content-end {
	-ms-flex-pack: end !important;
	justify-content: flex-end !important;
}
.justify-content-center {
	-ms-flex-pack: center !important;
	justify-content: center !important;
}
.justify-content-between {
	-ms-flex-pack: justify !important;
	justify-content: space-between !important;
}
.justify-content-around {
	-ms-flex-pack: distribute !important;
	justify-content: space-around !important;
}
.align-items-start {
	-ms-flex-align: start !important;
	align-items: flex-start !important;
}
.align-items-end {
	-ms-flex-align: end !important;
	align-items: flex-end !important;
}
.align-items-center {
	-ms-flex-align: center !important;
	align-items: center !important;
}
.align-items-baseline {
	-ms-flex-align: baseline !important;
	align-items: baseline !important;
}
.align-items-stretch {
	-ms-flex-align: stretch !important;
	align-items: stretch !important;
}
.align-content-start {
	-ms-flex-line-pack: start !important;
	align-content: flex-start !important;
}
.align-content-end {
	-ms-flex-line-pack: end !important;
	align-content: flex-end !important;
}
.align-content-center {
	-ms-flex-line-pack: center !important;
	align-content: center !important;
}
.align-content-between {
	-ms-flex-line-pack: justify !important;
	align-content: space-between !important;
}
.align-content-around {
	-ms-flex-line-pack: distribute !important;
	align-content: space-around !important;
}
.align-content-stretch {
	-ms-flex-line-pack: stretch !important;
	align-content: stretch !important;
}
.align-self-auto {
	-ms-flex-item-align: auto !important;
	align-self: auto !important;
}
.align-self-start {
	-ms-flex-item-align: start !important;
	align-self: flex-start !important;
}
.align-self-end {
	-ms-flex-item-align: end !important;
	align-self: flex-end !important;
}
.align-self-center {
	-ms-flex-item-align: center !important;
	align-self: center !important;
}
.align-self-baseline {
	-ms-flex-item-align: baseline !important;
	align-self: baseline !important;
}
.align-self-stretch {
	-ms-flex-item-align: stretch !important;
	align-self: stretch !important;
}
.m-0 {
	margin: 0 !important;
}
.mt-0 {
	margin-top: 0 !important;
}
.my-0 {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}
.mr-0 {
	margin-right: 0 !important;
}
.mx-0 {
	margin-right: 0 !important;
	margin-left: 0 !important;
}
.mb-0 {
	margin-bottom: 0 !important;
}
.ml-0 {
	margin-left: 0 !important;
}
.m-1 {
	margin: .25rem !important;
}
.mt-1 {
	margin-top: .25rem !important;
}
.my-1 {
	margin-top: .25rem !important;
	margin-bottom: .25rem !important;
}
.mr-1 {
	margin-right: .25rem !important;
}
.mx-1 {
	margin-right: .25rem !important;
	margin-left: .25rem !important;
}
.mb-1 {
	margin-bottom: .25rem !important;
}
.ml-1 {
	margin-left: .25rem !important;
}
.m-2 {
	margin: .5rem !important;
}
.mt-2 {
	margin-top: .5rem !important;
}
.my-2 {
	margin-top: .5rem !important;
	margin-bottom: .5rem !important;
}
.mr-2 {
	margin-right: .5rem !important;
}
.mx-2 {
	margin-right: .5rem !important;
	margin-left: .5rem !important;
}
.mb-2 {
	margin-bottom: .5rem !important;
}
.ml-2 {
	margin-left: .5rem !important;
}
.m-3 {
	margin: 1rem !important;
}
.mt-3 {
	margin-top: 1rem !important;
}
.my-3 {
	margin-top: 1rem !important;
	margin-bottom: 1rem !important;
}
.mr-3 {
	margin-right: 1rem !important;
}
.mx-3 {
	margin-right: 1rem !important;
	margin-left: 1rem !important;
}
.mb-3 {
	margin-bottom: 1rem !important;
}
.ml-3 {
	margin-left: 1rem !important;
}
.m-4 {
	margin: 1.5rem !important;
}
.mt-4 {
	margin-top: 1.5rem !important;
}
.my-4 {
	margin-top: 1.5rem !important;
	margin-bottom: 1.5rem !important;
}
.mr-4 {
	margin-right: 1.5rem !important;
}
.mx-4 {
	margin-right: 1.5rem !important;
	margin-left: 1.5rem !important;
}
.mb-4 {
	margin-bottom: 1.5rem !important;
}
.ml-4 {
	margin-left: 1.5rem !important;
}
.m-5 {
	margin: 3rem !important;
}
.mt-5 {
	margin-top: 3rem !important;
}
.my-5 {
	margin-top: 3rem !important;
	margin-bottom: 3rem !important;
}
.mr-5 {
	margin-right: 3rem !important;
}
.mx-5 {
	margin-right: 3rem !important;
	margin-left: 3rem !important;
}
.mb-5 {
	margin-bottom: 3rem !important;
}
.ml-5 {
	margin-left: 3rem !important;
}
.p-0 {
	padding: 0 !important;
}
.pt-0 {
	padding-top: 0 !important;
}
.py-0 {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}
.pr-0 {
	padding-right: 0 !important;
}
.px-0 {
	padding-right: 0 !important;
	padding-left: 0 !important;
}
.pb-0 {
	padding-bottom: 0 !important;
}
.pl-0 {
	padding-left: 0 !important;
}
.p-1 {
	padding: .25rem !important;
}
.pt-1 {
	padding-top: .25rem !important;
}
.py-1 {
	padding-top: .25rem !important;
	padding-bottom: .25rem !important;
}
.pr-1 {
	padding-right: .25rem !important;
}
.px-1 {
	padding-right: .25rem !important;
	padding-left: .25rem !important;
}
.pb-1 {
	padding-bottom: .25rem !important;
}
.pl-1 {
	padding-left: .25rem !important;
}
.p-2 {
	padding: .5rem !important;
}
.pt-2 {
	padding-top: .5rem !important;
}
.py-2 {
	padding-top: .5rem !important;
	padding-bottom: .5rem !important;
}
.pr-2 {
	padding-right: .5rem !important;
}
.px-2 {
	padding-right: .5rem !important;
	padding-left: .5rem !important;
}
.pb-2 {
	padding-bottom: .5rem !important;
}
.pl-2 {
	padding-left: .5rem !important;
}
.p-3 {
	padding: 1rem !important;
}
.pt-3 {
	padding-top: 1rem !important;
}
.py-3 {
	padding-top: 1rem !important;
	padding-bottom: 1rem !important;
}
.pr-3 {
	padding-right: 1rem !important;
}
.px-3 {
	padding-right: 1rem !important;
	padding-left: 1rem !important;
}
.pb-3 {
	padding-bottom: 1rem !important;
}
.pl-3 {
	padding-left: 1rem !important;
}
.p-4 {
	padding: 1.5rem !important;
}
.pt-4 {
	padding-top: 1.5rem !important;
}
.py-4 {
	padding-top: 1.5rem !important;
	padding-bottom: 1.5rem !important;
}
.pr-4 {
	padding-right: 1.5rem !important;
}
.px-4 {
	padding-right: 1.5rem !important;
	padding-left: 1.5rem !important;
}
.pb-4 {
	padding-bottom: 1.5rem !important;
}
.pl-4 {
	padding-left: 1.5rem !important;
}
.p-5 {
	padding: 3rem !important;
}
.pt-5 {
	padding-top: 3rem !important;
}
.py-5 {
	padding-top: 3rem !important;
	padding-bottom: 3rem !important;
}
.pr-5 {
	padding-right: 3rem !important;
}
.px-5 {
	padding-right: 3rem !important;
	padding-left: 3rem !important;
}
.pb-5 {
	padding-bottom: 3rem !important;
}
.pl-5 {
	padding-left: 3rem !important;
}
.m-n1 {
	margin: -.25rem !important;
}
.mt-n1 {
	margin-top: -.25rem !important;
}
.my-n1 {
	margin-top: -.25rem !important;
	margin-bottom: -.25rem !important;
}
.mr-n1 {
	margin-right: -.25rem !important;
}
.mx-n1 {
	margin-right: -.25rem !important;
	margin-left: -.25rem !important;
}
.mb-n1 {
	margin-bottom: -.25rem !important;
}
.ml-n1 {
	margin-left: -.25rem !important;
}
.m-n2 {
	margin: -.5rem !important;
}
.mt-n2 {
	margin-top: -.5rem !important;
}
.my-n2 {
	margin-top: -.5rem !important;
	margin-bottom: -.5rem !important;
}
.mr-n2 {
	margin-right: -.5rem !important;
}
.mx-n2 {
	margin-right: -.5rem !important;
	margin-left: -.5rem !important;
}
.mb-n2 {
	margin-bottom: -.5rem !important;
}
.ml-n2 {
	margin-left: -.5rem !important;
}
.m-n3 {
	margin: -1rem !important;
}
.mt-n3 {
	margin-top: -1rem !important;
}
.my-n3 {
	margin-top: -1rem !important;
	margin-bottom: -1rem !important;
}
.mr-n3 {
	margin-right: -1rem !important;
}
.mx-n3 {
	margin-right: -1rem !important;
	margin-left: -1rem !important;
}
.mb-n3 {
	margin-bottom: -1rem !important;
}
.ml-n3 {
	margin-left: -1rem !important;
}
.m-n4 {
	margin: -1.5rem !important;
}
.mt-n4 {
	margin-top: -1.5rem !important;
}
.my-n4 {
	margin-top: -1.5rem !important;
	margin-bottom: -1.5rem !important;
}
.mr-n4 {
	margin-right: -1.5rem !important;
}
.mx-n4 {
	margin-right: -1.5rem !important;
	margin-left: -1.5rem !important;
}
.mb-n4 {
	margin-bottom: -1.5rem !important;
}
.ml-n4 {
	margin-left: -1.5rem !important;
}
.m-n5 {
	margin: -3rem !important;
}
.mt-n5 {
	margin-top: -3rem !important;
}
.my-n5 {
	margin-top: -3rem !important;
	margin-bottom: -3rem !important;
}
.mr-n5 {
	margin-right: -3rem !important;
}
.mx-n5 {
	margin-right: -3rem !important;
	margin-left: -3rem !important;
}
.mb-n5 {
	margin-bottom: -3rem !important;
}
.ml-n5 {
	margin-left: -3rem !important;
}
.m-auto {
	margin: auto !important;
}
.mt-auto {
	margin-top: auto !important;
}
.my-auto {
	margin-top: auto !important;
	margin-bottom: auto !important;
}
.mr-auto {
	margin-right: auto !important;
}
.mx-auto {
	margin-right: auto !important;
	margin-left: auto !important;
}
.mb-auto {
	margin-bottom: auto !important;
}
.ml-auto {
	margin-left: auto !important;
}
@media (min-width: $screen-xs-max) {
	.container {
		max-width: 540px;
	}
	.col-sm {
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		-ms-flex-positive: 1;
		flex-grow: 1;
		max-width: 100%;
	}
	.col-sm-auto {
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		width: auto;
		max-width: 100%;
	}
	.col-sm-1 {
		-ms-flex: 0 0 8.333333%;
		flex: 0 0 8.333333%;
		max-width: 8.333333%;
	}
	.col-sm-2 {
		-ms-flex: 0 0 16.666667%;
		flex: 0 0 16.666667%;
		max-width: 16.666667%;
	}
	.col-sm-3 {
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%;
	}
	.col-sm-4 {
		-ms-flex: 0 0 33.333333%;
		flex: 0 0 33.333333%;
		max-width: 33.333333%;
	}
	.col-sm-5 {
		-ms-flex: 0 0 41.666667%;
		flex: 0 0 41.666667%;
		max-width: 41.666667%;
	}
	.col-sm-6 {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}
	.col-sm-7 {
		-ms-flex: 0 0 58.333333%;
		flex: 0 0 58.333333%;
		max-width: 58.333333%;
	}
	.col-sm-8 {
		-ms-flex: 0 0 66.666667%;
		flex: 0 0 66.666667%;
		max-width: 66.666667%;
	}
	.col-sm-9 {
		-ms-flex: 0 0 75%;
		flex: 0 0 75%;
		max-width: 75%;
	}
	.col-sm-10 {
		-ms-flex: 0 0 83.333333%;
		flex: 0 0 83.333333%;
		max-width: 83.333333%;
	}
	.col-sm-11 {
		-ms-flex: 0 0 91.666667%;
		flex: 0 0 91.666667%;
		max-width: 91.666667%;
	}
	.col-sm-12 {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
	.order-sm-first {
		-ms-flex-order: -1;
		order: -1;
	}
	.order-sm-last {
		-ms-flex-order: 13;
		order: 13;
	}
	.order-sm-0 {
		-ms-flex-order: 0;
		order: 0;
	}
	.order-sm-1 {
		-ms-flex-order: 1;
		order: 1;
	}
	.order-sm-2 {
		-ms-flex-order: 2;
		order: 2;
	}
	.order-sm-3 {
		-ms-flex-order: 3;
		order: 3;
	}
	.order-sm-4 {
		-ms-flex-order: 4;
		order: 4;
	}
	.order-sm-5 {
		-ms-flex-order: 5;
		order: 5;
	}
	.order-sm-6 {
		-ms-flex-order: 6;
		order: 6;
	}
	.order-sm-7 {
		-ms-flex-order: 7;
		order: 7;
	}
	.order-sm-8 {
		-ms-flex-order: 8;
		order: 8;
	}
	.order-sm-9 {
		-ms-flex-order: 9;
		order: 9;
	}
	.order-sm-10 {
		-ms-flex-order: 10;
		order: 10;
	}
	.order-sm-11 {
		-ms-flex-order: 11;
		order: 11;
	}
	.order-sm-12 {
		-ms-flex-order: 12;
		order: 12;
	}
	.offset-sm-0 {
		margin-left: 0;
	}
	.offset-sm-1 {
		margin-left: 8.333333%;
	}
	.offset-sm-2 {
		margin-left: 16.666667%;
	}
	.offset-sm-3 {
		margin-left: 25%;
	}
	.offset-sm-4 {
		margin-left: 33.333333%;
	}
	.offset-sm-5 {
		margin-left: 41.666667%;
	}
	.offset-sm-6 {
		margin-left: 50%;
	}
	.offset-sm-7 {
		margin-left: 58.333333%;
	}
	.offset-sm-8 {
		margin-left: 66.666667%;
	}
	.offset-sm-9 {
		margin-left: 75%;
	}
	.offset-sm-10 {
		margin-left: 83.333333%;
	}
	.offset-sm-11 {
		margin-left: 91.666667%;
	}
	.d-sm-none {
		display: none !important;
	}
	.d-sm-inline {
		display: inline !important;
	}
	.d-sm-inline-block {
		display: inline-block !important;
	}
	.d-sm-block {
		display: block !important;
	}
	.d-sm-table {
		display: table !important;
	}
	.d-sm-table-row {
		display: table-row !important;
	}
	.d-sm-table-cell {
		display: table-cell !important;
	}
	.d-sm-flex {
		display: -ms-flexbox !important;
		display: flex !important;
	}
	.d-sm-inline-flex {
		display: -ms-inline-flexbox !important;
		display: inline-flex !important;
	}
	.flex-sm-row {
		-ms-flex-direction: row !important;
		flex-direction: row !important;
	}
	.flex-sm-column {
		-ms-flex-direction: column !important;
		flex-direction: column !important;
	}
	.flex-sm-row-reverse {
		-ms-flex-direction: row-reverse !important;
		flex-direction: row-reverse !important;
	}
	.flex-sm-column-reverse {
		-ms-flex-direction: column-reverse !important;
		flex-direction: column-reverse !important;
	}
	.flex-sm-wrap {
		-ms-flex-wrap: wrap !important;
		flex-wrap: wrap !important;
	}
	.flex-sm-nowrap {
		-ms-flex-wrap: nowrap !important;
		flex-wrap: nowrap !important;
	}
	.flex-sm-wrap-reverse {
		-ms-flex-wrap: wrap-reverse !important;
		flex-wrap: wrap-reverse !important;
	}
	.flex-sm-fill {
		-ms-flex: 1 1 auto !important;
		flex: 1 1 auto !important;
	}
	.flex-sm-grow-0 {
		-ms-flex-positive: 0 !important;
		flex-grow: 0 !important;
	}
	.flex-sm-grow-1 {
		-ms-flex-positive: 1 !important;
		flex-grow: 1 !important;
	}
	.flex-sm-shrink-0 {
		-ms-flex-negative: 0 !important;
		flex-shrink: 0 !important;
	}
	.flex-sm-shrink-1 {
		-ms-flex-negative: 1 !important;
		flex-shrink: 1 !important;
	}
	.justify-content-sm-start {
		-ms-flex-pack: start !important;
		justify-content: flex-start !important;
	}
	.justify-content-sm-end {
		-ms-flex-pack: end !important;
		justify-content: flex-end !important;
	}
	.justify-content-sm-center {
		-ms-flex-pack: center !important;
		justify-content: center !important;
	}
	.justify-content-sm-between {
		-ms-flex-pack: justify !important;
		justify-content: space-between !important;
	}
	.justify-content-sm-around {
		-ms-flex-pack: distribute !important;
		justify-content: space-around !important;
	}
	.align-items-sm-start {
		-ms-flex-align: start !important;
		align-items: flex-start !important;
	}
	.align-items-sm-end {
		-ms-flex-align: end !important;
		align-items: flex-end !important;
	}
	.align-items-sm-center {
		-ms-flex-align: center !important;
		align-items: center !important;
	}
	.align-items-sm-baseline {
		-ms-flex-align: baseline !important;
		align-items: baseline !important;
	}
	.align-items-sm-stretch {
		-ms-flex-align: stretch !important;
		align-items: stretch !important;
	}
	.align-content-sm-start {
		-ms-flex-line-pack: start !important;
		align-content: flex-start !important;
	}
	.align-content-sm-end {
		-ms-flex-line-pack: end !important;
		align-content: flex-end !important;
	}
	.align-content-sm-center {
		-ms-flex-line-pack: center !important;
		align-content: center !important;
	}
	.align-content-sm-between {
		-ms-flex-line-pack: justify !important;
		align-content: space-between !important;
	}
	.align-content-sm-around {
		-ms-flex-line-pack: distribute !important;
		align-content: space-around !important;
	}
	.align-content-sm-stretch {
		-ms-flex-line-pack: stretch !important;
		align-content: stretch !important;
	}
	.align-self-sm-auto {
		-ms-flex-item-align: auto !important;
		align-self: auto !important;
	}
	.align-self-sm-start {
		-ms-flex-item-align: start !important;
		align-self: flex-start !important;
	}
	.align-self-sm-end {
		-ms-flex-item-align: end !important;
		align-self: flex-end !important;
	}
	.align-self-sm-center {
		-ms-flex-item-align: center !important;
		align-self: center !important;
	}
	.align-self-sm-baseline {
		-ms-flex-item-align: baseline !important;
		align-self: baseline !important;
	}
	.align-self-sm-stretch {
		-ms-flex-item-align: stretch !important;
		align-self: stretch !important;
	}
	.m-sm-0 {
		margin: 0 !important;
	}
	.mt-sm-0 {
		margin-top: 0 !important;
	}
	.my-sm-0 {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}
	.mr-sm-0 {
		margin-right: 0 !important;
	}
	.mx-sm-0 {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.mb-sm-0 {
		margin-bottom: 0 !important;
	}
	.ml-sm-0 {
		margin-left: 0 !important;
	}
	.m-sm-1 {
		margin: .25rem !important;
	}
	.mt-sm-1 {
		margin-top: .25rem !important;
	}
	.my-sm-1 {
		margin-top: .25rem !important;
		margin-bottom: .25rem !important;
	}
	.mr-sm-1 {
		margin-right: .25rem !important;
	}
	.mx-sm-1 {
		margin-right: .25rem !important;
		margin-left: .25rem !important;
	}
	.mb-sm-1 {
		margin-bottom: .25rem !important;
	}
	.ml-sm-1 {
		margin-left: .25rem !important;
	}
	.m-sm-2 {
		margin: .5rem !important;
	}
	.mt-sm-2 {
		margin-top: .5rem !important;
	}
	.my-sm-2 {
		margin-top: .5rem !important;
		margin-bottom: .5rem !important;
	}
	.mr-sm-2 {
		margin-right: .5rem !important;
	}
	.mx-sm-2 {
		margin-right: .5rem !important;
		margin-left: .5rem !important;
	}
	.mb-sm-2 {
		margin-bottom: .5rem !important;
	}
	.ml-sm-2 {
		margin-left: .5rem !important;
	}
	.m-sm-3 {
		margin: 1rem !important;
	}
	.mt-sm-3 {
		margin-top: 1rem !important;
	}
	.my-sm-3 {
		margin-top: 1rem !important;
		margin-bottom: 1rem !important;
	}
	.mr-sm-3 {
		margin-right: 1rem !important;
	}
	.mx-sm-3 {
		margin-right: 1rem !important;
		margin-left: 1rem !important;
	}
	.mb-sm-3 {
		margin-bottom: 1rem !important;
	}
	.ml-sm-3 {
		margin-left: 1rem !important;
	}
	.m-sm-4 {
		margin: 1.5rem !important;
	}
	.mt-sm-4 {
		margin-top: 1.5rem !important;
	}
	.my-sm-4 {
		margin-top: 1.5rem !important;
		margin-bottom: 1.5rem !important;
	}
	.mr-sm-4 {
		margin-right: 1.5rem !important;
	}
	.mx-sm-4 {
		margin-right: 1.5rem !important;
		margin-left: 1.5rem !important;
	}
	.mb-sm-4 {
		margin-bottom: 1.5rem !important;
	}
	.ml-sm-4 {
		margin-left: 1.5rem !important;
	}
	.m-sm-5 {
		margin: 3rem !important;
	}
	.mt-sm-5 {
		margin-top: 3rem !important;
	}
	.my-sm-5 {
		margin-top: 3rem !important;
		margin-bottom: 3rem !important;
	}
	.mr-sm-5 {
		margin-right: 3rem !important;
	}
	.mx-sm-5 {
		margin-right: 3rem !important;
		margin-left: 3rem !important;
	}
	.mb-sm-5 {
		margin-bottom: 3rem !important;
	}
	.ml-sm-5 {
		margin-left: 3rem !important;
	}
	.p-sm-0 {
		padding: 0 !important;
	}
	.pt-sm-0 {
		padding-top: 0 !important;
	}
	.py-sm-0 {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}
	.pr-sm-0 {
		padding-right: 0 !important;
	}
	.px-sm-0 {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
	.pb-sm-0 {
		padding-bottom: 0 !important;
	}
	.pl-sm-0 {
		padding-left: 0 !important;
	}
	.p-sm-1 {
		padding: .25rem !important;
	}
	.pt-sm-1 {
		padding-top: .25rem !important;
	}
	.py-sm-1 {
		padding-top: .25rem !important;
		padding-bottom: .25rem !important;
	}
	.pr-sm-1 {
		padding-right: .25rem !important;
	}
	.px-sm-1 {
		padding-right: .25rem !important;
		padding-left: .25rem !important;
	}
	.pb-sm-1 {
		padding-bottom: .25rem !important;
	}
	.pl-sm-1 {
		padding-left: .25rem !important;
	}
	.p-sm-2 {
		padding: .5rem !important;
	}
	.pt-sm-2 {
		padding-top: .5rem !important;
	}
	.py-sm-2 {
		padding-top: .5rem !important;
		padding-bottom: .5rem !important;
	}
	.pr-sm-2 {
		padding-right: .5rem !important;
	}
	.px-sm-2 {
		padding-right: .5rem !important;
		padding-left: .5rem !important;
	}
	.pb-sm-2 {
		padding-bottom: .5rem !important;
	}
	.pl-sm-2 {
		padding-left: .5rem !important;
	}
	.p-sm-3 {
		padding: 1rem !important;
	}
	.pt-sm-3 {
		padding-top: 1rem !important;
	}
	.py-sm-3 {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important;
	}
	.pr-sm-3 {
		padding-right: 1rem !important;
	}
	.px-sm-3 {
		padding-right: 1rem !important;
		padding-left: 1rem !important;
	}
	.pb-sm-3 {
		padding-bottom: 1rem !important;
	}
	.pl-sm-3 {
		padding-left: 1rem !important;
	}
	.p-sm-4 {
		padding: 1.5rem !important;
	}
	.pt-sm-4 {
		padding-top: 1.5rem !important;
	}
	.py-sm-4 {
		padding-top: 1.5rem !important;
		padding-bottom: 1.5rem !important;
	}
	.pr-sm-4 {
		padding-right: 1.5rem !important;
	}
	.px-sm-4 {
		padding-right: 1.5rem !important;
		padding-left: 1.5rem !important;
	}
	.pb-sm-4 {
		padding-bottom: 1.5rem !important;
	}
	.pl-sm-4 {
		padding-left: 1.5rem !important;
	}
	.p-sm-5 {
		padding: 3rem !important;
	}
	.pt-sm-5 {
		padding-top: 3rem !important;
	}
	.py-sm-5 {
		padding-top: 3rem !important;
		padding-bottom: 3rem !important;
	}
	.pr-sm-5 {
		padding-right: 3rem !important;
	}
	.px-sm-5 {
		padding-right: 3rem !important;
		padding-left: 3rem !important;
	}
	.pb-sm-5 {
		padding-bottom: 3rem !important;
	}
	.pl-sm-5 {
		padding-left: 3rem !important;
	}
	.m-sm-n1 {
		margin: -.25rem !important;
	}
	.mt-sm-n1 {
		margin-top: -.25rem !important;
	}
	.my-sm-n1 {
		margin-top: -.25rem !important;
		margin-bottom: -.25rem !important;
	}
	.mr-sm-n1 {
		margin-right: -.25rem !important;
	}
	.mx-sm-n1 {
		margin-right: -.25rem !important;
		margin-left: -.25rem !important;
	}
	.mb-sm-n1 {
		margin-bottom: -.25rem !important;
	}
	.ml-sm-n1 {
		margin-left: -.25rem !important;
	}
	.m-sm-n2 {
		margin: -.5rem !important;
	}
	.mt-sm-n2 {
		margin-top: -.5rem !important;
	}
	.my-sm-n2 {
		margin-top: -.5rem !important;
		margin-bottom: -.5rem !important;
	}
	.mr-sm-n2 {
		margin-right: -.5rem !important;
	}
	.mx-sm-n2 {
		margin-right: -.5rem !important;
		margin-left: -.5rem !important;
	}
	.mb-sm-n2 {
		margin-bottom: -.5rem !important;
	}
	.ml-sm-n2 {
		margin-left: -.5rem !important;
	}
	.m-sm-n3 {
		margin: -1rem !important;
	}
	.mt-sm-n3 {
		margin-top: -1rem !important;
	}
	.my-sm-n3 {
		margin-top: -1rem !important;
		margin-bottom: -1rem !important;
	}
	.mr-sm-n3 {
		margin-right: -1rem !important;
	}
	.mx-sm-n3 {
		margin-right: -1rem !important;
		margin-left: -1rem !important;
	}
	.mb-sm-n3 {
		margin-bottom: -1rem !important;
	}
	.ml-sm-n3 {
		margin-left: -1rem !important;
	}
	.m-sm-n4 {
		margin: -1.5rem !important;
	}
	.mt-sm-n4 {
		margin-top: -1.5rem !important;
	}
	.my-sm-n4 {
		margin-top: -1.5rem !important;
		margin-bottom: -1.5rem !important;
	}
	.mr-sm-n4 {
		margin-right: -1.5rem !important;
	}
	.mx-sm-n4 {
		margin-right: -1.5rem !important;
		margin-left: -1.5rem !important;
	}
	.mb-sm-n4 {
		margin-bottom: -1.5rem !important;
	}
	.ml-sm-n4 {
		margin-left: -1.5rem !important;
	}
	.m-sm-n5 {
		margin: -3rem !important;
	}
	.mt-sm-n5 {
		margin-top: -3rem !important;
	}
	.my-sm-n5 {
		margin-top: -3rem !important;
		margin-bottom: -3rem !important;
	}
	.mr-sm-n5 {
		margin-right: -3rem !important;
	}
	.mx-sm-n5 {
		margin-right: -3rem !important;
		margin-left: -3rem !important;
	}
	.mb-sm-n5 {
		margin-bottom: -3rem !important;
	}
	.ml-sm-n5 {
		margin-left: -3rem !important;
	}
	.m-sm-auto {
		margin: auto !important;
	}
	.mt-sm-auto {
		margin-top: auto !important;
	}
	.my-sm-auto {
		margin-top: auto !important;
		margin-bottom: auto !important;
	}
	.mr-sm-auto {
		margin-right: auto !important;
	}
	.mx-sm-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
	.mb-sm-auto {
		margin-bottom: auto !important;
	}
	.ml-sm-auto {
		margin-left: auto !important;
	}
}
@media (min-width:$screen-sm-max) {
	.container {
		max-width: 720px;
	}
	.col-md {
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		-ms-flex-positive: 1;
		flex-grow: 1;
		max-width: 100%;
	}
	.col-md-auto {
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		width: auto;
		max-width: 100%;
	}
	.col-md-1 {
		-ms-flex: 0 0 8.333333%;
		flex: 0 0 8.333333%;
		max-width: 8.333333%;
	}
	.col-md-2 {
		-ms-flex: 0 0 16.666667%;
		flex: 0 0 16.666667%;
		max-width: 16.666667%;
	}
	.col-md-3 {
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%;
	}
	.col-md-4 {
		-ms-flex: 0 0 33.333333%;
		flex: 0 0 33.333333%;
		max-width: 33.333333%;
	}
	.col-md-5 {
		-ms-flex: 0 0 41.666667%;
		flex: 0 0 41.666667%;
		max-width: 41.666667%;
	}
	.col-md-6 {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}
	.col-md-7 {
		-ms-flex: 0 0 58.333333%;
		flex: 0 0 58.333333%;
		max-width: 58.333333%;
	}
	.col-md-8 {
		-ms-flex: 0 0 66.666667%;
		flex: 0 0 66.666667%;
		max-width: 66.666667%;
	}
	.col-md-9 {
		-ms-flex: 0 0 75%;
		flex: 0 0 75%;
		max-width: 75%;
	}
	.col-md-10 {
		-ms-flex: 0 0 83.333333%;
		flex: 0 0 83.333333%;
		max-width: 83.333333%;
	}
	.col-md-11 {
		-ms-flex: 0 0 91.666667%;
		flex: 0 0 91.666667%;
		max-width: 91.666667%;
	}
	.col-md-12 {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
	.order-md-first {
		-ms-flex-order: -1;
		order: -1;
	}
	.order-md-last {
		-ms-flex-order: 13;
		order: 13;
	}
	.order-md-0 {
		-ms-flex-order: 0;
		order: 0;
	}
	.order-md-1 {
		-ms-flex-order: 1;
		order: 1;
	}
	.order-md-2 {
		-ms-flex-order: 2;
		order: 2;
	}
	.order-md-3 {
		-ms-flex-order: 3;
		order: 3;
	}
	.order-md-4 {
		-ms-flex-order: 4;
		order: 4;
	}
	.order-md-5 {
		-ms-flex-order: 5;
		order: 5;
	}
	.order-md-6 {
		-ms-flex-order: 6;
		order: 6;
	}
	.order-md-7 {
		-ms-flex-order: 7;
		order: 7;
	}
	.order-md-8 {
		-ms-flex-order: 8;
		order: 8;
	}
	.order-md-9 {
		-ms-flex-order: 9;
		order: 9;
	}
	.order-md-10 {
		-ms-flex-order: 10;
		order: 10;
	}
	.order-md-11 {
		-ms-flex-order: 11;
		order: 11;
	}
	.order-md-12 {
		-ms-flex-order: 12;
		order: 12;
	}
	.offset-md-0 {
		margin-left: 0;
	}
	.offset-md-1 {
		margin-left: 8.333333%;
	}
	.offset-md-2 {
		margin-left: 16.666667%;
	}
	.offset-md-3 {
		margin-left: 25%;
	}
	.offset-md-4 {
		margin-left: 33.333333%;
	}
	.offset-md-5 {
		margin-left: 41.666667%;
	}
	.offset-md-6 {
		margin-left: 50%;
	}
	.offset-md-7 {
		margin-left: 58.333333%;
	}
	.offset-md-8 {
		margin-left: 66.666667%;
	}
	.offset-md-9 {
		margin-left: 75%;
	}
	.offset-md-10 {
		margin-left: 83.333333%;
	}
	.offset-md-11 {
		margin-left: 91.666667%;
	}
	.d-md-none {
		display: none !important;
	}
	.d-md-inline {
		display: inline !important;
	}
	.d-md-inline-block {
		display: inline-block !important;
	}
	.d-md-block {
		display: block !important;
	}
	.d-md-table {
		display: table !important;
	}
	.d-md-table-row {
		display: table-row !important;
	}
	.d-md-table-cell {
		display: table-cell !important;
	}
	.d-md-flex {
		display: -ms-flexbox !important;
		display: flex !important;
	}
	.d-md-inline-flex {
		display: -ms-inline-flexbox !important;
		display: inline-flex !important;
	}
	.flex-md-row {
		-ms-flex-direction: row !important;
		flex-direction: row !important;
	}
	.flex-md-column {
		-ms-flex-direction: column !important;
		flex-direction: column !important;
	}
	.flex-md-row-reverse {
		-ms-flex-direction: row-reverse !important;
		flex-direction: row-reverse !important;
	}
	.flex-md-column-reverse {
		-ms-flex-direction: column-reverse !important;
		flex-direction: column-reverse !important;
	}
	.flex-md-wrap {
		-ms-flex-wrap: wrap !important;
		flex-wrap: wrap !important;
	}
	.flex-md-nowrap {
		-ms-flex-wrap: nowrap !important;
		flex-wrap: nowrap !important;
	}
	.flex-md-wrap-reverse {
		-ms-flex-wrap: wrap-reverse !important;
		flex-wrap: wrap-reverse !important;
	}
	.flex-md-fill {
		-ms-flex: 1 1 auto !important;
		flex: 1 1 auto !important;
	}
	.flex-md-grow-0 {
		-ms-flex-positive: 0 !important;
		flex-grow: 0 !important;
	}
	.flex-md-grow-1 {
		-ms-flex-positive: 1 !important;
		flex-grow: 1 !important;
	}
	.flex-md-shrink-0 {
		-ms-flex-negative: 0 !important;
		flex-shrink: 0 !important;
	}
	.flex-md-shrink-1 {
		-ms-flex-negative: 1 !important;
		flex-shrink: 1 !important;
	}
	.justify-content-md-start {
		-ms-flex-pack: start !important;
		justify-content: flex-start !important;
	}
	.justify-content-md-end {
		-ms-flex-pack: end !important;
		justify-content: flex-end !important;
	}
	.justify-content-md-center {
		-ms-flex-pack: center !important;
		justify-content: center !important;
	}
	.justify-content-md-between {
		-ms-flex-pack: justify !important;
		justify-content: space-between !important;
	}
	.justify-content-md-around {
		-ms-flex-pack: distribute !important;
		justify-content: space-around !important;
	}
	.align-items-md-start {
		-ms-flex-align: start !important;
		align-items: flex-start !important;
	}
	.align-items-md-end {
		-ms-flex-align: end !important;
		align-items: flex-end !important;
	}
	.align-items-md-center {
		-ms-flex-align: center !important;
		align-items: center !important;
	}
	.align-items-md-baseline {
		-ms-flex-align: baseline !important;
		align-items: baseline !important;
	}
	.align-items-md-stretch {
		-ms-flex-align: stretch !important;
		align-items: stretch !important;
	}
	.align-content-md-start {
		-ms-flex-line-pack: start !important;
		align-content: flex-start !important;
	}
	.align-content-md-end {
		-ms-flex-line-pack: end !important;
		align-content: flex-end !important;
	}
	.align-content-md-center {
		-ms-flex-line-pack: center !important;
		align-content: center !important;
	}
	.align-content-md-between {
		-ms-flex-line-pack: justify !important;
		align-content: space-between !important;
	}
	.align-content-md-around {
		-ms-flex-line-pack: distribute !important;
		align-content: space-around !important;
	}
	.align-content-md-stretch {
		-ms-flex-line-pack: stretch !important;
		align-content: stretch !important;
	}
	.align-self-md-auto {
		-ms-flex-item-align: auto !important;
		align-self: auto !important;
	}
	.align-self-md-start {
		-ms-flex-item-align: start !important;
		align-self: flex-start !important;
	}
	.align-self-md-end {
		-ms-flex-item-align: end !important;
		align-self: flex-end !important;
	}
	.align-self-md-center {
		-ms-flex-item-align: center !important;
		align-self: center !important;
	}
	.align-self-md-baseline {
		-ms-flex-item-align: baseline !important;
		align-self: baseline !important;
	}
	.align-self-md-stretch {
		-ms-flex-item-align: stretch !important;
		align-self: stretch !important;
	}
	.m-md-0 {
		margin: 0 !important;
	}
	.mt-md-0 {
		margin-top: 0 !important;
	}
	.my-md-0 {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}
	.mr-md-0 {
		margin-right: 0 !important;
	}
	.mx-md-0 {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.mb-md-0 {
		margin-bottom: 0 !important;
	}
	.ml-md-0 {
		margin-left: 0 !important;
	}
	.m-md-1 {
		margin: .25rem !important;
	}
	.mt-md-1 {
		margin-top: .25rem !important;
	}
	.my-md-1 {
		margin-top: .25rem !important;
		margin-bottom: .25rem !important;
	}
	.mr-md-1 {
		margin-right: .25rem !important;
	}
	.mx-md-1 {
		margin-right: .25rem !important;
		margin-left: .25rem !important;
	}
	.mb-md-1 {
		margin-bottom: .25rem !important;
	}
	.ml-md-1 {
		margin-left: .25rem !important;
	}
	.m-md-2 {
		margin: .5rem !important;
	}
	.mt-md-2 {
		margin-top: .5rem !important;
	}
	.my-md-2 {
		margin-top: .5rem !important;
		margin-bottom: .5rem !important;
	}
	.mr-md-2 {
		margin-right: .5rem !important;
	}
	.mx-md-2 {
		margin-right: .5rem !important;
		margin-left: .5rem !important;
	}
	.mb-md-2 {
		margin-bottom: .5rem !important;
	}
	.ml-md-2 {
		margin-left: .5rem !important;
	}
	.m-md-3 {
		margin: 1rem !important;
	}
	.mt-md-3 {
		margin-top: 1rem !important;
	}
	.my-md-3 {
		margin-top: 1rem !important;
		margin-bottom: 1rem !important;
	}
	.mr-md-3 {
		margin-right: 1rem !important;
	}
	.mx-md-3 {
		margin-right: 1rem !important;
		margin-left: 1rem !important;
	}
	.mb-md-3 {
		margin-bottom: 1rem !important;
	}
	.ml-md-3 {
		margin-left: 1rem !important;
	}
	.m-md-4 {
		margin: 1.5rem !important;
	}
	.mt-md-4 {
		margin-top: 1.5rem !important;
	}
	.my-md-4 {
		margin-top: 1.5rem !important;
		margin-bottom: 1.5rem !important;
	}
	.mr-md-4 {
		margin-right: 1.5rem !important;
	}
	.mx-md-4 {
		margin-right: 1.5rem !important;
		margin-left: 1.5rem !important;
	}
	.mb-md-4 {
		margin-bottom: 1.5rem !important;
	}
	.ml-md-4 {
		margin-left: 1.5rem !important;
	}
	.m-md-5 {
		margin: 3rem !important;
	}
	.mt-md-5 {
		margin-top: 3rem !important;
	}
	.my-md-5 {
		margin-top: 3rem !important;
		margin-bottom: 3rem !important;
	}
	.mr-md-5 {
		margin-right: 3rem !important;
	}
	.mx-md-5 {
		margin-right: 3rem !important;
		margin-left: 3rem !important;
	}
	.mb-md-5 {
		margin-bottom: 3rem !important;
	}
	.ml-md-5 {
		margin-left: 3rem !important;
	}
	.p-md-0 {
		padding: 0 !important;
	}
	.pt-md-0 {
		padding-top: 0 !important;
	}
	.py-md-0 {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}
	.pr-md-0 {
		padding-right: 0 !important;
	}
	.px-md-0 {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
	.pb-md-0 {
		padding-bottom: 0 !important;
	}
	.pl-md-0 {
		padding-left: 0 !important;
	}
	.p-md-1 {
		padding: .25rem !important;
	}
	.pt-md-1 {
		padding-top: .25rem !important;
	}
	.py-md-1 {
		padding-top: .25rem !important;
		padding-bottom: .25rem !important;
	}
	.pr-md-1 {
		padding-right: .25rem !important;
	}
	.px-md-1 {
		padding-right: .25rem !important;
		padding-left: .25rem !important;
	}
	.pb-md-1 {
		padding-bottom: .25rem !important;
	}
	.pl-md-1 {
		padding-left: .25rem !important;
	}
	.p-md-2 {
		padding: .5rem !important;
	}
	.pt-md-2 {
		padding-top: .5rem !important;
	}
	.py-md-2 {
		padding-top: .5rem !important;
		padding-bottom: .5rem !important;
	}
	.pr-md-2 {
		padding-right: .5rem !important;
	}
	.px-md-2 {
		padding-right: .5rem !important;
		padding-left: .5rem !important;
	}
	.pb-md-2 {
		padding-bottom: .5rem !important;
	}
	.pl-md-2 {
		padding-left: .5rem !important;
	}
	.p-md-3 {
		padding: 1rem !important;
	}
	.pt-md-3 {
		padding-top: 1rem !important;
	}
	.py-md-3 {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important;
	}
	.pr-md-3 {
		padding-right: 1rem !important;
	}
	.px-md-3 {
		padding-right: 1rem !important;
		padding-left: 1rem !important;
	}
	.pb-md-3 {
		padding-bottom: 1rem !important;
	}
	.pl-md-3 {
		padding-left: 1rem !important;
	}
	.p-md-4 {
		padding: 1.5rem !important;
	}
	.pt-md-4 {
		padding-top: 1.5rem !important;
	}
	.py-md-4 {
		padding-top: 1.5rem !important;
		padding-bottom: 1.5rem !important;
	}
	.pr-md-4 {
		padding-right: 1.5rem !important;
	}
	.px-md-4 {
		padding-right: 1.5rem !important;
		padding-left: 1.5rem !important;
	}
	.pb-md-4 {
		padding-bottom: 1.5rem !important;
	}
	.pl-md-4 {
		padding-left: 1.5rem !important;
	}
	.p-md-5 {
		padding: 3rem !important;
	}
	.pt-md-5 {
		padding-top: 3rem !important;
	}
	.py-md-5 {
		padding-top: 3rem !important;
		padding-bottom: 3rem !important;
	}
	.pr-md-5 {
		padding-right: 3rem !important;
	}
	.px-md-5 {
		padding-right: 3rem !important;
		padding-left: 3rem !important;
	}
	.pb-md-5 {
		padding-bottom: 3rem !important;
	}
	.pl-md-5 {
		padding-left: 3rem !important;
	}
	.m-md-n1 {
		margin: -.25rem !important;
	}
	.mt-md-n1 {
		margin-top: -.25rem !important;
	}
	.my-md-n1 {
		margin-top: -.25rem !important;
		margin-bottom: -.25rem !important;
	}
	.mr-md-n1 {
		margin-right: -.25rem !important;
	}
	.mx-md-n1 {
		margin-right: -.25rem !important;
		margin-left: -.25rem !important;
	}
	.mb-md-n1 {
		margin-bottom: -.25rem !important;
	}
	.ml-md-n1 {
		margin-left: -.25rem !important;
	}
	.m-md-n2 {
		margin: -.5rem !important;
	}
	.mt-md-n2 {
		margin-top: -.5rem !important;
	}
	.my-md-n2 {
		margin-top: -.5rem !important;
		margin-bottom: -.5rem !important;
	}
	.mr-md-n2 {
		margin-right: -.5rem !important;
	}
	.mx-md-n2 {
		margin-right: -.5rem !important;
		margin-left: -.5rem !important;
	}
	.mb-md-n2 {
		margin-bottom: -.5rem !important;
	}
	.ml-md-n2 {
		margin-left: -.5rem !important;
	}
	.m-md-n3 {
		margin: -1rem !important;
	}
	.mt-md-n3 {
		margin-top: -1rem !important;
	}
	.my-md-n3 {
		margin-top: -1rem !important;
		margin-bottom: -1rem !important;
	}
	.mr-md-n3 {
		margin-right: -1rem !important;
	}
	.mx-md-n3 {
		margin-right: -1rem !important;
		margin-left: -1rem !important;
	}
	.mb-md-n3 {
		margin-bottom: -1rem !important;
	}
	.ml-md-n3 {
		margin-left: -1rem !important;
	}
	.m-md-n4 {
		margin: -1.5rem !important;
	}
	.mt-md-n4 {
		margin-top: -1.5rem !important;
	}
	.my-md-n4 {
		margin-top: -1.5rem !important;
		margin-bottom: -1.5rem !important;
	}
	.mr-md-n4 {
		margin-right: -1.5rem !important;
	}
	.mx-md-n4 {
		margin-right: -1.5rem !important;
		margin-left: -1.5rem !important;
	}
	.mb-md-n4 {
		margin-bottom: -1.5rem !important;
	}
	.ml-md-n4 {
		margin-left: -1.5rem !important;
	}
	.m-md-n5 {
		margin: -3rem !important;
	}
	.mt-md-n5 {
		margin-top: -3rem !important;
	}
	.my-md-n5 {
		margin-top: -3rem !important;
		margin-bottom: -3rem !important;
	}
	.mr-md-n5 {
		margin-right: -3rem !important;
	}
	.mx-md-n5 {
		margin-right: -3rem !important;
		margin-left: -3rem !important;
	}
	.mb-md-n5 {
		margin-bottom: -3rem !important;
	}
	.ml-md-n5 {
		margin-left: -3rem !important;
	}
	.m-md-auto {
		margin: auto !important;
	}
	.mt-md-auto {
		margin-top: auto !important;
	}
	.my-md-auto {
		margin-top: auto !important;
		margin-bottom: auto !important;
	}
	.mr-md-auto {
		margin-right: auto !important;
	}
	.mx-md-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
	.mb-md-auto {
		margin-bottom: auto !important;
	}
	.ml-md-auto {
		margin-left: auto !important;
	}
}
@media (min-width:$screen-md-max) {
	.container {
		max-width: 960px;
	}
	.col-lg {
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		-ms-flex-positive: 1;
		flex-grow: 1;
		max-width: 100%;
	}
	.col-lg-auto {
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		width: auto;
		max-width: 100%;
	}
	.col-lg-1 {
		-ms-flex: 0 0 8.333333%;
		flex: 0 0 8.333333%;
		max-width: 8.333333%;
	}
	.col-lg-2 {
		-ms-flex: 0 0 16.666667%;
		flex: 0 0 16.666667%;
		max-width: 16.666667%;
	}
	.col-lg-3 {
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%;
	}
	.col-lg-4 {
		-ms-flex: 0 0 33.333333%;
		flex: 0 0 33.333333%;
		max-width: 33.333333%;
	}
	.col-lg-5 {
		-ms-flex: 0 0 41.666667%;
		flex: 0 0 41.666667%;
		max-width: 41.666667%;
	}
	.col-lg-6 {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}
	.col-lg-7 {
		-ms-flex: 0 0 58.333333%;
		flex: 0 0 58.333333%;
		max-width: 58.333333%;
	}
	.col-lg-8 {
		-ms-flex: 0 0 66.666667%;
		flex: 0 0 66.666667%;
		max-width: 66.666667%;
	}
	.col-lg-9 {
		-ms-flex: 0 0 75%;
		flex: 0 0 75%;
		max-width: 75%;
	}
	.col-lg-10 {
		-ms-flex: 0 0 83.333333%;
		flex: 0 0 83.333333%;
		max-width: 83.333333%;
	}
	.col-lg-11 {
		-ms-flex: 0 0 91.666667%;
		flex: 0 0 91.666667%;
		max-width: 91.666667%;
	}
	.col-lg-12 {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
	.order-lg-first {
		-ms-flex-order: -1;
		order: -1;
	}
	.order-lg-last {
		-ms-flex-order: 13;
		order: 13;
	}
	.order-lg-0 {
		-ms-flex-order: 0;
		order: 0;
	}
	.order-lg-1 {
		-ms-flex-order: 1;
		order: 1;
	}
	.order-lg-2 {
		-ms-flex-order: 2;
		order: 2;
	}
	.order-lg-3 {
		-ms-flex-order: 3;
		order: 3;
	}
	.order-lg-4 {
		-ms-flex-order: 4;
		order: 4;
	}
	.order-lg-5 {
		-ms-flex-order: 5;
		order: 5;
	}
	.order-lg-6 {
		-ms-flex-order: 6;
		order: 6;
	}
	.order-lg-7 {
		-ms-flex-order: 7;
		order: 7;
	}
	.order-lg-8 {
		-ms-flex-order: 8;
		order: 8;
	}
	.order-lg-9 {
		-ms-flex-order: 9;
		order: 9;
	}
	.order-lg-10 {
		-ms-flex-order: 10;
		order: 10;
	}
	.order-lg-11 {
		-ms-flex-order: 11;
		order: 11;
	}
	.order-lg-12 {
		-ms-flex-order: 12;
		order: 12;
	}
	.offset-lg-0 {
		margin-left: 0;
	}
	.offset-lg-1 {
		margin-left: 8.333333%;
	}
	.offset-lg-2 {
		margin-left: 16.666667%;
	}
	.offset-lg-3 {
		margin-left: 25%;
	}
	.offset-lg-4 {
		margin-left: 33.333333%;
	}
	.offset-lg-5 {
		margin-left: 41.666667%;
	}
	.offset-lg-6 {
		margin-left: 50%;
	}
	.offset-lg-7 {
		margin-left: 58.333333%;
	}
	.offset-lg-8 {
		margin-left: 66.666667%;
	}
	.offset-lg-9 {
		margin-left: 75%;
	}
	.offset-lg-10 {
		margin-left: 83.333333%;
	}
	.offset-lg-11 {
		margin-left: 91.666667%;
	}
	.d-lg-none {
		display: none !important;
	}
	.d-lg-inline {
		display: inline !important;
	}
	.d-lg-inline-block {
		display: inline-block !important;
	}
	.d-lg-block {
		display: block !important;
	}
	.d-lg-table {
		display: table !important;
	}
	.d-lg-table-row {
		display: table-row !important;
	}
	.d-lg-table-cell {
		display: table-cell !important;
	}
	.d-lg-flex {
		display: -ms-flexbox !important;
		display: flex !important;
	}
	.d-lg-inline-flex {
		display: -ms-inline-flexbox !important;
		display: inline-flex !important;
	}
	.flex-lg-row {
		-ms-flex-direction: row !important;
		flex-direction: row !important;
	}
	.flex-lg-column {
		-ms-flex-direction: column !important;
		flex-direction: column !important;
	}
	.flex-lg-row-reverse {
		-ms-flex-direction: row-reverse !important;
		flex-direction: row-reverse !important;
	}
	.flex-lg-column-reverse {
		-ms-flex-direction: column-reverse !important;
		flex-direction: column-reverse !important;
	}
	.flex-lg-wrap {
		-ms-flex-wrap: wrap !important;
		flex-wrap: wrap !important;
	}
	.flex-lg-nowrap {
		-ms-flex-wrap: nowrap !important;
		flex-wrap: nowrap !important;
	}
	.flex-lg-wrap-reverse {
		-ms-flex-wrap: wrap-reverse !important;
		flex-wrap: wrap-reverse !important;
	}
	.flex-lg-fill {
		-ms-flex: 1 1 auto !important;
		flex: 1 1 auto !important;
	}
	.flex-lg-grow-0 {
		-ms-flex-positive: 0 !important;
		flex-grow: 0 !important;
	}
	.flex-lg-grow-1 {
		-ms-flex-positive: 1 !important;
		flex-grow: 1 !important;
	}
	.flex-lg-shrink-0 {
		-ms-flex-negative: 0 !important;
		flex-shrink: 0 !important;
	}
	.flex-lg-shrink-1 {
		-ms-flex-negative: 1 !important;
		flex-shrink: 1 !important;
	}
	.justify-content-lg-start {
		-ms-flex-pack: start !important;
		justify-content: flex-start !important;
	}
	.justify-content-lg-end {
		-ms-flex-pack: end !important;
		justify-content: flex-end !important;
	}
	.justify-content-lg-center {
		-ms-flex-pack: center !important;
		justify-content: center !important;
	}
	.justify-content-lg-between {
		-ms-flex-pack: justify !important;
		justify-content: space-between !important;
	}
	.justify-content-lg-around {
		-ms-flex-pack: distribute !important;
		justify-content: space-around !important;
	}
	.align-items-lg-start {
		-ms-flex-align: start !important;
		align-items: flex-start !important;
	}
	.align-items-lg-end {
		-ms-flex-align: end !important;
		align-items: flex-end !important;
	}
	.align-items-lg-center {
		-ms-flex-align: center !important;
		align-items: center !important;
	}
	.align-items-lg-baseline {
		-ms-flex-align: baseline !important;
		align-items: baseline !important;
	}
	.align-items-lg-stretch {
		-ms-flex-align: stretch !important;
		align-items: stretch !important;
	}
	.align-content-lg-start {
		-ms-flex-line-pack: start !important;
		align-content: flex-start !important;
	}
	.align-content-lg-end {
		-ms-flex-line-pack: end !important;
		align-content: flex-end !important;
	}
	.align-content-lg-center {
		-ms-flex-line-pack: center !important;
		align-content: center !important;
	}
	.align-content-lg-between {
		-ms-flex-line-pack: justify !important;
		align-content: space-between !important;
	}
	.align-content-lg-around {
		-ms-flex-line-pack: distribute !important;
		align-content: space-around !important;
	}
	.align-content-lg-stretch {
		-ms-flex-line-pack: stretch !important;
		align-content: stretch !important;
	}
	.align-self-lg-auto {
		-ms-flex-item-align: auto !important;
		align-self: auto !important;
	}
	.align-self-lg-start {
		-ms-flex-item-align: start !important;
		align-self: flex-start !important;
	}
	.align-self-lg-end {
		-ms-flex-item-align: end !important;
		align-self: flex-end !important;
	}
	.align-self-lg-center {
		-ms-flex-item-align: center !important;
		align-self: center !important;
	}
	.align-self-lg-baseline {
		-ms-flex-item-align: baseline !important;
		align-self: baseline !important;
	}
	.align-self-lg-stretch {
		-ms-flex-item-align: stretch !important;
		align-self: stretch !important;
	}
	.m-lg-0 {
		margin: 0 !important;
	}
	.mt-lg-0 {
		margin-top: 0 !important;
	}
	.my-lg-0 {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}
	.mr-lg-0 {
		margin-right: 0 !important;
	}
	.mx-lg-0 {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.mb-lg-0 {
		margin-bottom: 0 !important;
	}
	.ml-lg-0 {
		margin-left: 0 !important;
	}
	.m-lg-1 {
		margin: .25rem !important;
	}
	.mt-lg-1 {
		margin-top: .25rem !important;
	}
	.my-lg-1 {
		margin-top: .25rem !important;
		margin-bottom: .25rem !important;
	}
	.mr-lg-1 {
		margin-right: .25rem !important;
	}
	.mx-lg-1 {
		margin-right: .25rem !important;
		margin-left: .25rem !important;
	}
	.mb-lg-1 {
		margin-bottom: .25rem !important;
	}
	.ml-lg-1 {
		margin-left: .25rem !important;
	}
	.m-lg-2 {
		margin: .5rem !important;
	}
	.mt-lg-2 {
		margin-top: .5rem !important;
	}
	.my-lg-2 {
		margin-top: .5rem !important;
		margin-bottom: .5rem !important;
	}
	.mr-lg-2 {
		margin-right: .5rem !important;
	}
	.mx-lg-2 {
		margin-right: .5rem !important;
		margin-left: .5rem !important;
	}
	.mb-lg-2 {
		margin-bottom: .5rem !important;
	}
	.ml-lg-2 {
		margin-left: .5rem !important;
	}
	.m-lg-3 {
		margin: 1rem !important;
	}
	.mt-lg-3 {
		margin-top: 1rem !important;
	}
	.my-lg-3 {
		margin-top: 1rem !important;
		margin-bottom: 1rem !important;
	}
	.mr-lg-3 {
		margin-right: 1rem !important;
	}
	.mx-lg-3 {
		margin-right: 1rem !important;
		margin-left: 1rem !important;
	}
	.mb-lg-3 {
		margin-bottom: 1rem !important;
	}
	.ml-lg-3 {
		margin-left: 1rem !important;
	}
	.m-lg-4 {
		margin: 1.5rem !important;
	}
	.mt-lg-4 {
		margin-top: 1.5rem !important;
	}
	.my-lg-4 {
		margin-top: 1.5rem !important;
		margin-bottom: 1.5rem !important;
	}
	.mr-lg-4 {
		margin-right: 1.5rem !important;
	}
	.mx-lg-4 {
		margin-right: 1.5rem !important;
		margin-left: 1.5rem !important;
	}
	.mb-lg-4 {
		margin-bottom: 1.5rem !important;
	}
	.ml-lg-4 {
		margin-left: 1.5rem !important;
	}
	.m-lg-5 {
		margin: 3rem !important;
	}
	.mt-lg-5 {
		margin-top: 3rem !important;
	}
	.my-lg-5 {
		margin-top: 3rem !important;
		margin-bottom: 3rem !important;
	}
	.mr-lg-5 {
		margin-right: 3rem !important;
	}
	.mx-lg-5 {
		margin-right: 3rem !important;
		margin-left: 3rem !important;
	}
	.mb-lg-5 {
		margin-bottom: 3rem !important;
	}
	.ml-lg-5 {
		margin-left: 3rem !important;
	}
	.p-lg-0 {
		padding: 0 !important;
	}
	.pt-lg-0 {
		padding-top: 0 !important;
	}
	.py-lg-0 {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}
	.pr-lg-0 {
		padding-right: 0 !important;
	}
	.px-lg-0 {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
	.pb-lg-0 {
		padding-bottom: 0 !important;
	}
	.pl-lg-0 {
		padding-left: 0 !important;
	}
	.p-lg-1 {
		padding: .25rem !important;
	}
	.pt-lg-1 {
		padding-top: .25rem !important;
	}
	.py-lg-1 {
		padding-top: .25rem !important;
		padding-bottom: .25rem !important;
	}
	.pr-lg-1 {
		padding-right: .25rem !important;
	}
	.px-lg-1 {
		padding-right: .25rem !important;
		padding-left: .25rem !important;
	}
	.pb-lg-1 {
		padding-bottom: .25rem !important;
	}
	.pl-lg-1 {
		padding-left: .25rem !important;
	}
	.p-lg-2 {
		padding: .5rem !important;
	}
	.pt-lg-2 {
		padding-top: .5rem !important;
	}
	.py-lg-2 {
		padding-top: .5rem !important;
		padding-bottom: .5rem !important;
	}
	.pr-lg-2 {
		padding-right: .5rem !important;
	}
	.px-lg-2 {
		padding-right: .5rem !important;
		padding-left: .5rem !important;
	}
	.pb-lg-2 {
		padding-bottom: .5rem !important;
	}
	.pl-lg-2 {
		padding-left: .5rem !important;
	}
	.p-lg-3 {
		padding: 1rem !important;
	}
	.pt-lg-3 {
		padding-top: 1rem !important;
	}
	.py-lg-3 {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important;
	}
	.pr-lg-3 {
		padding-right: 1rem !important;
	}
	.px-lg-3 {
		padding-right: 1rem !important;
		padding-left: 1rem !important;
	}
	.pb-lg-3 {
		padding-bottom: 1rem !important;
	}
	.pl-lg-3 {
		padding-left: 1rem !important;
	}
	.p-lg-4 {
		padding: 1.5rem !important;
	}
	.pt-lg-4 {
		padding-top: 1.5rem !important;
	}
	.py-lg-4 {
		padding-top: 1.5rem !important;
		padding-bottom: 1.5rem !important;
	}
	.pr-lg-4 {
		padding-right: 1.5rem !important;
	}
	.px-lg-4 {
		padding-right: 1.5rem !important;
		padding-left: 1.5rem !important;
	}
	.pb-lg-4 {
		padding-bottom: 1.5rem !important;
	}
	.pl-lg-4 {
		padding-left: 1.5rem !important;
	}
	.p-lg-5 {
		padding: 3rem !important;
	}
	.pt-lg-5 {
		padding-top: 3rem !important;
	}
	.py-lg-5 {
		padding-top: 3rem !important;
		padding-bottom: 3rem !important;
	}
	.pr-lg-5 {
		padding-right: 3rem !important;
	}
	.px-lg-5 {
		padding-right: 3rem !important;
		padding-left: 3rem !important;
	}
	.pb-lg-5 {
		padding-bottom: 3rem !important;
	}
	.pl-lg-5 {
		padding-left: 3rem !important;
	}
	.m-lg-n1 {
		margin: -.25rem !important;
	}
	.mt-lg-n1 {
		margin-top: -.25rem !important;
	}
	.my-lg-n1 {
		margin-top: -.25rem !important;
		margin-bottom: -.25rem !important;
	}
	.mr-lg-n1 {
		margin-right: -.25rem !important;
	}
	.mx-lg-n1 {
		margin-right: -.25rem !important;
		margin-left: -.25rem !important;
	}
	.mb-lg-n1 {
		margin-bottom: -.25rem !important;
	}
	.ml-lg-n1 {
		margin-left: -.25rem !important;
	}
	.m-lg-n2 {
		margin: -.5rem !important;
	}
	.mt-lg-n2 {
		margin-top: -.5rem !important;
	}
	.my-lg-n2 {
		margin-top: -.5rem !important;
		margin-bottom: -.5rem !important;
	}
	.mr-lg-n2 {
		margin-right: -.5rem !important;
	}
	.mx-lg-n2 {
		margin-right: -.5rem !important;
		margin-left: -.5rem !important;
	}
	.mb-lg-n2 {
		margin-bottom: -.5rem !important;
	}
	.ml-lg-n2 {
		margin-left: -.5rem !important;
	}
	.m-lg-n3 {
		margin: -1rem !important;
	}
	.mt-lg-n3 {
		margin-top: -1rem !important;
	}
	.my-lg-n3 {
		margin-top: -1rem !important;
		margin-bottom: -1rem !important;
	}
	.mr-lg-n3 {
		margin-right: -1rem !important;
	}
	.mx-lg-n3 {
		margin-right: -1rem !important;
		margin-left: -1rem !important;
	}
	.mb-lg-n3 {
		margin-bottom: -1rem !important;
	}
	.ml-lg-n3 {
		margin-left: -1rem !important;
	}
	.m-lg-n4 {
		margin: -1.5rem !important;
	}
	.mt-lg-n4 {
		margin-top: -1.5rem !important;
	}
	.my-lg-n4 {
		margin-top: -1.5rem !important;
		margin-bottom: -1.5rem !important;
	}
	.mr-lg-n4 {
		margin-right: -1.5rem !important;
	}
	.mx-lg-n4 {
		margin-right: -1.5rem !important;
		margin-left: -1.5rem !important;
	}
	.mb-lg-n4 {
		margin-bottom: -1.5rem !important;
	}
	.ml-lg-n4 {
		margin-left: -1.5rem !important;
	}
	.m-lg-n5 {
		margin: -3rem !important;
	}
	.mt-lg-n5 {
		margin-top: -3rem !important;
	}
	.my-lg-n5 {
		margin-top: -3rem !important;
		margin-bottom: -3rem !important;
	}
	.mr-lg-n5 {
		margin-right: -3rem !important;
	}
	.mx-lg-n5 {
		margin-right: -3rem !important;
		margin-left: -3rem !important;
	}
	.mb-lg-n5 {
		margin-bottom: -3rem !important;
	}
	.ml-lg-n5 {
		margin-left: -3rem !important;
	}
	.m-lg-auto {
		margin: auto !important;
	}
	.mt-lg-auto {
		margin-top: auto !important;
	}
	.my-lg-auto {
		margin-top: auto !important;
		margin-bottom: auto !important;
	}
	.mr-lg-auto {
		margin-right: auto !important;
	}
	.mx-lg-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
	.mb-lg-auto {
		margin-bottom: auto !important;
	}
	.ml-lg-auto {
		margin-left: auto !important;
	}
}
@media (min-width:$screen-lg-max) {
	.container {
		max-width: 1140px;
	}
	.col-xl {
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		-ms-flex-positive: 1;
		flex-grow: 1;
		max-width: 100%;
	}
	.col-xl-auto {
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		width: auto;
		max-width: 100%;
	}
	.col-xl-1 {
		-ms-flex: 0 0 8.333333%;
		flex: 0 0 8.333333%;
		max-width: 8.333333%;
	}
	.col-xl-2 {
		-ms-flex: 0 0 16.666667%;
		flex: 0 0 16.666667%;
		max-width: 16.666667%;
	}
	.col-xl-3 {
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%;
	}
	.col-xl-4 {
		-ms-flex: 0 0 33.333333%;
		flex: 0 0 33.333333%;
		max-width: 33.333333%;
	}
	.col-xl-5 {
		-ms-flex: 0 0 41.666667%;
		flex: 0 0 41.666667%;
		max-width: 41.666667%;
	}
	.col-xl-6 {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}
	.col-xl-7 {
		-ms-flex: 0 0 58.333333%;
		flex: 0 0 58.333333%;
		max-width: 58.333333%;
	}
	.col-xl-8 {
		-ms-flex: 0 0 66.666667%;
		flex: 0 0 66.666667%;
		max-width: 66.666667%;
	}
	.col-xl-9 {
		-ms-flex: 0 0 75%;
		flex: 0 0 75%;
		max-width: 75%;
	}
	.col-xl-10 {
		-ms-flex: 0 0 83.333333%;
		flex: 0 0 83.333333%;
		max-width: 83.333333%;
	}
	.col-xl-11 {
		-ms-flex: 0 0 91.666667%;
		flex: 0 0 91.666667%;
		max-width: 91.666667%;
	}
	.col-xl-12 {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
	.order-xl-first {
		-ms-flex-order: -1;
		order: -1;
	}
	.order-xl-last {
		-ms-flex-order: 13;
		order: 13;
	}
	.order-xl-0 {
		-ms-flex-order: 0;
		order: 0;
	}
	.order-xl-1 {
		-ms-flex-order: 1;
		order: 1;
	}
	.order-xl-2 {
		-ms-flex-order: 2;
		order: 2;
	}
	.order-xl-3 {
		-ms-flex-order: 3;
		order: 3;
	}
	.order-xl-4 {
		-ms-flex-order: 4;
		order: 4;
	}
	.order-xl-5 {
		-ms-flex-order: 5;
		order: 5;
	}
	.order-xl-6 {
		-ms-flex-order: 6;
		order: 6;
	}
	.order-xl-7 {
		-ms-flex-order: 7;
		order: 7;
	}
	.order-xl-8 {
		-ms-flex-order: 8;
		order: 8;
	}
	.order-xl-9 {
		-ms-flex-order: 9;
		order: 9;
	}
	.order-xl-10 {
		-ms-flex-order: 10;
		order: 10;
	}
	.order-xl-11 {
		-ms-flex-order: 11;
		order: 11;
	}
	.order-xl-12 {
		-ms-flex-order: 12;
		order: 12;
	}
	.offset-xl-0 {
		margin-left: 0;
	}
	.offset-xl-1 {
		margin-left: 8.333333%;
	}
	.offset-xl-2 {
		margin-left: 16.666667%;
	}
	.offset-xl-3 {
		margin-left: 25%;
	}
	.offset-xl-4 {
		margin-left: 33.333333%;
	}
	.offset-xl-5 {
		margin-left: 41.666667%;
	}
	.offset-xl-6 {
		margin-left: 50%;
	}
	.offset-xl-7 {
		margin-left: 58.333333%;
	}
	.offset-xl-8 {
		margin-left: 66.666667%;
	}
	.offset-xl-9 {
		margin-left: 75%;
	}
	.offset-xl-10 {
		margin-left: 83.333333%;
	}
	.offset-xl-11 {
		margin-left: 91.666667%;
	}
	.d-xl-none {
		display: none !important;
	}
	.d-xl-inline {
		display: inline !important;
	}
	.d-xl-inline-block {
		display: inline-block !important;
	}
	.d-xl-block {
		display: block !important;
	}
	.d-xl-table {
		display: table !important;
	}
	.d-xl-table-row {
		display: table-row !important;
	}
	.d-xl-table-cell {
		display: table-cell !important;
	}
	.d-xl-flex {
		display: -ms-flexbox !important;
		display: flex !important;
	}
	.d-xl-inline-flex {
		display: -ms-inline-flexbox !important;
		display: inline-flex !important;
	}
	.flex-xl-row {
		-ms-flex-direction: row !important;
		flex-direction: row !important;
	}
	.flex-xl-column {
		-ms-flex-direction: column !important;
		flex-direction: column !important;
	}
	.flex-xl-row-reverse {
		-ms-flex-direction: row-reverse !important;
		flex-direction: row-reverse !important;
	}
	.flex-xl-column-reverse {
		-ms-flex-direction: column-reverse !important;
		flex-direction: column-reverse !important;
	}
	.flex-xl-wrap {
		-ms-flex-wrap: wrap !important;
		flex-wrap: wrap !important;
	}
	.flex-xl-nowrap {
		-ms-flex-wrap: nowrap !important;
		flex-wrap: nowrap !important;
	}
	.flex-xl-wrap-reverse {
		-ms-flex-wrap: wrap-reverse !important;
		flex-wrap: wrap-reverse !important;
	}
	.flex-xl-fill {
		-ms-flex: 1 1 auto !important;
		flex: 1 1 auto !important;
	}
	.flex-xl-grow-0 {
		-ms-flex-positive: 0 !important;
		flex-grow: 0 !important;
	}
	.flex-xl-grow-1 {
		-ms-flex-positive: 1 !important;
		flex-grow: 1 !important;
	}
	.flex-xl-shrink-0 {
		-ms-flex-negative: 0 !important;
		flex-shrink: 0 !important;
	}
	.flex-xl-shrink-1 {
		-ms-flex-negative: 1 !important;
		flex-shrink: 1 !important;
	}
	.justify-content-xl-start {
		-ms-flex-pack: start !important;
		justify-content: flex-start !important;
	}
	.justify-content-xl-end {
		-ms-flex-pack: end !important;
		justify-content: flex-end !important;
	}
	.justify-content-xl-center {
		-ms-flex-pack: center !important;
		justify-content: center !important;
	}
	.justify-content-xl-between {
		-ms-flex-pack: justify !important;
		justify-content: space-between !important;
	}
	.justify-content-xl-around {
		-ms-flex-pack: distribute !important;
		justify-content: space-around !important;
	}
	.align-items-xl-start {
		-ms-flex-align: start !important;
		align-items: flex-start !important;
	}
	.align-items-xl-end {
		-ms-flex-align: end !important;
		align-items: flex-end !important;
	}
	.align-items-xl-center {
		-ms-flex-align: center !important;
		align-items: center !important;
	}
	.align-items-xl-baseline {
		-ms-flex-align: baseline !important;
		align-items: baseline !important;
	}
	.align-items-xl-stretch {
		-ms-flex-align: stretch !important;
		align-items: stretch !important;
	}
	.align-content-xl-start {
		-ms-flex-line-pack: start !important;
		align-content: flex-start !important;
	}
	.align-content-xl-end {
		-ms-flex-line-pack: end !important;
		align-content: flex-end !important;
	}
	.align-content-xl-center {
		-ms-flex-line-pack: center !important;
		align-content: center !important;
	}
	.align-content-xl-between {
		-ms-flex-line-pack: justify !important;
		align-content: space-between !important;
	}
	.align-content-xl-around {
		-ms-flex-line-pack: distribute !important;
		align-content: space-around !important;
	}
	.align-content-xl-stretch {
		-ms-flex-line-pack: stretch !important;
		align-content: stretch !important;
	}
	.align-self-xl-auto {
		-ms-flex-item-align: auto !important;
		align-self: auto !important;
	}
	.align-self-xl-start {
		-ms-flex-item-align: start !important;
		align-self: flex-start !important;
	}
	.align-self-xl-end {
		-ms-flex-item-align: end !important;
		align-self: flex-end !important;
	}
	.align-self-xl-center {
		-ms-flex-item-align: center !important;
		align-self: center !important;
	}
	.align-self-xl-baseline {
		-ms-flex-item-align: baseline !important;
		align-self: baseline !important;
	}
	.align-self-xl-stretch {
		-ms-flex-item-align: stretch !important;
		align-self: stretch !important;
	}
	.m-xl-0 {
		margin: 0 !important;
	}
	.mt-xl-0 {
		margin-top: 0 !important;
	}
	.my-xl-0 {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}
	.mr-xl-0 {
		margin-right: 0 !important;
	}
	.mx-xl-0 {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.mb-xl-0 {
		margin-bottom: 0 !important;
	}
	.ml-xl-0 {
		margin-left: 0 !important;
	}
	.m-xl-1 {
		margin: .25rem !important;
	}
	.mt-xl-1 {
		margin-top: .25rem !important;
	}
	.my-xl-1 {
		margin-top: .25rem !important;
		margin-bottom: .25rem !important;
	}
	.mr-xl-1 {
		margin-right: .25rem !important;
	}
	.mx-xl-1 {
		margin-right: .25rem !important;
		margin-left: .25rem !important;
	}
	.mb-xl-1 {
		margin-bottom: .25rem !important;
	}
	.ml-xl-1 {
		margin-left: .25rem !important;
	}
	.m-xl-2 {
		margin: .5rem !important;
	}
	.mt-xl-2 {
		margin-top: .5rem !important;
	}
	.my-xl-2 {
		margin-top: .5rem !important;
		margin-bottom: .5rem !important;
	}
	.mr-xl-2 {
		margin-right: .5rem !important;
	}
	.mx-xl-2 {
		margin-right: .5rem !important;
		margin-left: .5rem !important;
	}
	.mb-xl-2 {
		margin-bottom: .5rem !important;
	}
	.ml-xl-2 {
		margin-left: .5rem !important;
	}
	.m-xl-3 {
		margin: 1rem !important;
	}
	.mt-xl-3 {
		margin-top: 1rem !important;
	}
	.my-xl-3 {
		margin-top: 1rem !important;
		margin-bottom: 1rem !important;
	}
	.mr-xl-3 {
		margin-right: 1rem !important;
	}
	.mx-xl-3 {
		margin-right: 1rem !important;
		margin-left: 1rem !important;
	}
	.mb-xl-3 {
		margin-bottom: 1rem !important;
	}
	.ml-xl-3 {
		margin-left: 1rem !important;
	}
	.m-xl-4 {
		margin: 1.5rem !important;
	}
	.mt-xl-4 {
		margin-top: 1.5rem !important;
	}
	.my-xl-4 {
		margin-top: 1.5rem !important;
		margin-bottom: 1.5rem !important;
	}
	.mr-xl-4 {
		margin-right: 1.5rem !important;
	}
	.mx-xl-4 {
		margin-right: 1.5rem !important;
		margin-left: 1.5rem !important;
	}
	.mb-xl-4 {
		margin-bottom: 1.5rem !important;
	}
	.ml-xl-4 {
		margin-left: 1.5rem !important;
	}
	.m-xl-5 {
		margin: 3rem !important;
	}
	.mt-xl-5 {
		margin-top: 3rem !important;
	}
	.my-xl-5 {
		margin-top: 3rem !important;
		margin-bottom: 3rem !important;
	}
	.mr-xl-5 {
		margin-right: 3rem !important;
	}
	.mx-xl-5 {
		margin-right: 3rem !important;
		margin-left: 3rem !important;
	}
	.mb-xl-5 {
		margin-bottom: 3rem !important;
	}
	.ml-xl-5 {
		margin-left: 3rem !important;
	}
	.p-xl-0 {
		padding: 0 !important;
	}
	.pt-xl-0 {
		padding-top: 0 !important;
	}
	.py-xl-0 {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}
	.pr-xl-0 {
		padding-right: 0 !important;
	}
	.px-xl-0 {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
	.pb-xl-0 {
		padding-bottom: 0 !important;
	}
	.pl-xl-0 {
		padding-left: 0 !important;
	}
	.p-xl-1 {
		padding: .25rem !important;
	}
	.pt-xl-1 {
		padding-top: .25rem !important;
	}
	.py-xl-1 {
		padding-top: .25rem !important;
		padding-bottom: .25rem !important;
	}
	.pr-xl-1 {
		padding-right: .25rem !important;
	}
	.px-xl-1 {
		padding-right: .25rem !important;
		padding-left: .25rem !important;
	}
	.pb-xl-1 {
		padding-bottom: .25rem !important;
	}
	.pl-xl-1 {
		padding-left: .25rem !important;
	}
	.p-xl-2 {
		padding: .5rem !important;
	}
	.pt-xl-2 {
		padding-top: .5rem !important;
	}
	.py-xl-2 {
		padding-top: .5rem !important;
		padding-bottom: .5rem !important;
	}
	.pr-xl-2 {
		padding-right: .5rem !important;
	}
	.px-xl-2 {
		padding-right: .5rem !important;
		padding-left: .5rem !important;
	}
	.pb-xl-2 {
		padding-bottom: .5rem !important;
	}
	.pl-xl-2 {
		padding-left: .5rem !important;
	}
	.p-xl-3 {
		padding: 1rem !important;
	}
	.pt-xl-3 {
		padding-top: 1rem !important;
	}
	.py-xl-3 {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important;
	}
	.pr-xl-3 {
		padding-right: 1rem !important;
	}
	.px-xl-3 {
		padding-right: 1rem !important;
		padding-left: 1rem !important;
	}
	.pb-xl-3 {
		padding-bottom: 1rem !important;
	}
	.pl-xl-3 {
		padding-left: 1rem !important;
	}
	.p-xl-4 {
		padding: 1.5rem !important;
	}
	.pt-xl-4 {
		padding-top: 1.5rem !important;
	}
	.py-xl-4 {
		padding-top: 1.5rem !important;
		padding-bottom: 1.5rem !important;
	}
	.pr-xl-4 {
		padding-right: 1.5rem !important;
	}
	.px-xl-4 {
		padding-right: 1.5rem !important;
		padding-left: 1.5rem !important;
	}
	.pb-xl-4 {
		padding-bottom: 1.5rem !important;
	}
	.pl-xl-4 {
		padding-left: 1.5rem !important;
	}
	.p-xl-5 {
		padding: 3rem !important;
	}
	.pt-xl-5 {
		padding-top: 3rem !important;
	}
	.py-xl-5 {
		padding-top: 3rem !important;
		padding-bottom: 3rem !important;
	}
	.pr-xl-5 {
		padding-right: 3rem !important;
	}
	.px-xl-5 {
		padding-right: 3rem !important;
		padding-left: 3rem !important;
	}
	.pb-xl-5 {
		padding-bottom: 3rem !important;
	}
	.pl-xl-5 {
		padding-left: 3rem !important;
	}
	.m-xl-n1 {
		margin: -.25rem !important;
	}
	.mt-xl-n1 {
		margin-top: -.25rem !important;
	}
	.my-xl-n1 {
		margin-top: -.25rem !important;
		margin-bottom: -.25rem !important;
	}
	.mr-xl-n1 {
		margin-right: -.25rem !important;
	}
	.mx-xl-n1 {
		margin-right: -.25rem !important;
		margin-left: -.25rem !important;
	}
	.mb-xl-n1 {
		margin-bottom: -.25rem !important;
	}
	.ml-xl-n1 {
		margin-left: -.25rem !important;
	}
	.m-xl-n2 {
		margin: -.5rem !important;
	}
	.mt-xl-n2 {
		margin-top: -.5rem !important;
	}
	.my-xl-n2 {
		margin-top: -.5rem !important;
		margin-bottom: -.5rem !important;
	}
	.mr-xl-n2 {
		margin-right: -.5rem !important;
	}
	.mx-xl-n2 {
		margin-right: -.5rem !important;
		margin-left: -.5rem !important;
	}
	.mb-xl-n2 {
		margin-bottom: -.5rem !important;
	}
	.ml-xl-n2 {
		margin-left: -.5rem !important;
	}
	.m-xl-n3 {
		margin: -1rem !important;
	}
	.mt-xl-n3 {
		margin-top: -1rem !important;
	}
	.my-xl-n3 {
		margin-top: -1rem !important;
		margin-bottom: -1rem !important;
	}
	.mr-xl-n3 {
		margin-right: -1rem !important;
	}
	.mx-xl-n3 {
		margin-right: -1rem !important;
		margin-left: -1rem !important;
	}
	.mb-xl-n3 {
		margin-bottom: -1rem !important;
	}
	.ml-xl-n3 {
		margin-left: -1rem !important;
	}
	.m-xl-n4 {
		margin: -1.5rem !important;
	}
	.mt-xl-n4 {
		margin-top: -1.5rem !important;
	}
	.my-xl-n4 {
		margin-top: -1.5rem !important;
		margin-bottom: -1.5rem !important;
	}
	.mr-xl-n4 {
		margin-right: -1.5rem !important;
	}
	.mx-xl-n4 {
		margin-right: -1.5rem !important;
		margin-left: -1.5rem !important;
	}
	.mb-xl-n4 {
		margin-bottom: -1.5rem !important;
	}
	.ml-xl-n4 {
		margin-left: -1.5rem !important;
	}
	.m-xl-n5 {
		margin: -3rem !important;
	}
	.mt-xl-n5 {
		margin-top: -3rem !important;
	}
	.my-xl-n5 {
		margin-top: -3rem !important;
		margin-bottom: -3rem !important;
	}
	.mr-xl-n5 {
		margin-right: -3rem !important;
	}
	.mx-xl-n5 {
		margin-right: -3rem !important;
		margin-left: -3rem !important;
	}
	.mb-xl-n5 {
		margin-bottom: -3rem !important;
	}
	.ml-xl-n5 {
		margin-left: -3rem !important;
	}
	.m-xl-auto {
		margin: auto !important;
	}
	.mt-xl-auto {
		margin-top: auto !important;
	}
	.my-xl-auto {
		margin-top: auto !important;
		margin-bottom: auto !important;
	}
	.mr-xl-auto {
		margin-right: auto !important;
	}
	.mx-xl-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
	.mb-xl-auto {
		margin-bottom: auto !important;
	}
	.ml-xl-auto {
		margin-left: auto !important;
	}
}

@media print {
	.d-print-none {
		display: none !important;
	}
	.d-print-inline {
		display: inline !important;
	}
	.d-print-inline-block {
		display: inline-block !important;
	}
	.d-print-block {
		display: block !important;
	}
	.d-print-table {
		display: table !important;
	}
	.d-print-table-row {
		display: table-row !important;
	}
	.d-print-table-cell {
		display: table-cell !important;
	}
	.d-print-flex {
		display: -ms-flexbox !important;
		display: flex !important;
	}
	.d-print-inline-flex {
		display: -ms-inline-flexbox !important;
		display: inline-flex !important;
	}
}