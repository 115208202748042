@import "global";

// Elements
// Important - this file is shared with other web properties.

* {
	position: relative;
}

// IE sucks
html.browser-is-flawed {

	* {
		// z-index: 1;
	}

	.flawed-z-correct {
		z-index: 1;
	}

	footer {
		z-index: 2;
	}

	.flawed-hidden {
		display: none;
	}
}

no-br {
	white-space: nowrap;
}

// General
html,
body {
	position: relative;
	height: 100%;
	width: 100%;
}

a.anchor {
	display: block;
	position: relative;
	top: -($c4unit * 2);
	visibility: hidden;
}

.container {
	margin: 0 auto;
	padding: 0 $c4unit;
}

// Content

.full-screen,
html:not(.mobile-browser) .full-screen-desktop {
	min-height: 100vh;
	width: 100vw;
	overflow: hidden;
}

.video-full-screen:not(.unfix) {
	position: fixed;
	top: 50%;
	left: 50%;
	min-width: 100%;
	min-height: 100%;
	width: auto;
	height: auto;
	z-index: -100;
	-webkit-transform: translateX(-50%) translateY(-50%);
	transform: translateX(-50%) translateY(-50%);
}

.video-full-screen.unfix {
	position: absolute;
	top: 0;
	right: 50%;
	bottom: 0;
	left: 0;
	min-height: 100%;
	min-width: 100%;
	width: auto;
	height: auto;
}

.checkered.right .video-full-screen.unfix {
	left: auto;
	right: 0;
}

.block {
	display: block;
	width: 100%;
}

.inline {
	display: inline;
}

.inline-block {
	display: inline-block;
}

body .hidden {
	display: none;
}

html.mobile-browser .mobile-hide {
	display: none;
	opacity: 0;
}

html:not(.mobile-browser) .desktop-hide {
	display: none;
	opacity: 0;
}

.relative {
	position: relative;
}

.fixed,
html:not(.mobile-browser) .fixed-desktop {
	position: fixed;
	top: 0;
	left: 0;
}

.absolute, 
html:not(.mobile-browser) .absolute-desktop {
	position: absolute;
	
	&.fill {
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
	
	&.top {	
		top: 0;
	}
	
	&.right {	
		right: 0;
	}
	
	&.bottom {	
		bottom: 0;
	}
	
	&.left {	
		left: 0;
	}
}

.explicit-line-height {
	line-height: $font-size-base;
}

.shadow {
	box-shadow: $shadow;
}

.shadow-inset {
	box-shadow: inset $shadow;
}

.clickable {
	cursor: pointer;
}

.table-cell {
	display: table-cell;
}

.background {
	color: $white;
	background-position: center center;
	background-size: cover;
}

.background-fit {
	color: $white;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
}

.background-bottom {
	background-position: center bottom;
}

a.background-hover {
	display: block;
	overflow: hidden;
	
	.background {
		transition: 3s;
	}
	
	&:hover .background {
		transform: scale(1.05);
		transition: 1.5s;
	}
}

.vertically-center {
	width: 100%;
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

.tile-label {
	display: block;
	height: $c4unit * 2;
	@include small-caps();
	color: $text-color;
	line-height: 1.618em;
	text-align: center;
	background-color: $white;
	box-shadow: $shadow;
	transition: $duration-fast;
}

a:hover .tile-label,
a.active .tile-label {
	color: $white;
	background-color: $control4-red;
}

#backgrounds {
	z-index: 0;
}

#page-backgrounds {

	> div {
		z-index: -2;
	}
}

.move-to-backgrounds {
	z-index: 0;
}

.black,
.black-half-opacity,
.darker-grey,
.dark-grey,
.medium-grey,
.dark-green,
.dark-blue,
.dark-red,
.dark-orange,
.grey {
	color: $white;

	h3 {
		color: $white;
	}
	
	p,
	ul,
	table {
		color: $white-half-opacity;
		
		a{
			color: $white;
			
			&:hover {
				color: $control4-red;
			}
		}
	}	
}

.white,
.white-half-opacity,
.light-grey,
.light-green {
	color: $text-color;
}

body .white {
	background-color: $white;
}

body .white-half-opacity {
	background-color: $white-half-opacity;
}

body .white-opaque {
	background-color: $white-opaque;
}

body .black-half-opacity {
	background-color: $black-half-opacity;
}

body .light-grey {
	background-color: $grey-10;
	color: $text-color;
}

body .grey,
body .medium-grey {
	background-color: $grey-60;
}

body .black {
	background-color: $black;
}

body .dark-grey {
	background-color: $grey-120;
}

body .darker-grey {
	background-color: $grey-130;
}

// alerts

body .alert,
body .growl-container > .growl-item.alert {
    padding: $c4unit $c4unit $c4unit 3*$c4unit;
    margin-bottom: $c4unit;
    min-height: 75px;
    background-color: $white;
    border: 1px solid $grey-10;

    &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100%;
        width: 50px;
    }

    &::after {
        content: "";
        display: block;
        position: absolute;
        margin-top: -25px;
        top: 50%;
		left: 0px;
		height: 50px;
        width: 50px;
        color: $white;
		font-family: $icons;
		font-weight: $font-weight-icon-fal;
        font-size: 25px;
        line-height: 2*$c4unit;
        text-align: center;
    }

    &.alert-info {

        &::before {
            background-color: $blue;
        }

        &::after {
            content: "\f06a";
        }

        a:not(.c4btn):not(:hover) {
            color: $blue;
        }
    }
    
    &.alert-success {

        &::before {
            background-color: $success-icon;
        }

        &::after {
            content: "\f058";
        }

        a:not(.c4btn):not(:hover) {
            color: $success-icon;
        }
    }

    &.alert-warning {

        &::before {
            background-color: $warning-icon;
        }

        &::after {
            content: "\f06a";
        }

        a:not(.c4btn):not(:hover) {
            color: $warning-icon;
        }
    }

    &.alert-danger {

        &::before {
            background-color: $danger-icon;
        }

        &::after {
            content: "\f06a";
        }

        a:not(.c4btn):not(:hover) {
            color: $danger-icon;
        }
    }
}

body .no-padding,
body .reset-padding {
	padding-top: 0;
	padding-right: 0;
	padding-bottom: 0;
	padding-left: 0;
}

body .padding,
body .half-padding {
	padding: $c4unit;
}

body .quarter-padding-top {
	padding-top: $c4unit / 2;
}

body .quarter-padding-bottom {
	padding-bottom: $c4unit / 2;
}

body .padding-top {
	padding-top: $c4unit * 3;
}

body .padding-bottom {
	padding-bottom: $c4unit * 3;
}

body .half-padding-top {
	padding-top: $c4unit !important;
}

body .half-padding-bottom {
	padding-bottom: $c4unit !important;
}

body .padding-left {
	padding-left: $c4unit * 3;
}

body .padding-right {
	padding-right: $c4unit * 3;
}

body .half-padding-left {
	padding-left: $c4unit !important;
}

body .half-padding-right {
	padding-right: $c4unit !important;
}

body .reset-margin,
body .no-margin {
	margin-top: 0;
	margin-right: 0;
	margin-bottom: 0;
	margin-left: 0;
}

body .margin {
	margin: $c4unit * 3;
}

body .half-margin {
	margin: $c4unit;
}

body .margin-top {
	margin-top: $c4unit * 3;
}

body .margin-bottom {
	margin-bottom: $c4unit * 3;
}

body .half-margin-top {
	margin-top: $c4unit;
}

body .half-margin-bottom,
body .margin-bottom-sm {
	margin-bottom: $c4unit;
}

body .margin-bottom-xs {
	margin-bottom: $c4unit/2;
}

img {
	max-width: 100%;
}

div.page-header {
	position: relative;
    width: 100%;
    height: 300px;
    background-attachment: fixed;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: auto 300px;
    
	@media (min-width: $screen-lg-min) {
    	background-size: 100% auto;
	}
}

.border-bottom:not(:last-child) {
	border-bottom: 1px solid #eee;
}

// not mobile, not archaic browser
html.mobile-browser,
html.browser-is-flawed {
	
	div.page-header {
		background-attachment: scroll;
	}
}

div.pagination {
	line-height: calc(2 * #{$c4unit} - 2px);
	text-align: right;

	ul {
		height: $c4unit * 2;
		width: auto;
		padding: 0 20px;
		margin: 0 0 #{$c4unit / 2} 0;
		display: inline-block;
		list-style: none;
		margin: auto;

		li {
			list-style: none;
			display: inline-block;
			padding: 0 3px 0 3px;

			a:not(:hover) {
				color: $text-grey; 
			}
			&.active {
				a {
					font-weight: $font-weight-bold;
				}
				a:not(:hover) {
					color: $text-color;
				}
			}

		}
	}
}

.aspect:before {
	content: "";
	display: block;
	overflow: hidden;
}

.aspect.one-to-one:before {
	padding-top: 100%; /* This height will actually relate to the width of the box 1:1 aspect ratio */
}

.aspect.four-to-three:before {
	padding-top: 75%; /* This height will actually relate to the width of the box 2:1 aspect ratio */
}

.aspect.two-to-one:before {
	padding-top: 50%; /* This height will actually relate to the width of the box 2:1 aspect ratio */
}

.aspect.four-to-one:before {
	padding-top: 25%; /* This height will actually relate to the width of the box 2:1 aspect ratio */
}

.aspect.magazine:before {
	padding-top: 127%; /* This height will actually relate to the width of the box 2:1 aspect ratio */
}

.aspect.video:before {
	padding-top: 56.25%;
}

.clearfix:after, .content-boundary:after, .columns:after {
	visibility: hidden;
	display: block;
	font-size: 0;
	content: " ";
	clear: both;
	height: 0;
}
	
.checkered {
	position: relative;

	&.left {
		
		.background {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 50%;
		}
	}

	&.right {

		.background {
			position: absolute;
			top: 0;
			right: 50%;
			bottom: 0;
			left: 0;
		}
	}
}

// When the window gets too small then we stack the left and right divs on top of the content divs
@media (max-width: $screen-md-max) {
	
	.checkered {
		
		&.left, &.right {
			
			.background {
				position: static;
				height: 550px;
				width: 100%;
			}
		}
	}
}

* html .clearfix, * html .content-boundary, * html .columns {
	zoom: 1;
} /* IE6 */

*:first-child+html .clearfix, *:first-child+html .content-boundary, *:first-child+html .columns {
	zoom: 1;
} /* IE7 */

// Lists

ul.inline {
	list-style-type: none;
	margin: 0;
	padding: 0;
	font-size: 0;

	li {
		margin-right: 0px;
		display: inline;
	}
}

// Rollover Display

ul.rollover-list {
	margin: 25px 0px;
	padding: 0px;
	
	li[data-hide-and-show-hover],
	li[c4HoverToggle] {
		list-style: none;
		padding: 0px 25px;
		color: $grey-130;
		line-height: 35px;
		background-color: $grey-20;
		cursor: default;
		
		&:first-child {
			border-top-left-radius: 3px;
			border-top-right-radius: 3px;
		}
		
		&:last-child {
			border-bottom-left-radius: 3px;
			border-bottom-right-radius: 3px;
		}
		
		&:hover,
		&.active {
			color: $grey-130;
			background-color: $grey-40;
		}
	}
		
	@media (min-width: $screen-sm-min) {
		margin: 25px -200px 25px 0px;
			
		&.right {
			margin: 25px 0px 25px -200px;
			
			li[data-hide-and-show-hover],
			li[c4HoverToggle] {
				padding-left: 200px;
				text-align: right;
			}
		}
	}
}

ul.rollover-display {
	padding: 0;
	margin: 0;
	z-index: 1;
	
	li {
		list-style: none;
	}
}

// C4play

.c4play {
    position: relative;
    min-height: $c4unit * 3;
    min-width: $c4unit * 3;
    background-color: $black-transparent;
    z-index: 1;
    cursor: pointer;

    &::before {
        content: "\f04b";
        position: absolute;
        top: 50%;
        left: 50%;
        height: $c4unit * 3;
        width: $c4unit * 3;
        margin-top: -1 * $c4unit * 3 / 2;
        margin-left: -1 * $c4unit * 3 / 2;
        font-family: $icons;
		font-weight: $font-weight-icon-fal;
        font-size: $c4unit;
        color: $white;
        line-height: $c4unit * 3;
        text-align: center;
        z-index: 1;
    }

    &:hover {
        background-color: $control4-red;
    }
}

// c4down

.c4down {
	position: absolute;
	bottom: 0px;
	left: 50%;
	margin-left: -1 * $c4unit * 3 / 2;
	display: block;
	height: $c4unit * 3;
	width: $c4unit * 3;
	cursor: pointer;

	&.inline {
		display: inline-block;
	}
	
	&::before{
		content: "\f078";
		position: absolute;
		top: 0;
		left: 0;
		height: $c4unit * 3;
		width:  $c4unit * 3;
		font-family: $icons;
		font-weight: $font-weight-icon-fal;
		font-size: 40px;
		color: $white-transparent;
		line-height: $c4unit * 3;
		text-align: center;
		z-index: 1;
	}

	&:hover::before {
		color: $white;
	}
}

// c4close

.c4close {
	position: absolute;
	display: block;
	top: $c4unit;
	right: $c4unit;
	border-bottom: none;
	z-index: 2;
	cursor: pointer;
	
	
	&::before{
		content: "\f00d";
		color: $text-grey;
		font-family: $icons;
		font-weight: $font-weight-icon-fal;
		z-index: 1;
	}

	&:hover::before {
		color: $control4-red;
	}
}

// c4status

.c4status {
	height: 50px;
    width: 50px;
	line-height: inherit;
    text-align: center;
    border-radius: 50%;
    
    
	&::before {
	    color: $white;
		font-family: $icons;
		font-weight: $font-weight-icon-fal;
	    font-size: 25px;
		line-height: 51px;
	}
}

.c4status-success {
    background-color: $success-icon;

    &::before {
        content: "\f058";
    }
}

.c4status-warning {
    background-color: $warning-icon;

    &::before {
        content: "\f06a";
    }
}

.c4status-error {
    background-color: $danger-icon;

    &::before {
        content: "\f06a";
    }
}

.c4status-autorenew {
	background-color: $autorenew-icon;
	&::before {
		content: "\f021";
	}
}

.status-icon {
	line-height: 0;
}

// Blog

.post-content iframe.wistia_embed {
	margin-left: -30px;
}

// Slider

// C4 Slider

.c4-slide {
    position: absolute !important;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    opacity: 0;
    z-index: -1;
    transition: opacity 0.5s linear, z-index 0.5s step-end;

    &.show {
        opacity: 1;
        z-index: 0;
        transition: opacity 0.5s linear, z-index 0.5s step-start;
    }
}

.product-img {
    height: 400px;

    .slider {
        background: #fff;

        &:hover {
            .slider-arrow {
                color: rgba(151,153,155,0.3);
            }

            .slider-arrow:hover {
                color: rgba(151,153,155,0.8);
            }
        }

        .c4-slide .slide-img {
            height: 100%;
            width: 100%;
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
        }

        ul.dots {
            bottom: -20px;
        }
    }
}

.slider-arrow {
	color: transparent;
	font-size: 60px;
	line-height: 75px;
	text-align: center;
	border-radius: 50%;
	z-index: 1;
	transition: 0.1s;  
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	transition: opacity 200ms;
	display: block;
}

.slider-arrow-left {
	left: 15px;
}

.slider-arrow.right {
	right: 15px;
}

.slider {
    background: #333;

	ul.dots {
		position: absolute;
		bottom: 15px;
		left: 0;
		right: 0;
		margin: 0 auto;
		padding: 0;
		list-style: none;
		text-align: center;
		z-index: 1000;

		li {
			display: inline;
			margin: 0px 5px;
			list-style: none;
			font-size: 0;
		}

		li button {
			display: inline-block;
			height: 10px;
			width: 10px;
			padding: 0;
			margin: 0;
			font-size: 0px;
			line-height: 0;
			background-color: rgba(151, 153, 155, 0.5);
			border: 0 none;
			border-radius: 50%;
		}
	}
}

.slider ul.dots li.active button, div.slider ul.dots li button:hover {
	background-color: $grey-5;
}
  
div.slick-track {
	position: absolute;
	width: 100% !important;
}

// Slick Slide

div.slider {
	.slide {
		position: absolute !important;
		width: 100%;
		min-height: 300px;
		background-size: cover;
		background-position: center center;
	}
	.slick-list {
		height: 350px !important;
	}
}

// .product-img  {
// 	height: 400px;
	
// 	div.slider {
		
// 		.slide  {
// 			height: 350px;
// 			min-height: 350px;
// 			background-size: contain !important;
// 			background-position: center center;
// 			background-repeat: no-repeat;
// 		}
		
// 		ul.slick-dots {
// 			bottom: -20px;
// 		}
// 	}	
// }

div.slider .slide.text-white {
	color: #fff;
}

div.slider .slide:before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

div.slider .slide.takeover {
	height: 500px;
}

div.slider .slick-arrow {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	color: transparent;
	z-index: 1;
}

div.slider:hover .slick-arrow {
	color: rgba(151,153,155,0.3);
}

div.slider .slick-arrow:hover {
	color: rgba(151,153,155,0.8);
}

div.slider .slick-arrow.slick-prev {
	left: 15px;
}

div.slider .slick-arrow.slick-next {
	right: 15px;
}

div.slider ul.slick-dots {
	position: absolute;
	bottom: 15px;
	left: 0;
	right: 0;
	margin: 0 auto;
	padding: 0;
	list-style: none;
	text-align: center;
	z-index: 1000;
}

div.slider ul.slick-dots li {
	display: inline;
	margin: 0px 5px;
	list-style: none;
	font-size: 0;
}

div.slider ul.slick-dots li button {
	display: inline-block;
	height: 10px;
	width: 10px;
	padding: 0;
	margin: 0;
	font-size: 0px;
	line-height: 0;
	background-color: rgba(151,153,155,0.5);
	border: 0 none;
	border-radius: 50%;
}

div.slider ul.slick-dots li.slick-active button, 
div.slider ul.slick-dots li button:hover {
	background-color: $grey-5;
}

.background-contain{
	background-position: center center; 
	background-size: contain; 
	background-repeat: no-repeat; 
}

// Static Page Banner

.banner {
	height: 400px;
	color: #fff;
    background-position: center center;
    background-size: cover;
}
.sub-banner {
	height: 200px;
	color: #fff;
    background-position: center center;
    background-size: cover;
}

/* Remove the blue outline! */
.slide.slick-slide.slick-current.slick-active:focus,
div.slider .slide:focus {
    outline: none;
}

progress[value] {
	&::-webkit-progress-value {
		background-color: $grey-100;
	}
}

// Accordion

.accordion {        
	display: flex;        
	flex-direction: row;
	justify-content: space-between;
	align-items: stretch;
	transition-duration: 800ms;
}
.accordion-item {
	flex-grow: 1;
	transition-duration: 800ms;
	z-index: 1;
}
.accordion-item:not(:last-child) {
	border-right: 1px solid #fff;
}
.accordion-item:hover {
	background-color: rgba(195, 32, 50, 0.8);
	cursor: pointer;
}
.accordion-item:hover h3.hidden {
	display: inline-block;
}
.accordion i.fa.fa-plus,
.accordion #exit-accordion i.fa.fa-times,
.accordion i.fal.fa-plus,
.accordion #exit-accordion i.fal.fa-times {
    border: 1px solid #FFF;
    border-radius: 28px;
    padding: 18px;
    margin-right: 12.5px;
    z-index: 2;
}
.accordion-item:hover i.fa.fa-plus,
div#exit-accordion:hover i.fa.fa-times,
.accordion-item:hover i.fal.fa-plus,
div#exit-accordion:hover i.fal.fa-times {
    border: none;
    background-color: #fff;
}
.accordion-item:hover i.fal.fa-plus:before,
div#exit-accordion:hover i.fal.fa-times:before {
	color: #8a878a;
}
.accordion-item span {
	opacity: 0;
}
.accordion-item:hover span {
	opacity: 1;
}
.accordion-item.active {
	flex-grow: 5;
}    
.accordion-item.active:hover {
	background-color: transparent;
}
.accordion-item.active h3.hidden,
.accordion-item.active p.hidden,
.accordion-item.active .row.hidden,
.accordion.active .accordion-item:hover.active h3.hidden {
	display: block;
}
.accordion.active .accordion-item i.fa.fa-plus,
.accordion.active .accordion-item i.fal.fa-plus,
.accordion.active .accordion-item span,
.accordion.active .accordion-item:hover h3.hidden {
    display: none;
}    
.accordion.active #exit-accordion.hidden {
	display: block;
	top: 100px;
	left: 50%;
}
@media (min-width: 801px) {
	.accordion-item {
		min-height: 100vh;
	}
	.accordion-item .padding {
		height: 350px;
	}
	.accordion.active .accordion-item .padding {
		height: 500px;
	}
	.accordion.active .accordion-item.active .padding {
		height: 300px;
	}
	.accordion.active .accordion-item.active h2 {
		transform: rotate(0deg);
		float: none;
		width: 100%;
		left: 0px;
	}
	.accordion.active .accordion-item h2 {
		transform: rotate(90deg);
		float: left;
		width: 100vh;
		transform-origin: left;
		left: 50%;
	}
}

@media (max-width: 800px) {
	.accordion,
	.accordion.full-screen {
		flex-direction: column;
		min-height: calc(100vh + 200px) !important;
		background-position: center right;
	}
	.accordion-item:not(:last-child) {
		border-bottom: 1px solid #fff;
	}
	.accordion-item:hover {
		background-color: transparent;
	}
	.accordion-item h2,
	.accordion-item h3 {
		margin-bottom: 12.5px;
	}
	.accordion-item div.absolute {
		position: relative;
	}
	.accordion.active .accordion-item h3.hidden,
	.accordion.active #exit-accordion.hidden {
		display: none;
	}
	.accordion-item.active {
		flex-grow: 8;
	}
	.accordion.active .accordion-item.active h2,
	.accordion.active .accordion-item h2 {
		transform: rotate(0deg);
	}
    .accordion i.fa.fa-plus,
    .accordion i.fal.fa-plus {
        padding: 14px;
    }
}

// Interactive Slider
.iteractive-slider.full-screen,
html:not(.mobile-browser) .iteractive-slider.full-screen-desktop {
    min-height: 100vh !important;
    width: 100vw !important;
    overflow: hidden;

	.slider-controls {
	    position: absolute;
	    bottom: 25px;
	    left: 0;
	    width: 100%;
	    text-align: center;
	    z-index: 1000;
	}
	.slider-controls a[data-slick-slide] > div {
	    opacity: 0.7;
	    background-color: transparent;
	    border: 1px solid white;
	    border-radius: 50%;
	    transition: 400ms;
	}
	.slider-controls a[data-slick-slide]:hover > div,
	.slider-controls a[data-slick-slide].active > div {
	    opacity: 1;
	    background-color: rgba(195, 32, 50, 0.8);
	    border: 1px solid transparent;
	}
	.slider-controls a div {
	    display: inline-block;
	    height: 100%;
	    width: 100%;
	    max-height: 100px;
	    max-width: 100px;
	    background-repeat: no-repeat;
	    background-size: contain;
	}
	.black p {
	    color: #fff;
	}
	@media (max-width: 1300px) {
	    .slide .vertically-center {
	        width: calc(100vw - 70px);
	    }        
	}
	@media (max-width: 480px) {
	    .full-screen.black {
	        border: none !important;
	    }
	    .slide .vertically-center {
	        top: 50%;
	        width: calc(100vw - 70px);
	    }
	    .slide h3 {
	        font-size: 1.1em;
	    }
	    .slide.bg-position-left {
	        background-position: center left !important;
	    }
	    .slide.bg-position-right {
	        background-position: center right !important;
	    }
	    .slider-controls a div {
	        max-height: 80px;
	        max-width: 80px;
	    }
	}
}