@import "global";

// Text
// Important - this file is shared with other web properties.

html, body {
	@include font-reset();
}

h1 {
	font-size: $font-size-base * $font-size-ratio-lgdesktop * $font-size-ratio-lgdesktop;

	&.jumbo,
	span.jumbo {
		font-size: $font-size-base * $font-size-ratio-lgdesktop * $font-size-ratio-lgdesktop * $font-size-ratio-lgdesktop;
	}
}

h2, h1.blog-title {
	font-size: $font-size-base * $font-size-ratio-lgdesktop;
}

@media (max-width: $screen-lg-max) {
	
	h1 {
		font-size: $font-size-base * $font-size-ratio-desktop * $font-size-ratio-desktop;

		&.jumbo,
		span.jumbo {
			font-size: $font-size-base * $font-size-ratio-desktop * $font-size-ratio-desktop * $font-size-ratio-desktop;
		}
	}

	h2, h1.blog-title {
		font-size: $font-size-base * $font-size-ratio-desktop;
	}
}

// Tablet
@media (max-width: $screen-sm-max) {
	
	h1 {
		font-size: $font-size-base * $font-size-ratio-tablet * $font-size-ratio-tablet;

		&.jumbo,
		span.jumbo {
			font-size: $font-size-base * $font-size-ratio-tablet * $font-size-ratio-tablet * $font-size-ratio-tablet;
		}
	}

	h2, h1.blog-title {
		font-size: $font-size-base * $font-size-ratio-tablet;
	}
}

// Mobile
@media (max-width: $screen-xs-max) {
	
	h1 {
		font-size: $font-size-base * $font-size-ratio-mobile * $font-size-ratio-mobile;

		&.jumbo,
		span.jumbo {
			font-size: $font-size-base * $font-size-ratio-mobile * $font-size-ratio-mobile * $font-size-ratio-mobile;
		}
	}

	h2, h1.blog-title {
		font-size: $font-size-base * $font-size-ratio-mobile;
	}
}

* a {
	color: inherit;
	text-decoration: none;
	cursor: pointer;

	&.c4close,
	&.c4play {
		font-size: 22px;					
		line-height: inherit;
	}
	
	&:hover {
		color: $control4-red;
	}
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
table,
blockquote,
pre{
    margin: 0px 0px #{$c4unit} 0px;
    padding: 0px;
    
    &:last-child {
	    margin: 0px 0px 0px 0px;
    }
}

h1,
h2,
h3,
h4 {
	color: inherit;
	font-weight: $font-weight-normal;
	line-height: 1.1em;
}

h1 {
	font-weight: $header-font-weight-normal;
	max-width: none;
	
	&.jumbo,
	span.jumbo {
		text-transform: uppercase;
		line-height: 0.9em;
	}
}

h2 {
	font-weight: $header-font-weight-normal;
	text-transform: uppercase;
}

h3 {
	margin: 1.4rem 0px 0px 0px;
	font-size: $font-size-base;
	font-weight: $font-weight-bold;
	line-height: $c4unit;
	text-transform: uppercase;

	&:first-child {
		margin: 0px 0px 0px 0px;
	}
}

h4 {
	margin: 1.4rem 0px 0px 0px;
	font-size: $font-size-base;
	font-weight: $font-weight-bold;
	line-height: $c4unit;

	&:first-child {
		margin: 0px 0px 0px 0px;
	}
}

ul {
    padding: 0px 0px 0px #{$c4unit};
    margin: 0px 0px #{$c4unit} 0px;
	
	&.no-style,
	&.no-style li {
		list-style: none;
		padding: 0;
		margin: 0;
	}

	&.padding-bottom,
	&.padding-bottom > li {
		padding-bottom: $c4unit;
	}
}

b,
.bold,
strong {
	font-weight: $font-weight-bold !important;
}

.font-weight-normal {
	font-weight: $font-weight-normal;
}

.font-size-bigger {
	font-size: #{$font-size-ratio-mobile}em;
}

.uppercase {
	text-transform: uppercase !important;
}

.lowercase {
	text-transform: none !important;
}

.small-caps {
	@include small-caps();
}

.text-shadow {
	text-shadow: $shadow;
}

a {
	color: $control4-dark-red;
	text-decoration: none;
}

i {
    position: relative;
    // top: 1px;
    margin: 0 5px;
    font-size: 100%;
    line-height: $font-size-base;
}

.text-dark {
	color: $text-color;
}

.text-grey {
	color: $text-grey;
}

.text-white {
	color: $white;
}

.text-white.link {
	color: rgba(255, 255, 255, 0.6);
	
	&:hover {
		color: $white;
	}
}

.text-red {
	color: $control4-dark-red !important;
}

.text-gold {
	color: $color-gold !important;
}

.text-platinum {
	color: $color-platinum !important;
}

.text-pinnacle,
.text-diamond {
	color: $color-pinnacle !important;
}

.text-authorized {
	color: $color-authorized !important;
}

.text-success {
	color: $success-icon !important;
}

.text-warning {
	color: $warning-icon !important;
}

.text-dark-red,
.text-danger {
	color: $control4-dark-red !important;
}

.chart-danger {
	color: #7f212d;
}

.chart-warn {
	color: #c4901d;
}

.chart-success {
	color: #778628;
}

.chart-unknown {
	color: #A3A4A5;
}

.text-autorenew {
	color: $autorenew-icon !important;
}

.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}

.small,
small {
	display: inline-block;
	color: $text-grey;
	font-size: $font-size-small;
	// font-style: italic;
	line-height: 18px;
	
	&.footnote {
		display: block;
		padding-top: $c4unit;
		margin-top: $c4unit;
		border-top: 1px solid $border-style;
	}
}

.tags {

	> a,
	.tag {
		display: inline-block;
		padding:0 10px;
		margin:0 5px 10px 0;
		height: 25px;
		color: $grey-80;
		font-size:12.30769px;
		line-height:24px;
		background-color: $grey-10;
		border-radius: 13px;
		
		&a:hover {
			color: $control4-red;
		}
	}
}

span.badge, 
small.badge {
	margin-top: -2px;
	margin-left: $c4unit / 2;
    height: calc(12px + 0.67em);
    width: calc(12px + 0.9em);
    color: $white;
    font-size: 0.9em; 
    font-style: normal;
    line-height: calc(12px + 0.7em);
    text-align: center;
    background-color: $control4-dark-red;
    border-radius: calc(6px + 0.33em);
    
    &.grey {
	    background-color: $grey-80;
    }
}

blockquote {
	display: block;
	position: relative;
	margin: $c4unit 0px;
    padding: 0px 0px 0px $c4unit * 2;
    color: $text-grey;
    font-family: $serif;
    font-size: $font-size-base;
    font-style: italic;
    line-height: 1.3em;
    border-left: 6px solid $orange;
    
    &:before {
	    content: "\201C";
		position: absolute;
	    left: 16px;
	    top: 20px;
	    color: $text-grey;
	    font-family: $serif;
	    font-style: normal;
	    font-weight: $font-weight-normal;
	    font-size: 80px;
	}
	
	&:after{
	  content: "";
	}
	
	cite {
		display: block;
		margin-top: $c4unit / 2;
		color: $text-grey;
		@include small-caps();
		
		&:before {
			content: "\2014\0020";
		}
	}
}

pre,
code {
	display: block;
	padding: $c4unit;
	color: $white-half-opacity;
	font-size: $font-size-small;
	line-height: 17px;
	word-wrap: break-word;
	background-color: $grey-120;
	border-radius: $c4unit/2;
	overflow-x: scroll;
}

*::selection {
	color: $white;
	background: $pakedge-blue;
}

.truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.strikethrough {
    text-decoration: line-through;
}
