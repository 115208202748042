@import "global";

*,
::after,
::before{
	box-sizing:border-box;
}

html,
body {
	height: auto;
	padding: 0;
	margin: 0;
	font: 400 15px $sans-serif;
	line-height: 1.66rem;
	box-sizing:border-box;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
  -webkit-overflow-scrolling: touch;
} 

.hidescroll {
  overflow: hidden;
}

a#header-logo {
  position: absolute;
  top: 11px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  height: 28px;
  width: 160px;
  background: url("//res.cloudinary.com/control4/image/upload/logos/control4-portal-menu-white.png")
    center center no-repeat;
  background-size: contain;
}

c4-header header,
header {
  a#header-logo {
    background: url("//res.cloudinary.com/control4/image/upload/logos/control4-portal-menu-white.png") center center no-repeat;
  }
}

// drawer navigation

nav#c4-nav {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: $c4unit * 2;
  left: 0;
  bottom: 0;
  width: 275px;
  background-color: $grey-120;
  transition: left 0.2s;
  // z-index: 1;
  div#c4-nav-search {
    height: $c4unit * 2;
		border-bottom: 1px solid $grey-100;
  }

  div#c4-nav-search input {
    padding-left: $c4unit;

    &:not(:focus) {
      color: #ddd !important;
      background-color: transparent;
    }

    &::-webkit-input-placeholder {
      color: #ddd;
    }

    &:-moz-placeholder {
      color: #ddd;
    }

    &::-moz-placeholder {
      color: #ddd;
    }

    &::-ms-input-placeholder {
      color: #ddd;
    }
  }

  div#c4-nav-menu {
    flex: 100%;
    margin: 17px 0;
    overflow-x: hidden;

    div.c4-nav-menu {
      position: absolute;
      width: 300px;
      top: 0;
      bottom: 0;
      background-color: $grey-120;
      overflow-y: scroll;
    }
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;

    li {
      a, div {
        display: block;
        margin-right: 35px;
        color: #eee;
        text-indent: $c4unit;
        line-height: 33px;
        text-overflow: ellipsis;
        white-space: nowrap;
				overflow: hidden;
				background-color: $grey-120;

        i {
		  		width: $c4unit;
          margin: 0 $c4unit/2 0 0;
          color: rgba(255, 255, 255, 0.33);
          font-weight: 300 !important;
          text-align: center;
					text-indent: 0;
					
					&.text-white {
						color: #fff;
					}
				}

        &:not(.active):hover,
        &.active.clickable:hover,
        &:not(.active):not(.nav-control):hover i,
        &.active.clickable:hover i,
        i:hover.clickable {
          color: #fff;
          cursor: pointer;
        }

        &.active {
          color: #fff;
          font-weight: bold;
          cursor: default;
				}
				
				&.nav-control {
					margin-right: 0;
					padding-right: 50px;
					line-height: $c4unit * 2;
					height: $c4unit * 2;
					border-top: 1px solid $grey-120;
					
					&.activated {    
						border-bottom: unset;
						background-color: $grey-130;
						&::before {
							display: block;
							content: "";
							height: 2px;
							width: 100%;
							position: absolute;
							bottom: 0;
							left: 0;
							background: linear-gradient(to right, #6e1d28, #C32032);
						}
					}
					i.right {
						position: absolute;
						right: 12.5px;
						line-height: 50px;
					}
				}
      }
    }

    ul li a {
      text-indent: 62px;
    }
  }

  #user-controls,
  c4-user-controls {
    display: block;
    padding: 10px $c4unit 21px;
    white-space: nowrap;
    overflow: hidden;
    color: #ddd;
    background-color: $grey-130;
		border-top: 1px solid $grey-120;

    .impersonate-action {
      position: absolute;
      top: 11px;
      right: $c4unit;
      text-align: right;
      z-index: 1;
    }

    a {
      display: block;
      color: #969696;

      &:hover {
        color: #fff;
      }
    }
  }
}

// only sm and xs
@media (max-width: 1199px) {
  body {
    nav#c4-nav {
      left: -275px;
      div#c4-nav-cart {
        display: none;
      }
    }

    div#page-container {
      padding-top: $c4unit * 2;
      margin-left: 0;
    }
  }

  body.showmenu {
    nav#c4-nav {
      left: 0;
    }

    div#page-container {
      transform: translate(275px);
    }
  }
}

// page-container

div#page-container {
	padding: 0 0 50px 0;
  margin-left: 275px;
  min-height: 100vh;
  background-color: $grey-5 !important;
  transition: margin-left 0.2s, transform 0.2s;
}

div.page-header {
  position: relative;
  width: 100%;
  height: 300px;
  background-attachment: fixed;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: auto 300px;

  @media (min-width: 1200px) {
    background-position: right top;
    background-size: calc(100% - 230px) auto;
  }

  &:before {
    display: none;
  }
}

h1 {
  font-size: 35px;
}

.text-primary {
  color: $control4-dark-red;
}

.control4-primary {
  color: $control4-dark-red;
}

.pakedge-primary {
  color: $pakedge-green;
}

.triad-primary {
  color: $triad-gold;
}

.widget {
  margin-bottom: 25px;
  text-overflow: ellipsis;
  background-color: $white;
  -webkit-border-border-radius: $border-radius;
  border-radius: $border-radius;

  &.widget-fixed-height {
    height: 400px;
  }

  h3[class^="widget-header-"] {
    margin: 0;
    padding: 0 $c4unit;
    font-size: $font-size-base;
    line-height: 2 * $c4unit;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-transform: none;
    font-weight: $font-weight-normal;
    letter-spacing: normal;
    -webkit-border-top-left-radius: $border-radius;
    -webkit-border-top-right-radius: $border-radius;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  .widget-header-grey {
    color: white;
    background-color: $grey-40;

    a {
      color: white;
    }
  }

  .widget-header-white {
    color: $text-grey;
    border-bottom: 1px solid #eee;

    a {
      color: $text-grey;
    }
  }

  .widget-header-white.text-white {
    color: white;
  }

  .padding {
    word-wrap: break-word;
  }
}

.widget.product-img {
  overflow: hidden;

  .slider .c4-slide {
    &:not(.engraving-slide) {
      cursor: pointer;
      cursor: zoom-in;
      transform: scale(1);
    }

    .slide-img {
      height: 100%;
      width: 100%;
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
    }
  }

  &.zoom {
    .c4-slide:not(.engraving-slide) {
      transform: scale(4);
      cursor: zoom-out;

      &.slick-active {
        transform: scale(4);
      }
    }
  }
}

div.product-card,
div.spec-card {
  transition: box-shadow 0.2s;

  &:hover {
    z-index: 1;
    box-shadow: 0px 0px $c4unit 0px rgba(0, 0, 0, 0.15);

    .card-button a.c4btn,
    .card-button button {
      bottom: -50px;
      box-shadow: 0px 0px $c4unit 0px rgba(0, 0, 0, 0.15);
    }
  }

  .padding {
    height: 300px;
    padding: 25px 25px 20px 25px;

    h4 {
      margin-bottom: 0;
      height: 50px;
      overflow: hidden;
    }

    div.img {
      height: 150px;
      width: 100%;
      margin-bottom: 25px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  .card-button a.c4btn,
  .card-button button {
    position: absolute;
    margin: 0;
    bottom: 0;
    z-index: -1;
    transition: box-shadow 0.2s, bottom 0.2s;
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.3);
  }
}

.widget.home {
  transition: box-shadow 0.2s;

  &:hover {
    z-index: 1;
    box-shadow: 0px 0px $c4unit 0px rgba(0, 0, 0, 0.15);

    a.c4btn {
      bottom: -50px;
      box-shadow: 0px 0px $c4unit 0px rgba(0, 0, 0, 0.15);
    }
  }

  a.c4btn {
    position: absolute;
    margin: 0;
    bottom: 0;
    z-index: -1;
    transition: box-shadow 0.2s, bottom 0.2s;
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.3);
  }
}

div.spec-card {
  height: 500px;

  .padding {
    div.img {
      height: 450px;
    }
  }
}

div.thumbnail-img {
  height: 100px;
  width: 100%;
  margin-bottom: 12px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  &.search{
	  height: 40px;
  }
}

.text-light-grey {
  color: $grey-20;
}

// Background Colors

.bg-black {
  background-color: $black;
  color: $white;
}

.bg-red {
  background-color: $control4-dark-red;
  color: $white;
}

.blue .widget,
.blue .widget-header {
  background-color: #1a5b73;
}

.blue h3,
.blue .fal,
.dark-orange h3 {
  opacity: 0.5;
  color: $white;
}

.contact-height {
  height: 100px;
  padding-top: 5px;
}

.queue-time {
  font-size: 35px;
  font-weight: 400 !important;
  margin-bottom: 10px;
  line-height: 35px;
}

.result {
  margin-bottom: 25px;
}

.result h3,
.result h4 {
  text-transform: none;
  margin-bottom: 10px;
}

p.num-result {
  font-size: 13px;
  margin-bottom: 25px;
  border-bottom: 1px solid $grey-10;
}

.border-bottom {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  margin-bottom: 5px;
}

.fal {
  &.fa-cc-visa:hover,
  &.fa-cc-visa.active {
    color: $color-visa;
  }

  &.fa-cc-mastercard:hover,
  &.fa-cc-mastercard.active {
    color: $color-mastercard;
  }

  &.fa-cc-amex:hover,
  &.fa-cc-amex.active {
    color: $color-amex;
  }

  &.fa-cc-discover:hover,
  &.fa-cc-discover.active {
    color: $color-discover;
  }
}

.fas {
  &.fa-square.white {
    color: $white;
    border: 1px solid $grey-10;
  }

  &.fa-square.grey {
    color: $grey-120;
  }

  &.fa-square.light-grey {
    color: $grey-20;
  }

  &.fa-square.outline {
    color: transparent;
    border: 1px solid $black;
  }
}

a.c4btn.light-grey i.fal.fa-square.light-grey,
button.c4btn.light-grey i.fal.fa-square.light-grey {
  color: $grey-40;
}

// only xs
@media (max-width: 768px) {
  .widget {
    &.widget-fixed-height {
      height: auto !important;
    }
  }
}

// only sm and xs
@media (max-width: 1199px) {
  div.product-card,
  div.product-card:hover,
  div.spec-card,
  div.spec-card:hover {
    .card-button a.c4btn,
    .card-button button {
      position: relative;
      bottom: 0;
      color: $text-color;
      background-color: $grey-10;
      border-color: transparent;
      box-shadow: none;
      z-index: 1;
    }
  }
}

// Product Page

.img-list {
  max-height: 150px;
  width: auto !important;
}

.img-center {
  margin-left: auto;
  margin-right: auto;
  padding: 25px 10px 10px;
  display: block;
}

.product-height {
  height: 170px;
}

.product-recommended {
  height: 150px;
}

div.product-img {
  .slick-arrow {
    display: none !important;
    color: rgba(200, 200, 200, 0.67) !important;
    font-size: 60px;
    // height: 75px;
    // width: 75px;
    // background-color: #fff;
    line-height: 75px;
    text-align: center;
    border-radius: 50%;
    z-index: 10000;
    transition: 0.1s;

    &.slick-prev {
      left: -15px;
    }

    &.slick-next {
      right: -15px;
    }
  }

  &:hover .slick-arrow {
    display: block !important;

    &:hover {
      color: $control4-dark-red !important;
    }
  }
}

ng-container {
	display: block !important; 
	width: 100%;
}

// Engraving

#filedrag {
  display: none;
  font-weight: bold;
  text-align: center;
  padding: 1em 0;
  margin: 1em 0;
  color: $grey-100;
  border: 2px dashed #eee;
  -webkit-border-radius: $border-radius;
  border-radius: $border-radius;
  cursor: default;
}

#filedrag.hover {
  color: #f00;
  border-color: #eee;
  border-style: solid;
  box-shadow: inset 0 3px 4px #888;
}

.engraving-product-img {
  height: 400px;
}

.product-img div.slider .c4-slide.engraving-slide .slide-img {
  background-size: auto !important;
}

// KB Alert

#kb-alert {
  h1,
  h2,
  h3,
  h4 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0px;
    line-height: 25px;
  }

  h2 {
    text-transform: none;
  }

  h3 {
    color: $text-dark-grey;
  }

  p {
    margin-bottom: 0px;
  }
}

// chat

#chat {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 0;
  z-index: 10;
  max-width: 500px;
  width: 100vw;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
}

#chat #chat_window {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 150px);
  background-color: #f5f5f5;
}

#chat #chat_thread {
  flex: 1 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

#chat #chat_input {
  height: 125px;
}

#chat #chat_thread .me,
#chat #chat_thread .you {
  padding: 13px 25px 25px 25px;
  margin: 25px;
  background: #fff;
}

#chat #chat_thread .me {
  margin-right: 0px;
}

#chat #chat_thread .you {
  margin-left: 0px;
}

#chat #chat_thread .me .author,
#chat #chat_thread .you .author {
  margin-bottom: 0;
  color: #777;
  font-size: 11px;
  font-style: italic;
}

#chat #chat_thread .me .author {
  text-align: right;
}

#chat #chat_thread .you .author {
  color: $control4-dark-red;
}

// Iframe

iframe {
  max-width: 100%;
}

// Datepicker

.datepicker table tr td.active:hover,
.datepicker table tr td.active:hover:hover,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active:hover.disabled,
.datepicker table tr td.active.disabled.disabled,
.datepicker table tr td.active.disabled:hover.disabled,
.datepicker table tr td.active[disabled],
.datepicker table tr td.active:hover[disabled],
.datepicker table tr td.active.disabled[disabled],
.datepicker table tr td.active.disabled:hover[disabled] {
  background-color: $control4-dark-red !important;
}

// footer

footer {
  position: absolute;
  bottom: 0;
  height: $c4unit * 2;
  width: 100%;
  background-color: $grey-5;

  ul {
    display: inline-block;
    margin: 0px;
    padding: 0px 0px 0px $c4unit;
    height: $c4unit * 2;
    color: rgb(150, 150, 150);
    font-size: 0px;
    list-style-type: none;
    white-space: nowrap;

    li {
      display: inline-block;
      padding: 0px 1.1em 0px 0px;
      @include font-reset();
      color: $text-grey;
      font-size: 13px;
      line-height: $c4unit * 2;

      a {
        color: $text-grey;

        &:hover {
          color: $text-color;
        }
      }
    }
  }
}

// Quick View
.product-card:hover .overlay a.c4btn,
.product-card:hover .overlay button {
  position: relative;
  bottom: 0px;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.25);
}

.product-card:hover .overlay {
  background-color: rgba(0, 0, 0, 0.12);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

// Popup
.popup-container {
	position: absolute;
	display: block;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	z-index: 1000;
}


.fade {
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;

  &.ng-enter {
    filter: alpha(opacity=0);
    opacity: 0;
  }

  &.ng-enter.ng-enter-active {
    filter: alpha(opacity=100);
    opacity: 1;
  }

  &.ng-leave {
    filter: alpha(opacity=100);
    opacity: 1;
  }

  &.ng-leave.ng-leave-active {
    filter: alpha(opacity=0);
    opacity: 0;
  }
}
.flex-container {
		display: flex;
		flex-flow: row wrap;
}

.widget.filter-container.ng-hide form,
.widget.filter-container.ng-hide button,
.widget.filter-container.ng-hide h3,
.widget.filter-container.ng-hide p,
.widget.filter-container.ng-hide label {
		opacity: 0;
		transition: 100ms ease;
}

@media(max-width:799px) {
		c4-product-filter,
		.widget.filter-container,
		.widget.product-list,
		.widget.product-list.filters {
				width: 100%;
				transition: 300ms ease;
		}
		.widget.filter-container.ng-hide {
				height: 0px;
				transition: 300ms ease;
		}
}

@media (min-width: 800px) {
		.widget.filter-container {
				width: 300px;
				transition: 200ms ease;
		}
		.widget.filter-container.ng-hide {
				width: 0px;
				transition: 200ms ease;
		}
		.widget.product-list {
				width: 100%;
				transition: 200ms ease;
		}
		.widget.product-list.filters {
				margin-left: auto;
				width: calc(100% - 325px);
				transition: 200ms ease;
		}
}
