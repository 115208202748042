@import "global";

table.table {
	width: 100%;
	border-spacing: 0;
	
	thead {
	
		tr {
			
			th {
				padding: $c4unit / 2;
				font-weight: $font-weight-bold;
				text-align: left;
				border-bottom: none;
			}
		}
	}
	
	tbody {
		
		tr {
			
			td {
				padding: $c4unit / 2;	
				border-top: 1px solid rgb(222,222,222);
			}
		}
	}
	
	&.table-hover {
	
		tbody {
			
			tr:hover {
				background-color: rgba(128,128,128,0.2);
			}
		}
	}
}

div.table,
.table {
	
	.head {

		& > div.row,
		& > .row {
			margin-left: 0;
			margin-right: 0;
			font-weight: $font-weight-bold;
			border-bottom: 1px solid rgba(0,0,0,0.1);
	
			& > div[class^="col-"] {
				padding-top: $c4unit / 2;	
				padding-bottom: $c4unit /2;
				padding-left: 0px;
				padding-right: 0px;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}
	
	&,
	.body {
		
		> div.row,
		> .row,
		> .row > .row {
			
			margin-left: 0;
			margin-right: 0;
			min-height: $c4unit * 2;
			
			&:not(:last-child) {
				border-bottom: 1px solid rgba(0,0,0,0.25);
			}
			
			> div[class^="col-"] {
				padding: 12px 0;
				text-overflow: ellipsis;
				
				> div.row {
					margin: 0 $c4unit/-2;

					> div[class^="col-"] {
						padding: 0;
						padding-top: 0;
						padding-right: $c4unit / 2;
						padding-bottom: 0;
						padding-left: $c4unit / 2;
					}

					&.no-margin {
						margin: 0;
						
						> div[class^="col-"] {
							padding-right: 0;
							padding-left: 0;
						}
					}
				}
			}
		}
	}

	&.table-sm {

		.body {

			> div.row {
				min-height: $c4unit * 1.3;

				> div[class^="col-"] {
					padding: 5px 0 4px 0;
				}
			}
		}
	}
	
	&.table-hover {
	
		&,
		.body {
		
			> div.row:hover {
				background-color: rgba(41, 28, 28, 0.2) !important;
			}
		}
	}
}

div.table.table-small div.row {
    min-height: 37.5px;
}