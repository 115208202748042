// Import all the theming functionality.
// We can use relative imports for imports from the cdk because we bundle everything
// up into a single flat scss file for material.
// We want overlays to always appear over user content, so set a baseline
// very high z-index for the overlay container, which is where we create the new
// stacking context for all overlays.
$cdk-z-index-overlay-container: 1000 !default;
$cdk-z-index-overlay: 1000 !default;
$cdk-z-index-overlay-backdrop: 1000 !default;

// Background color for all of the backdrops
$cdk-overlay-dark-backdrop-background: rgba(0, 0, 0, 0.32) !default;

// Default backdrop animation is based on the Material Design swift-ease-out.
$backdrop-animation-duration: 400ms !default;
$backdrop-animation-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1) !default;


@mixin cdk-overlay() {
  .cdk-overlay-container, .cdk-global-overlay-wrapper {
    // Disable events from being captured on the overlay container.
    pointer-events: none;

    // The container should be the size of the viewport.
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  // The overlay-container is an invisible element which contains all individual overlays.
  .cdk-overlay-container {
    position: fixed;
    z-index: $cdk-z-index-overlay-container;

    &:empty {
      // Hide the element when it doesn't have any child nodes. This doesn't
      // include overlays that have been detached, rather than disposed.
      display: none;
    }
  }

  // We use an extra wrapper element in order to use make the overlay itself a flex item.
  // This makes centering the overlay easy without running into the subpixel rendering
  // problems tied to using `transform` and without interfering with the other position
  // strategies.
  .cdk-global-overlay-wrapper {
    display: flex;
    position: absolute;
    z-index: $cdk-z-index-overlay;
  }

  // A single overlay pane.
  .cdk-overlay-pane {
    // Note: it's important for this one to start off `absolute`,
    // in order for us to be able to measure it correctly.
    position: absolute;
    pointer-events: auto;
    box-sizing: border-box;
    z-index: $cdk-z-index-overlay;

    // For connected-position overlays, we set `display: flex` in
    // order to force `max-width` and `max-height` to take effect.
    display: flex;
    max-width: 100%;
    max-height: 100%;
  }

  .cdk-overlay-backdrop {
    // TODO(jelbourn): reuse sidenav fullscreen mixin.
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    z-index: $cdk-z-index-overlay-backdrop;
    pointer-events: auto;
    -webkit-tap-highlight-color: transparent;
    transition: opacity $backdrop-animation-duration $backdrop-animation-timing-function;
    opacity: 0;

    &.cdk-overlay-backdrop-showing {
      opacity: 1;

      // In high contrast mode the rgba background will become solid so we need to fall back
      // to making it opaque using `opacity`. Note that we can't use the `cdk-high-contrast`
      // mixin, because we can't normalize the import path to the _a11y.scss both for the
      // source and when this file is distributed. See #10908.
      @media screen and (-ms-high-contrast: active) {
        opacity: 0.6;
      }
    }
  }

  .cdk-overlay-dark-backdrop {
    background: $cdk-overlay-dark-backdrop-background;
  }

  .cdk-overlay-transparent-backdrop {
    // Note: as of Firefox 57, having the backdrop be `background: none` will prevent it from
    // capturing the user's mouse scroll events. Since we also can't use something like
    // `rgba(0, 0, 0, 0)`, we work around the inconsistency by not setting the background at
    // all and using `opacity` to make the element transparent.
    &, &.cdk-overlay-backdrop-showing {
      opacity: 0;
    }
  }

  // Overlay parent element used with the connected position strategy. Used to constrain the
  // overlay element's size to fit within the viewport.
  .cdk-overlay-connected-position-bounding-box {
    position: absolute;
    z-index: $cdk-z-index-overlay;

    // We use `display: flex` on this element exclusively for centering connected overlays.
    // When *not* centering, a top/left/bottom/right will be set which overrides the normal
    // flex layout.
    display: flex;

    // We use the `column` direction here to avoid some flexbox issues in Edge
    // when using the "grow after open" options.
    flex-direction: column;

    // Add some dimensions so the element has an `innerText` which some people depend on in tests.
    min-width: 1px;
    min-height: 1px;
  }

  // Used when disabling global scrolling.
  .cdk-global-scrollblock {
    position: fixed;

    // Necessary for the content not to lose its width. Note that we're using 100%, instead of
    // 100vw, because 100vw includes the width plus the scrollbar, whereas 100% is the width
    // that the element had before we made it `fixed`.
    width: 100%;

    // Note: this will always add a scrollbar to whatever element it is on, which can
    // potentially result in double scrollbars. It shouldn't be an issue, because we won't
    // block scrolling on a page that doesn't have a scrollbar in the first place.
    overflow-y: scroll;
  }
}

@mixin cdk-a11y {
  .cdk-visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;

    // Avoid browsers rendering the focus ring in some cases.
    outline: 0;

    // Avoid some cases where the browser will still render the native controls (see #9049).
    -webkit-appearance: none;
    -moz-appearance: none;
  }
}

// Applies styles for users in high contrast mode. Note that this only applies
// to Microsoft browsers. Chrome can be included by checking for the `html[hc]`
// attribute, however Chrome handles high contrast differently.
//
// @param target Which kind of high contrast setting to target. Defaults to `active`, can be
//    `white-on-black` or `black-on-white`.
@mixin cdk-high-contrast($target: active) {
  @media (-ms-high-contrast: $target) {
    @content;
  }
}

// Core styles that enable monitoring autofill state of text fields.
@mixin cdk-text-field {
  // Keyframes that apply no styles, but allow us to monitor when an text field becomes autofilled
  // by watching for the animation events that are fired when they start. Note: the /*!*/ comment is
  // needed to prevent LibSass from stripping the keyframes out.
  // Based on: https://medium.com/@brunn/detecting-autofilled-fields-in-javascript-aed598d25da7
  @keyframes cdk-text-field-autofill-start {/*!*/}
  @keyframes cdk-text-field-autofill-end {/*!*/}

  .cdk-text-field-autofill-monitored:-webkit-autofill {
    animation-name: cdk-text-field-autofill-start;
  }

  .cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
    animation-name: cdk-text-field-autofill-end;
  }

  // Remove the resize handle on autosizing textareas, because whatever height
  // the user resized to will be overwritten once they start typing again.
  textarea.cdk-textarea-autosize {
    resize: none;
  }

  // This class is temporarily applied to the textarea when it is being measured. It is immediately
  // removed when measuring is complete. We use `!important` rules here to make sure user-specified
  // rules do not interfere with the measurement.
  textarea.cdk-textarea-autosize-measuring {
    height: auto !important;
    overflow: hidden !important;
    // Having 2px top and bottom padding seems to fix a bug where Chrome gets an incorrect
    // measurement. We just have to account for it later and subtract it off the final result.
    padding: 2px 0 !important;
    box-sizing: content-box !important;
  }
}

// Used to generate UIDs for keyframes used to change the text field autofill styles.
$cdk-text-field-autofill-color-frame-count: 0;

// Mixin used to apply custom background and foreground colors to an autofilled text field.
// Based on: https://stackoverflow.com/questions/2781549/
// removing-input-background-colour-for-chrome-autocomplete#answer-37432260
@mixin cdk-text-field-autofill-color($background, $foreground:'') {
  @keyframes cdk-text-field-autofill-color-#{$cdk-text-field-autofill-color-frame-count} {
    to {
      background: $background;
      @if $foreground != '' { color: $foreground; }
    }
  }

  &:-webkit-autofill {
    animation-name: cdk-text-field-autofill-color-#{$cdk-text-field-autofill-color-frame-count};
    animation-fill-mode: both;
  }

  &.cdk-text-field-autofill-monitored:-webkit-autofill {
    animation-name: cdk-text-field-autofill-start,
                    cdk-text-field-autofill-color-#{$cdk-text-field-autofill-color-frame-count};
  }

  $cdk-text-field-autofill-color-frame-count:
      $cdk-text-field-autofill-color-frame-count + 1 !global;
}


// Core styles that can be used to apply material design treatments to any element.
// Media queries
// TODO(josephperrott): Change $mat-xsmall and $mat-small usages to rely on BreakpointObserver,
$mat-xsmall: 'max-width: 599px';
$mat-small: 'max-width: 959px';

// TODO: Revisit all z-indices before beta
// z-index master list

$z-index-fab: 20 !default;
$z-index-drawer: 100 !default;

// Global constants
$pi: 3.14159265;

// Padding between input toggles and their labels
$mat-toggle-padding: 8px !default;
// Width and height of input toggles
$mat-toggle-size: 20px !default;

// Easing Curves
// TODO(jelbourn): all of these need to be revisited

// The default animation curves used by material design.
$mat-linear-out-slow-in-timing-function: cubic-bezier(0, 0, 0.2, 0.1) !default;
$mat-fast-out-slow-in-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !default;
$mat-fast-out-linear-in-timing-function: cubic-bezier(0.4, 0, 1, 1) !default;

$ease-in-out-curve-function: cubic-bezier(0.35, 0, 0.25, 1) !default;

$swift-ease-out-duration: 400ms !default;
$swift-ease-out-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1) !default;
$swift-ease-out: all $swift-ease-out-duration $swift-ease-out-timing-function !default;

$swift-ease-in-duration: 300ms !default;
$swift-ease-in-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2) !default;
$swift-ease-in: all $swift-ease-in-duration $swift-ease-in-timing-function !default;

$swift-ease-in-out-duration: 500ms !default;
$swift-ease-in-out-timing-function: $ease-in-out-curve-function !default;
$swift-ease-in-out: all $swift-ease-in-out-duration $swift-ease-in-out-timing-function !default;

$swift-linear-duration: 80ms !default;
$swift-linear-timing-function: linear !default;
$swift-linear: all $swift-linear-duration $swift-linear-timing-function !default;



// A collection of mixins and CSS classes that can be used to apply elevation to a material
// element.
// See: https://material.io/design/environment/elevation.html
// Examples:
//
//
// .mat-foo {
//   @include $mat-elevation(2);
//
//   &:active {
//     @include $mat-elevation(8);
//   }
// }
//
// <div id="external-card" class="mat-elevation-z2"><p>Some content</p></div>
//
// For an explanation of the design behind how elevation is implemented, see the design doc at
// https://goo.gl/Kq0k9Z.

// Colors for umbra, penumbra, and ambient shadows. As described in the design doc, each elevation
// level is created using a set of 3 shadow values, one for umbra (the shadow representing the
// space completely obscured by an object relative to its light source), one for penumbra (the
// space partially obscured by an object), and one for ambient (the space which contains the object
// itself). For a further explanation of these terms and their meanings, see
// https://en.wikipedia.org/wiki/Umbra,_penumbra_and_antumbra.

// Maps for the different shadow sets and their values within each z-space. These values were
// created by taking a few reference shadow sets created by Google's Designers and interpolating
// all of the values between them.

@function _get-umbra-map($color, $opacity) {
  $shadow-color: if(type-of($color) == color, rgba($color, $opacity * 0.2), $color);

  @return (
    0: '0px 0px 0px 0px #{$shadow-color}',
    1: '0px 2px 1px -1px #{$shadow-color}',
    2: '0px 3px 1px -2px #{$shadow-color}',
    3: '0px 3px 3px -2px #{$shadow-color}',
    4: '0px 2px 4px -1px #{$shadow-color}',
    5: '0px 3px 5px -1px #{$shadow-color}',
    6: '0px 3px 5px -1px #{$shadow-color}',
    7: '0px 4px 5px -2px #{$shadow-color}',
    8: '0px 5px 5px -3px #{$shadow-color}',
    9: '0px 5px 6px -3px #{$shadow-color}',
    10: '0px 6px 6px -3px #{$shadow-color}',
    11: '0px 6px 7px -4px #{$shadow-color}',
    12: '0px 7px 8px -4px #{$shadow-color}',
    13: '0px 7px 8px -4px #{$shadow-color}',
    14: '0px 7px 9px -4px #{$shadow-color}',
    15: '0px 8px 9px -5px #{$shadow-color}',
    16: '0px 8px 10px -5px #{$shadow-color}',
    17: '0px 8px 11px -5px #{$shadow-color}',
    18: '0px 9px 11px -5px #{$shadow-color}',
    19: '0px 9px 12px -6px #{$shadow-color}',
    20: '0px 10px 13px -6px #{$shadow-color}',
    21: '0px 10px 13px -6px #{$shadow-color}',
    22: '0px 10px 14px -6px #{$shadow-color}',
    23: '0px 11px 14px -7px #{$shadow-color}',
    24: '0px 11px 15px -7px #{$shadow-color}'
  );
}

@function _get-penumbra-map($color, $opacity) {
  $shadow-color: if(type-of($color) == color, rgba($color, $opacity * 0.14), $color);

  @return (
    0: '0px 0px 0px 0px #{$shadow-color}',
    1: '0px 1px 1px 0px #{$shadow-color}',
    2: '0px 2px 2px 0px #{$shadow-color}',
    3: '0px 3px 4px 0px #{$shadow-color}',
    4: '0px 4px 5px 0px #{$shadow-color}',
    5: '0px 5px 8px 0px #{$shadow-color}',
    6: '0px 6px 10px 0px #{$shadow-color}',
    7: '0px 7px 10px 1px #{$shadow-color}',
    8: '0px 8px 10px 1px #{$shadow-color}',
    9: '0px 9px 12px 1px #{$shadow-color}',
    10: '0px 10px 14px 1px #{$shadow-color}',
    11: '0px 11px 15px 1px #{$shadow-color}',
    12: '0px 12px 17px 2px #{$shadow-color}',
    13: '0px 13px 19px 2px #{$shadow-color}',
    14: '0px 14px 21px 2px #{$shadow-color}',
    15: '0px 15px 22px 2px #{$shadow-color}',
    16: '0px 16px 24px 2px #{$shadow-color}',
    17: '0px 17px 26px 2px #{$shadow-color}',
    18: '0px 18px 28px 2px #{$shadow-color}',
    19: '0px 19px 29px 2px #{$shadow-color}',
    20: '0px 20px 31px 3px #{$shadow-color}',
    21: '0px 21px 33px 3px #{$shadow-color}',
    22: '0px 22px 35px 3px #{$shadow-color}',
    23: '0px 23px 36px 3px #{$shadow-color}',
    24: '0px 24px 38px 3px #{$shadow-color}'
  );
}

@function _get-ambient-map($color, $opacity) {
  $shadow-color: if(type-of($color) == color, rgba($color, $opacity * 0.12), $color);

  @return (
    0: '0px 0px 0px 0px #{$shadow-color}',
    1: '0px 1px 3px 0px #{$shadow-color}',
    2: '0px 1px 5px 0px #{$shadow-color}',
    3: '0px 1px 8px 0px #{$shadow-color}',
    4: '0px 1px 10px 0px #{$shadow-color}',
    5: '0px 1px 14px 0px #{$shadow-color}',
    6: '0px 1px 18px 0px #{$shadow-color}',
    7: '0px 2px 16px 1px #{$shadow-color}',
    8: '0px 3px 14px 2px #{$shadow-color}',
    9: '0px 3px 16px 2px #{$shadow-color}',
    10: '0px 4px 18px 3px #{$shadow-color}',
    11: '0px 4px 20px 3px #{$shadow-color}',
    12: '0px 5px 22px 4px #{$shadow-color}',
    13: '0px 5px 24px 4px #{$shadow-color}',
    14: '0px 5px 26px 4px #{$shadow-color}',
    15: '0px 6px 28px 5px #{$shadow-color}',
    16: '0px 6px 30px 5px #{$shadow-color}',
    17: '0px 6px 32px 5px #{$shadow-color}',
    18: '0px 7px 34px 6px #{$shadow-color}',
    19: '0px 7px 36px 6px #{$shadow-color}',
    20: '0px 8px 38px 7px #{$shadow-color}',
    21: '0px 8px 40px 7px #{$shadow-color}',
    22: '0px 8px 42px 7px #{$shadow-color}',
    23: '0px 9px 44px 8px #{$shadow-color}',
    24: '0px 9px 46px 8px #{$shadow-color}'
  );
}

// The default duration value for elevation transitions.
$mat-elevation-transition-duration: 280ms !default;

// The default easing value for elevation transitions.
$mat-elevation-transition-timing-function: $mat-fast-out-slow-in-timing-function;

// The default color for elevation shadows.
$mat-elevation-color: black !default;

// The default opacity scaling value for elevation shadows.
$mat-elevation-opacity: 1 !default;

// Prefix for elevation-related selectors.
$_mat-elevation-prefix: 'mat-elevation-z';

// Applies the correct css rules to an element to give it the elevation specified by $zValue.
// The $zValue must be between 0 and 24.
@mixin mat-elevation($zValue, $color: $mat-elevation-color, $opacity: $mat-elevation-opacity) {
  @if type-of($zValue) != number or not unitless($zValue) {
    @error '$zValue must be a unitless number';
  }
  @if $zValue < 0 or $zValue > 24 {
    @error '$zValue must be between 0 and 24';
  }

  box-shadow: #{map-get(_get-umbra-map($color, $opacity), $zValue)},
              #{map-get(_get-penumbra-map($color, $opacity), $zValue)},
              #{map-get(_get-ambient-map($color, $opacity), $zValue)};
}

@mixin _mat-theme-elevation($zValue, $theme, $opacity: $mat-elevation-opacity) {
  $foreground: map-get($theme, foreground);
  $elevation-color: map-get($foreground, elevation);
  $elevation-color-or-default: if($elevation-color == null, $mat-elevation-color, $elevation-color);

  @include mat-elevation($zValue, $elevation-color-or-default, $opacity);
}

// Applies the elevation to an element in a manner that allows
// consumers to override it via the Material elevation classes.
@mixin mat-overridable-elevation(
    $zValue,
    $color: $mat-elevation-color,
    $opacity: $mat-elevation-opacity) {
  &:not([class*='#{$_mat-elevation-prefix}']) {
    @include mat-elevation($zValue, $color, $opacity);
  }
}

@mixin _mat-theme-overridable-elevation($zValue, $theme, $opacity: $mat-elevation-opacity) {
  $foreground: map-get($theme, foreground);
  $elevation-color: map-get($foreground, elevation);
  $elevation-color-or-default: if($elevation-color == null, $mat-elevation-color, $elevation-color);

  @include mat-overridable-elevation($zValue, $elevation-color-or-default, $opacity);
}

// Returns a string that can be used as the value for a transition property for elevation.
// Calling this function directly is useful in situations where a component needs to transition
// more than one property.
//
// .foo {
//   transition: mat-elevation-transition-property-value(), opacity 100ms ease;
// }
@function mat-elevation-transition-property-value(
    $duration: $mat-elevation-transition-duration,
    $easing: $mat-elevation-transition-timing-function) {
  @return box-shadow #{$duration} #{$easing};
}

// Applies the correct css rules needed to have an element transition between elevations.
// This mixin should be applied to elements whose elevation values will change depending on their
// context (e.g. when active or disabled).
//
// NOTE(traviskaufman): Both this mixin and the above function use default parameters so they can
// be used in the same way by clients.
@mixin mat-elevation-transition(
    $duration: $mat-elevation-transition-duration,
    $easing: $mat-elevation-transition-timing-function) {
  transition: mat-elevation-transition-property-value($duration, $easing);
}

// Color palettes from the Material Design spec.
// See https://material.io/design/color/
//
// Contrast colors are hard-coded because it is too difficult (probably impossible) to
// calculate them. These contrast colors are pulled from the public Material Design spec swatches.
// While the contrast colors in the spec are not prescriptive, we use them for convenience.


// @deprecated renamed to $dark-primary-text.
// @breaking-change 8.0.0
$black-87-opacity: rgba(black, 0.87);
// @deprecated renamed to $light-primary-text.
// @breaking-change 8.0.0
$white-87-opacity: rgba(white, 0.87);
// @deprecated use $dark-[secondary-text,disabled-text,dividers,focused] instead.
// @breaking-change 8.0.0
$black-12-opacity: rgba(black, 0.12);
// @deprecated use $light-[secondary-text,disabled-text,dividers,focused] instead.
// @breaking-change 8.0.0
$white-12-opacity: rgba(white, 0.12);
// @deprecated use $dark-[secondary-text,disabled-text,dividers,focused] instead.
// @breaking-change 8.0.0
$black-6-opacity: rgba(black, 0.06);
// @deprecated use $light-[secondary-text,disabled-text,dividers,focused] instead.
// @breaking-change 8.0.0
$white-6-opacity: rgba(white, 0.06);

$dark-primary-text: rgba(black, 0.87);
$dark-secondary-text: rgba(black, 0.54);
$dark-disabled-text: rgba(black, 0.38);
$dark-dividers: rgba(black, 0.12);
$dark-focused: rgba(black, 0.12);
$light-primary-text: white;
$light-secondary-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);
$light-dividers: rgba(white, 0.12);
$light-focused: rgba(white, 0.12);

$mat-red: (
  50: #ffebee,
  100: #ffcdd2,
  200: #ef9a9a,
  300: #e57373,
  400: #ef5350,
  500: #f44336,
  600: #e53935,
  700: #d32f2f,
  800: #c62828,
  900: #b71c1c,
  A100: #ff8a80,
  A200: #ff5252,
  A400: #ff1744,
  A700: #d50000,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$mat-pink: (
  50: #fce4ec,
  100: #f8bbd0,
  200: #f48fb1,
  300: #f06292,
  400: #ec407a,
  500: #e91e63,
  600: #d81b60,
  700: #c2185b,
  800: #ad1457,
  900: #880e4f,
  A100: #ff80ab,
  A200: #ff4081,
  A400: #f50057,
  A700: #c51162,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$mat-purple: (
  50: #f3e5f5,
  100: #e1bee7,
  200: #ce93d8,
  300: #ba68c8,
  400: #ab47bc,
  500: #9c27b0,
  600: #8e24aa,
  700: #7b1fa2,
  800: #6a1b9a,
  900: #4a148c,
  A100: #ea80fc,
  A200: #e040fb,
  A400: #d500f9,
  A700: #aa00ff,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$mat-deep-purple: (
  50: #ede7f6,
  100: #d1c4e9,
  200: #b39ddb,
  300: #9575cd,
  400: #7e57c2,
  500: #673ab7,
  600: #5e35b1,
  700: #512da8,
  800: #4527a0,
  900: #311b92,
  A100: #b388ff,
  A200: #7c4dff,
  A400: #651fff,
  A700: #6200ea,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$mat-indigo: (
  50: #e8eaf6,
  100: #c5cae9,
  200: #9fa8da,
  300: #7986cb,
  400: #5c6bc0,
  500: #3f51b5,
  600: #3949ab,
  700: #303f9f,
  800: #283593,
  900: #1a237e,
  A100: #8c9eff,
  A200: #536dfe,
  A400: #3d5afe,
  A700: #304ffe,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$mat-blue: (
  50: #e3f2fd,
  100: #bbdefb,
  200: #90caf9,
  300: #64b5f6,
  400: #42a5f5,
  500: #2196f3,
  600: #1e88e5,
  700: #1976d2,
  800: #1565c0,
  900: #0d47a1,
  A100: #82b1ff,
  A200: #448aff,
  A400: #2979ff,
  A700: #2962ff,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$mat-light-blue: (
  50: #e1f5fe,
  100: #b3e5fc,
  200: #81d4fa,
  300: #4fc3f7,
  400: #29b6f6,
  500: #03a9f4,
  600: #039be5,
  700: #0288d1,
  800: #0277bd,
  900: #01579b,
  A100: #80d8ff,
  A200: #40c4ff,
  A400: #00b0ff,
  A700: #0091ea,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $light-primary-text,
  )
);

$mat-cyan: (
  50: #e0f7fa,
  100: #b2ebf2,
  200: #80deea,
  300: #4dd0e1,
  400: #26c6da,
  500: #00bcd4,
  600: #00acc1,
  700: #0097a7,
  800: #00838f,
  900: #006064,
  A100: #84ffff,
  A200: #18ffff,
  A400: #00e5ff,
  A700: #00b8d4,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  )
);

$mat-teal: (
  50: #e0f2f1,
  100: #b2dfdb,
  200: #80cbc4,
  300: #4db6ac,
  400: #26a69a,
  500: #009688,
  600: #00897b,
  700: #00796b,
  800: #00695c,
  900: #004d40,
  A100: #a7ffeb,
  A200: #64ffda,
  A400: #1de9b6,
  A700: #00bfa5,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  )
);

$mat-green: (
  50: #e8f5e9,
  100: #c8e6c9,
  200: #a5d6a7,
  300: #81c784,
  400: #66bb6a,
  500: #4caf50,
  600: #43a047,
  700: #388e3c,
  800: #2e7d32,
  900: #1b5e20,
  A100: #b9f6ca,
  A200: #69f0ae,
  A400: #00e676,
  A700: #00c853,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  )
);

$mat-light-green: (
  50: #f1f8e9,
  100: #dcedc8,
  200: #c5e1a5,
  300: #aed581,
  400: #9ccc65,
  500: #8bc34a,
  600: #7cb342,
  700: #689f38,
  800: #558b2f,
  900: #33691e,
  A100: #ccff90,
  A200: #b2ff59,
  A400: #76ff03,
  A700: #64dd17,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  )
);

$mat-lime: (
  50: #f9fbe7,
  100: #f0f4c3,
  200: #e6ee9c,
  300: #dce775,
  400: #d4e157,
  500: #cddc39,
  600: #c0ca33,
  700: #afb42b,
  800: #9e9d24,
  900: #827717,
  A100: #f4ff81,
  A200: #eeff41,
  A400: #c6ff00,
  A700: #aeea00,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $dark-primary-text,
    800: $dark-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  )
);

$mat-yellow: (
  50: #fffde7,
  100: #fff9c4,
  200: #fff59d,
  300: #fff176,
  400: #ffee58,
  500: #ffeb3b,
  600: #fdd835,
  700: #fbc02d,
  800: #f9a825,
  900: #f57f17,
  A100: #ffff8d,
  A200: #ffff00,
  A400: #ffea00,
  A700: #ffd600,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $dark-primary-text,
    800: $dark-primary-text,
    900: $dark-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  )
);

$mat-amber: (
  50: #fff8e1,
  100: #ffecb3,
  200: #ffe082,
  300: #ffd54f,
  400: #ffca28,
  500: #ffc107,
  600: #ffb300,
  700: #ffa000,
  800: #ff8f00,
  900: #ff6f00,
  A100: #ffe57f,
  A200: #ffd740,
  A400: #ffc400,
  A700: #ffab00,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $dark-primary-text,
    800: $dark-primary-text,
    900: $dark-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  )
);

$mat-orange: (
  50: #fff3e0,
  100: #ffe0b2,
  200: #ffcc80,
  300: #ffb74d,
  400: #ffa726,
  500: #ff9800,
  600: #fb8c00,
  700: #f57c00,
  800: #ef6c00,
  900: #e65100,
  A100: #ffd180,
  A200: #ffab40,
  A400: #ff9100,
  A700: #ff6d00,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $dark-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: black,
  )
);

$mat-deep-orange: (
  50: #fbe9e7,
  100: #ffccbc,
  200: #ffab91,
  300: #ff8a65,
  400: #ff7043,
  500: #ff5722,
  600: #f4511e,
  700: #e64a19,
  800: #d84315,
  900: #bf360c,
  A100: #ff9e80,
  A200: #ff6e40,
  A400: #ff3d00,
  A700: #dd2c00,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$mat-brown: (
  50: #efebe9,
  100: #d7ccc8,
  200: #bcaaa4,
  300: #a1887f,
  400: #8d6e63,
  500: #795548,
  600: #6d4c41,
  700: #5d4037,
  800: #4e342e,
  900: #3e2723,
  A100: #d7ccc8,
  A200: #bcaaa4,
  A400: #8d6e63,
  A700: #5d4037,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$mat-grey: (
  50: #fafafa,
  100: #f5f5f5,
  200: #eeeeee,
  300: #e0e0e0,
  400: #bdbdbd,
  500: #9e9e9e,
  600: #757575,
  700: #616161,
  800: #424242,
  900: #212121,
  A100: #ffffff,
  A200: #eeeeee,
  A400: #bdbdbd,
  A700: #616161,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $light-primary-text,
  )
);

// Alias for alternate spelling.
$mat-gray: $mat-grey;

$mat-blue-grey: (
  50: #eceff1,
  100: #cfd8dc,
  200: #b0bec5,
  300: #90a4ae,
  400: #78909c,
  500: #607d8b,
  600: #546e7a,
  700: #455a64,
  800: #37474f,
  900: #263238,
  A100: #cfd8dc,
  A200: #b0bec5,
  A400: #78909c,
  A700: #455a64,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

// Alias for alternate spelling.
$mat-blue-gray: $mat-blue-grey;


// Background palette for light themes.
$mat-light-theme-background: (
  status-bar: map_get($mat-grey, 300),
  app-bar:    map_get($mat-grey, 100),
  background: map_get($mat-grey, 50),
  hover:      rgba(black, 0.04), // TODO(kara): check style with Material Design UX
  card:       white,
  dialog:     white,
  disabled-button: rgba(black, 0.12),
  raised-button: white,
  focused-button: $dark-focused,
  selected-button: map_get($mat-grey, 300),
  selected-disabled-button: map_get($mat-grey, 400),
  disabled-button-toggle: map_get($mat-grey, 200),
  unselected-chip: map_get($mat-grey, 300),
  disabled-list-option: map_get($mat-grey, 200),
);

// Background palette for dark themes.
$mat-dark-theme-background: (
  status-bar: black,
  app-bar:    map_get($mat-grey, 900),
  background: #303030,
  hover:      rgba(white, 0.04), // TODO(kara): check style with Material Design UX
  card:       map_get($mat-grey, 800),
  dialog:     map_get($mat-grey, 800),
  disabled-button: rgba(white, 0.12),
  raised-button: map-get($mat-grey, 800),
  focused-button: $light-focused,
  selected-button: map_get($mat-grey, 900),
  selected-disabled-button: map_get($mat-grey, 800),
  disabled-button-toggle: black,
  unselected-chip: map_get($mat-grey, 700),
  disabled-list-option: black,
);

// Foreground palette for light themes.
$mat-light-theme-foreground: (
  base:              black,
  divider:           $dark-dividers,
  dividers:          $dark-dividers,
  disabled:          $dark-disabled-text,
  disabled-button:   rgba(black, 0.26),
  disabled-text:     $dark-disabled-text,
  elevation:         black,
  hint-text:         $dark-disabled-text,
  secondary-text:    $dark-secondary-text,
  icon:              rgba(black, 0.54),
  icons:             rgba(black, 0.54),
  text:              rgba(black, 0.87),
  slider-min:        rgba(black, 0.87),
  slider-off:        rgba(black, 0.26),
  slider-off-active: rgba(black, 0.38),
);

// Foreground palette for dark themes.
$mat-dark-theme-foreground: (
  base:              white,
  divider:           $light-dividers,
  dividers:          $light-dividers,
  disabled:          $light-disabled-text,
  disabled-button:   rgba(white, 0.3),
  disabled-text:     $light-disabled-text,
  elevation:         black,
  hint-text:         $light-disabled-text,
  secondary-text:    $light-secondary-text,
  icon:              white,
  icons:             white,
  text:              white,
  slider-min:        white,
  slider-off:        rgba(white, 0.3),
  slider-off-active: rgba(white, 0.3),
);



// For a given hue in a palette, return the contrast color from the map of contrast palettes.
// @param $color-map
// @param $hue
@function mat-contrast($palette, $hue) {
  @return map-get(map-get($palette, contrast), $hue);
}


// Creates a map of hues to colors for a theme. This is used to define a theme palette in terms
// of the Material Design hues.
// @param $color-map
// @param $primary
// @param $lighter
@function mat-palette($base-palette, $default: 500, $lighter: 100, $darker: 700, $text: $default) {
  $result: map_merge($base-palette, (
    default: map-get($base-palette, $default),
    lighter: map-get($base-palette, $lighter),
    darker: map-get($base-palette, $darker),
    text: map-get($base-palette, $text),

    default-contrast: mat-contrast($base-palette, $default),
    lighter-contrast: mat-contrast($base-palette, $lighter),
    darker-contrast: mat-contrast($base-palette, $darker)
  ));

  // For each hue in the palette, add a "-contrast" color to the map.
  @each $hue, $color in $base-palette {
    $result: map_merge($result, (
      '#{$hue}-contrast': mat-contrast($base-palette, $hue)
    ));
  }

  @return $result;
}


// Gets a color from a theme palette (the output of mat-palette).
// The hue can be one of the standard values (500, A400, etc.), one of the three preconfigured
// hues (default, lighter, darker), or any of the aforementioned prefixed with "-contrast".
//
// @param $color-map The theme palette (output of mat-palette).
// @param $hue The hue from the palette to use. If this is a value between 0 and 1, it will
//     be treated as opacity.
// @param $opacity The alpha channel value for the color.
@function mat-color($palette, $hue: default, $opacity: null) {
  // If hueKey is a number between zero and one, then it actually contains an
  // opacity value, so recall this function with the default hue and that given opacity.
  @if type-of($hue) == number and $hue >= 0 and $hue <= 1 {
    @return mat-color($palette, default, $hue);
  }

  $color: map-get($palette, $hue);

  @if (type-of($color) != color) {
    // If the $color resolved to something different from a color (e.g. a CSS variable),
    // we can't apply the opacity anyway so we return the value as is, otherwise Sass can
    // throw an error or output something invalid.
    @return $color;
  }

  @return rgba($color, if($opacity == null, opacity($color), $opacity));
}


// Creates a container object for a light theme to be given to individual component theme mixins.
@function mat-light-theme($primary, $accent, $warn: mat-palette($mat-red)) {
  @return (
    primary: $primary,
    accent: $accent,
    warn: $warn,
    is-dark: false,
    foreground: $mat-light-theme-foreground,
    background: $mat-light-theme-background,
  );
}


// Creates a container object for a dark theme to be given to individual component theme mixins.
@function mat-dark-theme($primary, $accent, $warn: mat-palette($mat-red)) {
  @return (
    primary: $primary,
    accent: $accent,
    warn: $warn,
    is-dark: true,
    foreground: $mat-dark-theme-foreground,
    background: $mat-dark-theme-background,
  );
}



$mat-ripple-color-opacity: 0.1;

@mixin mat-ripple() {

  // The host element of an mat-ripple directive should always have a position of "absolute" or
  // "relative" so that the ripples inside are correctly positioned relatively to the container.
  .mat-ripple {
    overflow: hidden;

    // By default, every ripple container should have position: relative in favor of creating an
    // easy API for developers using the MatRipple directive.
    position: relative;
  }

  .mat-ripple.mat-ripple-unbounded {
    overflow: visible;
  }

  .mat-ripple-element {
    position: absolute;
    border-radius: 50%;
    pointer-events: none;

    transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
    transform: scale(0);

    // In high contrast mode the ripple is opaque, causing it to obstruct the content.
    @include cdk-high-contrast {
      display: none;
    }
  }
}

/* Theme for the ripple elements.*/
@mixin mat-ripple-theme($theme) {
  $foreground: map_get($theme, foreground);
  $foreground-base: map_get($foreground, base);

  .mat-ripple-element {
    // If the ripple color is resolves to a color *type*, we can use it directly, otherwise
    // (e.g. it resolves to a CSS variable) we fall back to using the color and setting an opacity.
    @if (type-of($foreground-base) == color) {
      background-color: rgba($foreground-base, $mat-ripple-color-opacity);
    }
    @else {
      background-color: $foreground-base;
      opacity: $mat-ripple-color-opacity;
    }
  }
}



// Utility for fetching a nested value from a typography config.
@function _mat-get-type-value($config, $level, $name) {
  @return map-get(map-get($config, $level), $name);
}

// Gets the font size for a level inside a typography config.
@function mat-font-size($config, $level) {
  @return _mat-get-type-value($config, $level, font-size);
}

// Gets the line height for a level inside a typography config.
@function mat-line-height($config, $level) {
  @return _mat-get-type-value($config, $level, line-height);
}

// Gets the font weight for a level inside a typography config.
@function mat-font-weight($config, $level) {
  @return _mat-get-type-value($config, $level, font-weight);
}

// Gets the letter spacing for a level inside a typography config.
@function mat-letter-spacing($config, $level) {
  @return _mat-get-type-value($config, $level, letter-spacing);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function mat-font-family($config, $level: null) {
  $font-family: map-get($config, font-family);

  @if $level != null {
    $font-family: _mat-get-type-value($config, $level, font-family);
  }

  // Guard against unquoting non-string values, because it's deprecated.
  @return if(type-of($font-family) == string, unquote($font-family), $font-family);
}

// Outputs the shorthand `font` CSS property, based on a set of typography values. Falls back to
// the individual properties if a value that isn't allowed in the shorthand is passed in.
@mixin mat-typography-font-shorthand($font-size, $font-weight, $line-height, $font-family) {
  // If any of the values are set to `inherit`, we can't use the shorthand
  // so we fall back to passing in the individual properties.
  @if ($font-size == inherit or
       $font-weight == inherit or
       $line-height == inherit or
       $font-family == inherit or
       $font-size == null or
       $font-weight == null or
       $line-height == null or
       $font-family == null) {

    font-size: $font-size;
    font-weight: $font-weight;
    line-height: $line-height;
    font-family: $font-family;
  }
  @else {
    // Otherwise use the shorthand `font`, because it's the least amount of bytes. Note
    // that we need to use interpolation for `font-size/line-height` in order to prevent
    // Sass from dividing the two values.
    font: $font-weight #{$font-size}/#{$line-height} $font-family;
  }
}

// Converts a typography level into CSS styles.
@mixin mat-typography-level-to-styles($config, $level) {
  $font-size: mat-font-size($config, $level);
  $font-weight: mat-font-weight($config, $level);
  $line-height: mat-line-height($config, $level);
  $font-family: mat-font-family($config, $level);

  @include mat-typography-font-shorthand($font-size, $font-weight, $line-height, $font-family);
  letter-spacing: mat-letter-spacing($config, $level);
}


@mixin mat-option-theme($theme) {
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .mat-option {
    color: mat-color($foreground, text);

    &:hover:not(.mat-option-disabled),
    &:focus:not(.mat-option-disabled) {
      background: mat-color($background, hover);
    }

    // In multiple mode there is a checkbox to show that the option is selected.
    &.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
      background: mat-color($background, hover);
    }

    &.mat-active {
      background: mat-color($background, hover);
      color: mat-color($foreground, text);
    }

    &.mat-option-disabled {
      color: mat-color($foreground, hint-text);
    }
  }

  .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: mat-color($primary, text);
  }

  .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
    color: mat-color($accent, text);
  }

  .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
    color: mat-color($warn, text);
  }
}

@mixin mat-option-typography($config) {
  .mat-option {
    font: {
      family: mat-font-family($config);
      size: mat-font-size($config, subheading-2);
    }
  }
}





@mixin mat-optgroup-theme($theme) {
  $foreground: map-get($theme, foreground);

  .mat-optgroup-label {
    color: mat-color($foreground, secondary-text);
  }

  .mat-optgroup-disabled .mat-optgroup-label {
    color: mat-color($foreground, hint-text);
  }
}

@mixin mat-optgroup-typography($config) {
  .mat-optgroup-label {
    @include mat-typography-level-to-styles($config, body-2);
  }
}



@mixin mat-pseudo-checkbox-theme($theme) {
  $is-dark-theme: map-get($theme, is-dark);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);

  // NOTE(traviskaufman): While the spec calls for translucent blacks/whites for disabled colors,
  // this does not work well with elements layered on top of one another. To get around this we
  // blend the colors together based on the base color and the theme background.
  $white-30pct-opacity-on-dark: #686868;
  $black-26pct-opacity-on-light: #b0b0b0;
  $disabled-color: if($is-dark-theme, $white-30pct-opacity-on-dark, $black-26pct-opacity-on-light);
  $colored-box-selector: '.mat-pseudo-checkbox-checked, .mat-pseudo-checkbox-indeterminate';

  .mat-pseudo-checkbox {
    color: mat-color(map-get($theme, foreground), secondary-text);

    &::after {
      color: mat-color($background, background);
    }
  }

  .mat-pseudo-checkbox-disabled {
    color: $disabled-color;
  }

  // Default to the accent color. Note that the pseudo checkboxes are meant to inherit the
  // theme from their parent, rather than implementing their own theming, which is why we
  // don't attach to the `mat-*` classes.
  .mat-pseudo-checkbox-checked,
  .mat-pseudo-checkbox-indeterminate,
  .mat-accent .mat-pseudo-checkbox-checked,
  .mat-accent .mat-pseudo-checkbox-indeterminate {
    background: mat-color(map-get($theme, accent));
  }

  .mat-primary .mat-pseudo-checkbox-checked,
  .mat-primary .mat-pseudo-checkbox-indeterminate {
    background: mat-color(map-get($theme, primary));
  }

  .mat-warn .mat-pseudo-checkbox-checked,
  .mat-warn .mat-pseudo-checkbox-indeterminate {
    background: mat-color(map-get($theme, warn));
  }

  .mat-pseudo-checkbox-checked,
  .mat-pseudo-checkbox-indeterminate {
    &.mat-pseudo-checkbox-disabled {
      background: $disabled-color;
    }
  }
}



// Represents a typography level from the Material design spec.
@function mat-typography-level(
  $font-size,
  $line-height: $font-size,
  $font-weight: 400,
  $font-family: null,
  $letter-spacing: null) {

  @return (
    font-size: $font-size,
    line-height: $line-height,
    font-weight: $font-weight,
    font-family: $font-family,
    letter-spacing: $letter-spacing
  );
}

// Represents a collection of typography levels.
// Defaults come from https://material.io/guidelines/style/typography.html
// Note: The spec doesn't mention letter spacing. The values here come from
// eyeballing it until it looked exactly like the spec examples.
@function mat-typography-config(
  $font-family:   'Roboto, "Helvetica Neue", sans-serif',
  $display-4:     mat-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
  $display-3:     mat-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
  $display-2:     mat-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
  $display-1:     mat-typography-level(34px, 40px, 400),
  $headline:      mat-typography-level(24px, 32px, 400),
  $title:         mat-typography-level(20px, 32px, 500),
  $subheading-2:  mat-typography-level(16px, 28px, 400),
  $subheading-1:  mat-typography-level(15px, 24px, 400),
  $body-2:        mat-typography-level(14px, 24px, 500),
  $body-1:        mat-typography-level(14px, 20px, 400),
  $caption:       mat-typography-level(12px, 20px, 400),
  $button:        mat-typography-level(14px, 14px, 500),
  // Line-height must be unit-less fraction of the font-size.
  $input:         mat-typography-level(inherit, 1.125, 400)
) {

  // Declare an initial map with all of the levels.
  $config: (
    display-4:      $display-4,
    display-3:      $display-3,
    display-2:      $display-2,
    display-1:      $display-1,
    headline:       $headline,
    title:          $title,
    subheading-2:   $subheading-2,
    subheading-1:   $subheading-1,
    body-2:         $body-2,
    body-1:         $body-1,
    caption:        $caption,
    button:         $button,
    input:          $input,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      $new-level: map-merge($level, (font-family: $font-family));
      $config: map-merge($config, ($key: $new-level));
    }
  }

  // Add the base font family to the config.
  @return map-merge($config, (font-family: $font-family));
}

// Adds the base typography styles, based on a config.
@mixin mat-base-typography($config, $selector: '.mat-typography') {
  .mat-h1, .mat-headline, #{$selector} h1 {
    @include mat-typography-level-to-styles($config, headline);
    margin: 0 0 16px;
  }

  .mat-h2, .mat-title, #{$selector} h2 {
    @include mat-typography-level-to-styles($config, title);
    margin: 0 0 16px;
  }

  .mat-h3, .mat-subheading-2, #{$selector} h3 {
    @include mat-typography-level-to-styles($config, subheading-2);
    margin: 0 0 16px;
  }

  .mat-h4, .mat-subheading-1, #{$selector} h4 {
    @include mat-typography-level-to-styles($config, subheading-1);
    margin: 0 0 16px;
  }

  // Note: the spec doesn't have anything that would correspond to h5 and h6, but we add these for
  // consistency. The font sizes come from the Chrome user agent styles which have h5 at 0.83em
  // and h6 at 0.67em.
  .mat-h5, #{$selector} h5 {
    @include mat-typography-font-shorthand(
       // calc is used here to support css variables
      calc(#{mat-font-size($config, body-1)} * 0.83),
      mat-font-weight($config, body-1),
      mat-line-height($config, body-1),
      mat-font-family($config, body-1)
    );

    margin: 0 0 12px;
  }

  .mat-h6, #{$selector} h6 {
    @include mat-typography-font-shorthand(
       // calc is used here to support css variables
      calc(#{mat-font-size($config, body-1)} * 0.67),
      mat-font-weight($config, body-1),
      mat-line-height($config, body-1),
      mat-font-family($config, body-1)
    );

    margin: 0 0 12px;
  }

  .mat-body-strong, .mat-body-2 {
    @include mat-typography-level-to-styles($config, body-2);
  }

  .mat-body, .mat-body-1, #{$selector} {
    @include mat-typography-level-to-styles($config, body-1);

    p {
      margin: 0 0 12px;
    }
  }

  .mat-small, .mat-caption {
    @include mat-typography-level-to-styles($config, caption);
  }

  .mat-display-4, #{$selector} .mat-display-4 {
    @include mat-typography-level-to-styles($config, display-4);
    margin: 0 0 56px;
  }

  .mat-display-3, #{$selector} .mat-display-3 {
    @include mat-typography-level-to-styles($config, display-3);
    margin: 0 0 64px;
  }

  .mat-display-2, #{$selector} .mat-display-2 {
    @include mat-typography-level-to-styles($config, display-2);
    margin: 0 0 64px;
  }

  .mat-display-1, #{$selector} .mat-display-1 {
    @include mat-typography-level-to-styles($config, display-1);
    margin: 0 0 64px;
  }
}




@mixin mat-autocomplete-theme($theme) {
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  .mat-autocomplete-panel {
    @include _mat-theme-overridable-elevation(4, $theme);
    background: mat-color($background, card);
    color: mat-color($foreground, text);

    // Selected options in autocompletes should not be gray, but we
    // only want to override the background for selected options if
    // they are *not* in hover or focus state. This change has to be
    // made here because base option styles are shared between the
    // autocomplete and the select.
    .mat-option.mat-selected:not(.mat-active):not(:hover) {
      background: mat-color($background, card);

      &:not(.mat-option-disabled) {
        color: mat-color($foreground, text);
      }
    }
  }

}

@mixin mat-autocomplete-typography($config) { }

// This contains all of the styles for the badge
// rather than just the color/theme because of
// no style sheet support for directives.





$mat-badge-font-size: 12px;
$mat-badge-font-weight: 600;
$mat-badge-default-size: 22px !default;
$mat-badge-small-size: $mat-badge-default-size - 6;
$mat-badge-large-size: $mat-badge-default-size + 6;

// Mixin for building offset given different sizes
@mixin _mat-badge-size($size) {
  .mat-badge-content {
    width: $size;
    height: $size;
    line-height: $size;
  }

  &.mat-badge-above {
    .mat-badge-content {
      top: -$size / 2;
    }
  }

  &.mat-badge-below {
    .mat-badge-content {
      bottom: -$size / 2;
    }
  }

  &.mat-badge-before {
    .mat-badge-content {
      left: -$size;
    }
  }

  [dir='rtl'] &.mat-badge-before {
    .mat-badge-content {
      left: auto;
      right: -$size;
    }
  }

  &.mat-badge-after {
    .mat-badge-content {
      right: -$size;
    }
  }

  [dir='rtl'] &.mat-badge-after {
    .mat-badge-content {
      right: auto;
      left: -$size;
    }
  }

  &.mat-badge-overlap {
    &.mat-badge-before {
      .mat-badge-content {
        left: -$size / 2;
      }
    }

    [dir='rtl'] &.mat-badge-before {
      .mat-badge-content {
        left: auto;
        right: -$size / 2;
      }
    }

    &.mat-badge-after {
      .mat-badge-content {
        right: -$size / 2;
      }
    }

    [dir='rtl'] &.mat-badge-after {
      .mat-badge-content {
        right: auto;
        left: -$size / 2;
      }
    }
  }
}

@mixin mat-badge-theme($theme) {
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $primary: map-get($theme, primary);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .mat-badge-content {
    color: mat-color($primary, default-contrast);
    background: mat-color($primary);

    @include cdk-high-contrast {
      outline: solid 1px;
      border-radius: 0;
    }
  }

  .mat-badge-accent {
    .mat-badge-content {
      background: mat-color($accent);
      color: mat-color($accent, default-contrast);
    }
  }

  .mat-badge-warn {
    .mat-badge-content {
      color: mat-color($warn, default-contrast);
      background: mat-color($warn);
    }
  }

  .mat-badge {
    position: relative;
  }

  .mat-badge-hidden {
    .mat-badge-content {
      display: none;
    }
  }

  .mat-badge-disabled {
    .mat-badge-content {
      $app-background: mat-color($background, 'background');
      $badge-color: mat-color($foreground, disabled-button);

      // The disabled color usually has some kind of opacity, but because the badge is overlayed
      // on top of something else, it won't look good if it's opaque. If it is a color *type*,
      // we convert it into a solid color by taking the opacity from the rgba value and using
      // the value to determine the percentage of the background to put into foreground when
      // mixing the colors together.
      @if (type-of($badge-color) == color and type-of($app-background) == color) {
        $badge-opacity: opacity($badge-color);
        background: mix($app-background, rgba($badge-color, 1), (1 - $badge-opacity) * 100%);
      }
      @else {
        background: $badge-color;
      }

      color: mat-color($foreground, disabled-text);
    }
  }

  .mat-badge-content {
    position: absolute;
    text-align: center;
    display: inline-block;
    border-radius: 50%;
    transition: transform 200ms ease-in-out;
    transform: scale(0.6);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    pointer-events: none;
  }

  .ng-animate-disabled .mat-badge-content,
  .mat-badge-content._mat-animation-noopable {
    transition: none;
  }

  // The active class is added after the element is added
  // so it can animate scale to default
  .mat-badge-content.mat-badge-active {
    // Scale to `none` instead of `1` to avoid blurry text in some browsers.
    transform: none;
  }

  .mat-badge-small {
    @include _mat-badge-size($mat-badge-small-size);
  }
  .mat-badge-medium {
    @include _mat-badge-size($mat-badge-default-size);
  }
  .mat-badge-large {
    @include _mat-badge-size($mat-badge-large-size);
  }
}

@mixin mat-badge-typography($config) {
  .mat-badge-content {
    font-weight: $mat-badge-font-weight;
    font-size: $mat-badge-font-size;
    font-family: mat-font-family($config);
  }

  .mat-badge-small .mat-badge-content {
    // Set the font size to 75% of the original.
    font-size: $mat-badge-font-size * 0.75;
  }

  .mat-badge-large .mat-badge-content {
    font-size: $mat-badge-font-size * 2;
  }
}





@mixin mat-bottom-sheet-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .mat-bottom-sheet-container {
    @include _mat-theme-elevation(16, $theme);
    background: mat-color($background, dialog);
    color: mat-color($foreground, text);
  }
}

@mixin mat-bottom-sheet-typography($config) {
  .mat-bottom-sheet-container {
    @include mat-typography-level-to-styles($config, body-1);
  }
}





$_mat-button-ripple-opacity: 0.1;

// Applies a focus style to an mat-button element for each of the supported palettes.
@mixin _mat-button-focus-overlay-color($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  &.mat-primary .mat-button-focus-overlay {
    background-color: mat-color($primary);
  }

  &.mat-accent .mat-button-focus-overlay {
    background-color: mat-color($accent);
  }

  &.mat-warn .mat-button-focus-overlay {
    background-color: mat-color($warn);
  }

  &[disabled] .mat-button-focus-overlay {
    background-color: transparent;
  }
}

// Applies the background color for a ripple. If the value provided is not a Sass color,
// we assume that we've been given a CSS variable. Since we can't perform alpha-blending
// on a CSS variable, we instead add the opacity directly to the ripple element.
@mixin _mat-button-ripple-background($palette, $hue, $opacity) {
  $background-color: mat-color($palette, $hue, $opacity);
  background-color: $background-color;
  @if (type-of($background-color) != color) {
    opacity: $opacity;
  }
}

@mixin _mat-button-ripple-color($theme, $hue, $opacity: $_mat-button-ripple-opacity) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  &.mat-primary .mat-ripple-element {
    @include _mat-button-ripple-background($primary, $hue, $opacity);
  }

  &.mat-accent .mat-ripple-element {
    @include _mat-button-ripple-background($accent, $hue, $opacity);
  }

  &.mat-warn .mat-ripple-element {
    @include _mat-button-ripple-background($warn, $hue, $opacity);
  }
}

// Applies a property to an mat-button element for each of the supported palettes.
@mixin _mat-button-theme-property($theme, $property, $hue) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  &.mat-primary {
    #{$property}: mat-color($primary, $hue);
  }
  &.mat-accent {
    #{$property}: mat-color($accent, $hue);
  }
  &.mat-warn {
    #{$property}: mat-color($warn, $hue);
  }

  &.mat-primary, &.mat-accent, &.mat-warn, &[disabled] {
    &[disabled] {
      $palette: if($property == 'color', $foreground, $background);
      #{$property}: mat-color($palette, disabled-button);
    }
  }
}

@mixin mat-button-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .mat-button, .mat-icon-button, .mat-stroked-button {
    // Buttons without a background color should inherit the font color. This is necessary to
    // ensure that the button is readable on custom background colors. It's wrong to always assume
    // that those buttons are always placed inside of containers with the default background
    // color of the theme (e.g. themed toolbars).
    color: inherit;
    background: transparent;

    @include _mat-button-theme-property($theme, 'color', text);
    @include _mat-button-focus-overlay-color($theme);

    // Setup the ripple color to be based on the text color. This ensures that the ripples
    // are matching with the current theme palette and are in contrast to the background color
    // (e.g in themed toolbars).
    .mat-ripple-element {
      opacity: $_mat-button-ripple-opacity;
      background-color: currentColor;
    }
  }

  .mat-button-focus-overlay {
    background: map_get($foreground, base);
  }

  // Note: this needs a bit extra specificity, because we're not guaranteed the inclusion
  // order of the theme styles and the button reset may end up resetting this as well.
  .mat-stroked-button:not([disabled]) {
    border-color: mat-color($foreground, divider);
  }

  .mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
    // Default font and background color when not using any color palette.
    color: mat-color($foreground, text);
    background-color: mat-color($background, raised-button);

    @include _mat-button-theme-property($theme, 'color', default-contrast);
    @include _mat-button-theme-property($theme, 'background-color', default);
    @include _mat-button-ripple-color($theme, default-contrast);
  }

  .mat-stroked-button, .mat-flat-button {
    @include _mat-theme-overridable-elevation(0, $theme);
  }

  .mat-raised-button {
    @include _mat-theme-overridable-elevation(2, $theme);

    &:not([disabled]):active {
      @include _mat-theme-overridable-elevation(8, $theme);
    }

    &[disabled] {
      @include _mat-theme-overridable-elevation(0, $theme);
    }
  }

  .mat-fab, .mat-mini-fab {
    @include _mat-theme-overridable-elevation(6, $theme);

    &:not([disabled]):active {
      @include _mat-theme-overridable-elevation(12, $theme);
    }

    &[disabled] {
      @include _mat-theme-overridable-elevation(0, $theme);
    }
  }
}

@mixin mat-button-typography($config) {
  .mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
  .mat-flat-button, .mat-fab, .mat-mini-fab {
    font: {
      family: mat-font-family($config, button);
      size: mat-font-size($config, button);
      weight: mat-font-weight($config, button);
    }
  }
}






@mixin mat-button-toggle-theme($theme) {
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);
  $divider-color: mat-color($foreground, divider);

  .mat-button-toggle-standalone,
  .mat-button-toggle-group {
    @include _mat-theme-elevation(2, $theme);
  }

  .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
  .mat-button-toggle-group-appearance-standard {
    box-shadow: none;
  }

  .mat-button-toggle {
    color: mat-color($foreground, hint-text);

    .mat-button-toggle-focus-overlay {
      background-color: mat-color($background, focused-button);
    }
  }

  .mat-button-toggle-appearance-standard {
    color: mat-color($foreground, text);
    background: mat-color($background, card);

    .mat-button-toggle-focus-overlay {
      background-color: mat-color($background, focused-button, 1);
    }
  }

  .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
    border-left: solid 1px $divider-color;
  }

  [dir='rtl'] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
    border-left: none;
    border-right: solid 1px $divider-color;
  }

  .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical {
    .mat-button-toggle + .mat-button-toggle {
      border-left: none;
      border-right: none;
      border-top: solid 1px $divider-color;
    }
  }

  .mat-button-toggle-checked {
    background-color: mat-color($background, selected-button);
    color: mat-color($foreground, secondary-text);

    &.mat-button-toggle-appearance-standard {
      color: mat-color($foreground, text);
    }
  }

  .mat-button-toggle-disabled {
    color: mat-color($foreground, disabled-button);
    background-color: mat-color($background, disabled-button-toggle);

    &.mat-button-toggle-appearance-standard {
      background: mat-color($background, card);
    }

    &.mat-button-toggle-checked {
      background-color: mat-color($background, selected-disabled-button);
    }
  }

  .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
  .mat-button-toggle-group-appearance-standard {
    border: solid 1px $divider-color;
  }
}

@mixin mat-button-toggle-typography($config) {
  .mat-button-toggle {
    font-family: mat-font-family($config);
  }
}







@mixin mat-card-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .mat-card {
    @include _mat-theme-overridable-elevation(1, $theme);
    background: mat-color($background, card);
    color: mat-color($foreground, text);

    // Needs extra specificity to be able to override the elevation selectors.
    &.mat-card-flat {
      @include _mat-theme-overridable-elevation(0, $theme);
    }
  }

  .mat-card-subtitle {
    color: mat-color($foreground, secondary-text);
  }
}

@mixin mat-card-typography($config) {
  .mat-card {
    font-family: mat-font-family($config);
  }

  .mat-card-title {
    font: {
      size: mat-font-size($config, headline);
      weight: mat-font-weight($config, title);
    }
  }

  .mat-card-header .mat-card-title {
    font-size: mat-font-size($config, title);
  }

  .mat-card-subtitle,
  .mat-card-content {
    font-size: mat-font-size($config, body-1);
  }
}






@mixin mat-checkbox-theme($theme) {
  $is-dark-theme: map-get($theme, is-dark);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);


  // The color of the checkbox's checkmark / mixedmark.
  $checkbox-mark-color: mat-color($background, background);

  // NOTE(traviskaufman): While the spec calls for translucent blacks/whites for disabled colors,
  // this does not work well with elements layered on top of one another. To get around this we
  // blend the colors together based on the base color and the theme background.
  $white-30pct-opacity-on-dark: #686868;
  $black-26pct-opacity-on-light: #b0b0b0;
  $disabled-color: if($is-dark-theme, $white-30pct-opacity-on-dark, $black-26pct-opacity-on-light);

  .mat-checkbox-frame {
    border-color: mat-color($foreground, secondary-text);
  }

  .mat-checkbox-checkmark {
    fill: $checkbox-mark-color;
  }

  .mat-checkbox-checkmark-path {
    // !important is needed here because a stroke must be set as an
    // attribute on the SVG in order for line animation to work properly.
    stroke: $checkbox-mark-color !important;

    @include cdk-high-contrast(black-on-white) {
      // Having the one above be !important ends up overriding the browser's automatic
      // color inversion so we need to re-invert it ourselves for black-on-white.
      stroke: #000 !important;
    }
  }

  .mat-checkbox-mixedmark {
    background-color: $checkbox-mark-color;
  }

  .mat-checkbox-indeterminate, .mat-checkbox-checked {
    &.mat-primary .mat-checkbox-background {
      background-color: mat-color($primary);
    }

    &.mat-accent .mat-checkbox-background {
      background-color: mat-color($accent);
    }

    &.mat-warn .mat-checkbox-background {
      background-color: mat-color($warn);
    }
  }

  .mat-checkbox-disabled {
    &.mat-checkbox-checked,
    &.mat-checkbox-indeterminate {
      .mat-checkbox-background {
        background-color: $disabled-color;
      }
    }

    &:not(.mat-checkbox-checked) {
      .mat-checkbox-frame {
        border-color: $disabled-color;
      }
    }

    .mat-checkbox-label {
      color: mat-color($foreground, secondary-text);
    }

    @include cdk-high-contrast {
      opacity: 0.5;
    }
  }

  // This one is moved down here so it can target both
  // the theme colors and the disabled state.
  @include cdk-high-contrast {
    .mat-checkbox-background {
      // Needs to be removed because it hides the checkbox outline.
      background: none;
    }
  }

  // Switch this to a solid color since we're using `opacity`
  // to control how opaque the ripple should be.
  .mat-checkbox .mat-ripple-element {
    background-color: map_get(map-get($theme, foreground), base);
  }

  .mat-checkbox-checked:not(.mat-checkbox-disabled),
  .mat-checkbox:active:not(.mat-checkbox-disabled) {
    &.mat-primary .mat-ripple-element {
      background: mat-color($primary);
    }

    &.mat-accent .mat-ripple-element {
      background: mat-color($accent);
    }

    &.mat-warn .mat-ripple-element {
      background: mat-color($warn);
    }
  }
}

@mixin mat-checkbox-typography($config) {
  .mat-checkbox {
    font-family: mat-font-family($config);
  }

  // TODO(kara): Remove this style when fixing vertical baseline
  .mat-checkbox-layout .mat-checkbox-label {
    line-height: mat-line-height($config, body-2);
  }
}






$mat-chip-remove-font-size: 18px;

@mixin mat-chips-color($foreground, $background) {
  background-color: $background;
  color: $foreground;

  .mat-chip-remove {
    color: $foreground;
    opacity: 0.4;
  }
}

@mixin mat-chips-theme-color($palette) {
  @include mat-chips-color(mat-color($palette, default-contrast), mat-color($palette));

  .mat-ripple-element {
    background: mat-color($palette, default-contrast, 0.1);
  }
}

@mixin mat-chips-theme($theme) {
  $is-dark-theme: map-get($theme, is-dark);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  $unselected-background: mat-color($background, unselected-chip);
  $unselected-foreground: mat-color($foreground, text);

  .mat-chip.mat-standard-chip {
    @include mat-chips-color($unselected-foreground, $unselected-background);

    &:not(.mat-chip-disabled) {
      &:active {
        @include _mat-theme-elevation(3, $theme);
      }

      .mat-chip-remove:hover {
        opacity: 0.54;
      }
    }

    &.mat-chip-disabled {
      opacity: 0.4;
    }

    &::after {
      background: map_get($foreground, base);
    }
  }

  .mat-chip.mat-standard-chip.mat-chip-selected {
    &.mat-primary {
      @include mat-chips-theme-color($primary);
    }

    &.mat-warn {
      @include mat-chips-theme-color($warn);
    }

    &.mat-accent {
      @include mat-chips-theme-color($accent);
    }
  }
}

@mixin mat-chips-typography($config) {
  .mat-chip {
    font-size: mat-font-size($config, body-2);
    font-weight: mat-font-weight($config, body-2);

    .mat-chip-trailing-icon.mat-icon,
    .mat-chip-remove.mat-icon {
      font-size: $mat-chip-remove-font-size;
    }
  }
}





@mixin mat-table-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .mat-table {
    background: mat-color($background, 'card');
  }

  .mat-table thead, .mat-table tbody, .mat-table tfoot,
  mat-header-row, mat-row, mat-footer-row,
  [mat-header-row], [mat-row], [mat-footer-row],
  .mat-table-sticky {
    background: inherit;
  }

  mat-row, mat-header-row, mat-footer-row,
  th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
    border-bottom-color: mat-color($foreground, divider);
  }

  .mat-header-cell {
    color: mat-color($foreground, secondary-text);
  }

  .mat-cell, .mat-footer-cell {
    color: mat-color($foreground, text);
  }
}

@mixin mat-table-typography($config) {
  .mat-table {
    font-family: mat-font-family($config);
  }

  .mat-header-cell {
    font-size: mat-font-size($config, caption);
    font-weight: mat-font-weight($config, body-2);
  }

  .mat-cell, .mat-footer-cell {
    font-size: mat-font-size($config, body-1);
  }
}







$mat-datepicker-selected-today-box-shadow-width: 1px;
$mat-datepicker-selected-fade-amount: 0.6;
$mat-datepicker-today-fade-amount: 0.2;
$mat-calendar-body-font-size: 13px !default;
$mat-calendar-weekday-table-font-size: 11px !default;

@mixin _mat-datepicker-color($palette) {
  .mat-calendar-body-selected {
    background-color: mat-color($palette);
    color: mat-color($palette, default-contrast);
  }

  .mat-calendar-body-disabled > .mat-calendar-body-selected {
    $background: mat-color($palette);

    @if (type-of($background) == color) {
      background-color: fade-out($background, $mat-datepicker-selected-fade-amount);
    }
    @else {
      // If we couldn't resolve to background to a color (e.g. it's a CSS variable),
      // fall back to fading the content out via `opacity`.
      opacity: $mat-datepicker-today-fade-amount;
    }
  }

  .mat-calendar-body-today.mat-calendar-body-selected {
    box-shadow: inset 0 0 0 $mat-datepicker-selected-today-box-shadow-width
                mat-color($palette, default-contrast);
  }
}

@mixin mat-datepicker-theme($theme) {
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  .mat-calendar-arrow {
    border-top-color: mat-color($foreground, icon);
  }

  // The prev/next buttons need a bit more specificity to
  // avoid being overwritten by the .mat-icon-button.
  .mat-datepicker-toggle,
  .mat-datepicker-content .mat-calendar-next-button,
  .mat-datepicker-content .mat-calendar-previous-button {
    color: mat-color($foreground, icon);
  }

  .mat-calendar-table-header {
    color: mat-color($foreground, hint-text);
  }

  .mat-calendar-table-header-divider::after {
    background: mat-color($foreground, divider);
  }

  .mat-calendar-body-label {
    color: mat-color($foreground, secondary-text);
  }

  .mat-calendar-body-cell-content {
    color: mat-color($foreground, text);
    border-color: transparent;
  }

  .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
    color: mat-color($foreground, disabled-text);
  }

  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover,
  .cdk-keyboard-focused .mat-calendar-body-active,
  .cdk-program-focused .mat-calendar-body-active {
    & > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
      background-color: mat-color($background, hover);
    }
  }

  .mat-calendar-body-today:not(.mat-calendar-body-selected) {
    // Note: though it's not text, the border is a hint about the fact that this is today's date,
    // so we use the hint color.
    border-color: mat-color($foreground, hint-text);
  }

  .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected) {
    $color: mat-color($foreground, hint-text);

    @if (type-of($color) == color) {
      border-color: fade-out($color, $mat-datepicker-today-fade-amount);
    }
    @else {
      // If the color didn't resolve to a color value, but something like a CSS variable, we can't
      // fade it out so we fall back to reducing the element opacity. Note that we don't use the
      // $mat-datepicker-today-fade-amount, because hint text usually has some opacity applied
      // to it already and we don't want them to stack on top of each other.
      opacity: 0.5;
    }
  }

  @include _mat-datepicker-color(map-get($theme, primary));

  .mat-datepicker-content {
    @include _mat-theme-elevation(4, $theme);
    background-color: mat-color($background, card);
    color: mat-color($foreground, text);

    &.mat-accent {
      @include _mat-datepicker-color(map-get($theme, accent));
    }

    &.mat-warn {
      @include _mat-datepicker-color(map-get($theme, warn));
    }
  }

  .mat-datepicker-content-touch {
    @include _mat-theme-elevation(0, $theme);
  }

  .mat-datepicker-toggle-active {
    color: mat-color(map-get($theme, primary), text);

    &.mat-accent {
      color: mat-color(map-get($theme, accent), text);
    }

    &.mat-warn {
      color: mat-color(map-get($theme, warn), text);
    }
  }
}

@mixin mat-datepicker-typography($config) {
  .mat-calendar {
    font-family: mat-font-family($config);
  }

  .mat-calendar-body {
    font-size: $mat-calendar-body-font-size;
  }

  .mat-calendar-body-label,
  .mat-calendar-period-button {
    font: {
      size: mat-font-size($config, button);
      weight: mat-font-weight($config, button);
    }
  }

  .mat-calendar-table-header th {
    font: {
      size: $mat-calendar-weekday-table-font-size;
      weight: mat-font-weight($config, body-1);
    }
  }
}







@mixin mat-dialog-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .mat-dialog-container {
    @include _mat-theme-elevation(24, $theme);
    background: mat-color($background, dialog);
    color: mat-color($foreground, text);
  }
}

@mixin mat-dialog-typography($config) {
  .mat-dialog-title {
    @include mat-typography-level-to-styles($config, title);
  }
}






@mixin mat-expansion-panel-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .mat-expansion-panel {
    @include _mat-theme-overridable-elevation(2, $theme);
    background: mat-color($background, card);
    color: mat-color($foreground, text);
  }

  .mat-action-row {
    border-top-color: mat-color($foreground, divider);
  }

  .mat-expansion-panel {
    & .mat-expansion-panel-header.cdk-keyboard-focused,
    & .mat-expansion-panel-header.cdk-program-focused,
    &:not(.mat-expanded) .mat-expansion-panel-header:hover {
      &:not([aria-disabled='true']) {
        background: mat-color($background, hover);
      }
    }
  }

  // Disable the hover on touch devices since it can appear like it is stuck. We can't use
  // `@media (hover)` above, because the desktop support browser support isn't great.
  @media (hover: none) {
    .mat-expansion-panel:not(.mat-expanded):not([aria-disabled='true'])
      .mat-expansion-panel-header:hover {
      background: mat-color($background, card);
    }
  }

  .mat-expansion-panel-header-title {
    color: mat-color($foreground, text);
  }

  .mat-expansion-panel-header-description,
  .mat-expansion-indicator::after {
    color: mat-color($foreground, secondary-text);
  }

  .mat-expansion-panel-header[aria-disabled='true'] {
    color: mat-color($foreground, disabled-button);

    .mat-expansion-panel-header-title,
    .mat-expansion-panel-header-description {
      color: inherit;
    }
  }
}

@mixin mat-expansion-panel-typography($config) {
  .mat-expansion-panel-header {
    font: {
      family: mat-font-family($config, subheading-1);
      size: mat-font-size($config, subheading-1);
      weight: mat-font-weight($config, subheading-1);
    }
  }

  .mat-expansion-panel-content {
    @include mat-typography-level-to-styles($config, body-1);
  }
}




// This mixin will ensure that lines that overflow the container will hide the overflow and
// truncate neatly with an ellipsis.
@mixin mat-truncate-line() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Mixin to provide all mat-line styles, changing secondary font size based on whether the list
// is in dense mode.
@mixin mat-line-base($secondary-font-size) {
  .mat-line {
    @include mat-truncate-line();
    display: block;
    box-sizing: border-box;

    // all lines but the top line should have smaller text
    &:nth-child(n+2) {
      font-size: $secondary-font-size;
    }
  }
}

// This mixin normalizes default element styles, e.g. font weight for heading text.
@mixin mat-normalize-text() {
  & > * {
    margin: 0;
    padding: 0;
    font-weight: normal;
    font-size: inherit;
  }
}

// This mixin provides base styles for the wrapper around mat-line elements in a list.
@mixin mat-line-wrapper-base() {
  @include mat-normalize-text();

  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;

  // Must remove wrapper when lines are empty or it takes up horizontal
  // space and pushes other elements to the right.
  &:empty {
    display: none;
  }
}



// Include this empty mixin for consistency with the other components.
@mixin mat-grid-list-theme($theme) { }

@mixin mat-grid-list-typography($config) {
  .mat-grid-tile-header,
  .mat-grid-tile-footer {
    @include mat-line-base(mat-font-size($config, caption));
    font-size: mat-font-size($config, body-1);
  }
}




// Include this empty mixin for consistency with the other components.
@mixin mat-icon-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .mat-icon {
    &.mat-primary {
      color: mat-color($primary, text);
    }

    &.mat-accent {
      color: mat-color($accent, text);
    }

    &.mat-warn {
      color: mat-color($warn, text);
    }
  }
}

@mixin mat-icon-typography($config) { }





// Renders a gradient for showing the dashed line when the input is disabled.
// Unlike using a border, a gradient allows us to adjust the spacing of the dotted line
// to match the Material Design spec.
@mixin mat-control-disabled-underline($color) {
  background-image: linear-gradient(to right, $color 0%, $color 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

// Figures out the color of the placeholder for a form control.
// Used primarily to prevent the various form controls from
// becoming out of sync since these colors aren't in a palette.
@function _mat-control-placeholder-color($theme) {
  $foreground: map-get($theme, foreground);
  $is-dark-theme: map-get($theme, is-dark);
  @return mat-color($foreground, secondary-text, if($is-dark-theme, 0.5, 0.42));
}


/* stylelint-disable material/no-prefixes */
@mixin user-select($value) {
  -webkit-user-select: $value;
  -moz-user-select: $value;
  -ms-user-select: $value;
  user-select: $value;
}

@mixin input-placeholder {
  &::placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &::-webkit-input-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }
}

@mixin cursor-grab {
  cursor: -webkit-grab;
  cursor: grab;
}

@mixin cursor-grabbing {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

@mixin backface-visibility($value) {
  -webkit-backface-visibility: $value;
  backface-visibility: $value;
}
/* stylelint-enable */



@mixin mat-input-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);

  .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
    color: mat-color($foreground, secondary-text);
  }

  .mat-input-element:disabled,
  .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
    color: mat-color($foreground, disabled-text);
  }

  .mat-input-element {
    caret-color: mat-color($primary, text);

    @include input-placeholder {
      color: _mat-control-placeholder-color($theme);
    }

    // On dark themes we set the native `select` color to some shade of white,
    // however the color propagates to all of the `option` elements, which are
    // always on a white background inside the dropdown, causing them to blend in.
    // Since we can't change background of the dropdown, we need to explicitly
    // reset the color of the options to something dark.
    @if (map-get($theme, is-dark)) {
      option {
        color: $dark-primary-text;
      }

      option:disabled {
        color: $dark-disabled-text;
      }
    }
  }

  .mat-accent .mat-input-element {
    caret-color: mat-color($accent, text);
  }

  .mat-warn .mat-input-element,
  .mat-form-field-invalid .mat-input-element {
    caret-color: mat-color($warn, text);
  }

  .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
    color: mat-color($warn, text);
  }
}

@mixin mat-input-typography($config) {
  // The unit-less line-height from the font config.
  $line-height: mat-line-height($config, input);

  // The amount of space between the top of the line and the top of the actual text
  // (as a fraction of the font-size).
  $line-spacing: ($line-height - 1) / 2;

  // <input> elements seem to have their height set slightly too large on Safari causing the text to
  // be misaligned w.r.t. the placeholder. Adding this margin corrects it.
  input.mat-input-element {
    margin-top: -$line-spacing * 1em;
  }
}







@mixin mat-list-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .mat-list-base {
    .mat-list-item {
      color: mat-color($foreground, text);
    }

    .mat-list-option {
      color: mat-color($foreground, text);
    }

    .mat-subheader {
      color: mat-color($foreground, secondary-text);
    }
  }

  .mat-list-item-disabled {
    background-color: mat-color($background, disabled-list-option);
  }

  .mat-list-option,
  .mat-nav-list .mat-list-item,
  .mat-action-list .mat-list-item {
    &:hover, &:focus {
      background: mat-color($background, 'hover');
    }
  }
}

@mixin mat-list-typography($config) {
  $font-family: mat-font-family($config);

  .mat-list-item {
    font-family: $font-family;
  }

  .mat-list-option {
    font-family: $font-family;
  }

  // Default list
  .mat-list-base {
    .mat-list-item {
      font-size: mat-font-size($config, subheading-2);
      @include mat-line-base(mat-font-size($config, body-1));
    }

    .mat-list-option {
      font-size: mat-font-size($config, subheading-2);
      @include mat-line-base(mat-font-size($config, body-1));
    }

    .mat-subheader {
      font-family: mat-font-family($config, body-2);
      font-size: mat-font-size($config, body-2);
      font-weight: mat-font-weight($config, body-2);
    }
  }

  // Dense list
  .mat-list-base[dense] {
    .mat-list-item {
      font-size: mat-font-size($config, caption);
      @include mat-line-base(mat-font-size($config, caption));
    }

    .mat-list-option {
      font-size: mat-font-size($config, caption);
      @include mat-line-base(mat-font-size($config, caption));
    }

    .mat-subheader {
      font-family: $font-family;
      font-size: mat-font-size($config, caption);
      font-weight: mat-font-weight($config, body-2);
    }
  }
}







@mixin mat-menu-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .mat-menu-panel {
    @include _mat-theme-overridable-elevation(4, $theme);
    background: mat-color($background, 'card');
  }

  .mat-menu-item {
    background: transparent;
    color: mat-color($foreground, 'text');

    &[disabled] {
      &, &::after {
        color: mat-color($foreground, 'disabled');
      }
    }
  }

  .mat-menu-item .mat-icon-no-color,
  .mat-menu-item-submenu-trigger::after {
    color: mat-color($foreground, 'icon');
  }

  .mat-menu-item:hover,
  .mat-menu-item.cdk-program-focused,
  .mat-menu-item.cdk-keyboard-focused,
  .mat-menu-item-highlighted {
    &:not([disabled]) {
      background: mat-color($background, 'hover');
    }
  }
}

@mixin mat-menu-typography($config) {
  .mat-menu-item {
    font: {
      family: mat-font-family($config, body-1);
      size: mat-font-size($config, body-1);
      weight: mat-font-weight($config, body-1);
    }
  }
}






@mixin mat-paginator-theme($theme) {
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  .mat-paginator {
    background: mat-color($background, 'card');
  }

  .mat-paginator,
  .mat-paginator-page-size .mat-select-trigger {
    color: mat-color($foreground, secondary-text);
  }

  .mat-paginator-decrement,
  .mat-paginator-increment {
    border-top: 2px solid mat-color($foreground, 'icon');
    border-right: 2px solid mat-color($foreground, 'icon');
  }

  .mat-paginator-first,
  .mat-paginator-last {
    border-top: 2px solid mat-color($foreground, 'icon');
  }

  .mat-icon-button[disabled] {
    .mat-paginator-decrement,
    .mat-paginator-increment,
    .mat-paginator-first,
    .mat-paginator-last {
      border-color: mat-color($foreground, 'disabled');
    }
  }
}

@mixin mat-paginator-typography($config) {
  .mat-paginator,
  .mat-paginator-page-size .mat-select-trigger {
    font: {
      family: mat-font-family($config, caption);
      size: mat-font-size($config, caption);
    }
  }
}





@mixin mat-progress-bar-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .mat-progress-bar-background {
    fill: mat-color($primary, lighter);
  }

  .mat-progress-bar-buffer {
    background-color: mat-color($primary, lighter);
  }

  .mat-progress-bar-fill::after {
    background-color: mat-color($primary);
  }

  .mat-progress-bar.mat-accent {
    .mat-progress-bar-background {
      fill: mat-color($accent, lighter);
    }

    .mat-progress-bar-buffer {
      background-color: mat-color($accent, lighter);
    }

    .mat-progress-bar-fill::after {
      background-color: mat-color($accent);
    }
  }

  .mat-progress-bar.mat-warn {
    .mat-progress-bar-background {
      fill: mat-color($warn, lighter);
    }

    .mat-progress-bar-buffer {
      background-color: mat-color($warn, lighter);
    }

    .mat-progress-bar-fill::after {
      background-color: mat-color($warn);
    }
  }
}

@mixin mat-progress-bar-typography($config) { }






@mixin mat-progress-spinner-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .mat-progress-spinner, .mat-spinner {
    circle {
      stroke: mat-color($primary);
    }

    &.mat-accent circle {
      stroke: mat-color($accent);
    }

    &.mat-warn circle {
      stroke: mat-color($warn);
    }
  }
}

@mixin mat-progress-spinner-typography($config) { }





@mixin _mat-radio-color($palette) {
  &.mat-radio-checked .mat-radio-outer-circle {
    border-color: mat-color($palette);
  }

  .mat-radio-inner-circle,
  .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
  &.mat-radio-checked .mat-radio-persistent-ripple,
  &:active .mat-radio-persistent-ripple {
    background-color: mat-color($palette);
  }
}

@mixin mat-radio-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .mat-radio-outer-circle {
    border-color: mat-color($foreground, secondary-text);
  }

  .mat-radio-button {
    &.mat-primary {
      @include _mat-radio-color($primary);
    }

    &.mat-accent {
      @include _mat-radio-color($accent);
    }

    &.mat-warn {
      @include _mat-radio-color($warn);
    }

    // This needs extra specificity, because the classes above are combined
    // (e.g. `.mat-radio-button.mat-accent`) which increases their specificity a lot.
    // TODO: consider making the selectors into descendants (`.mat-primary .mat-radio-button`).
    &.mat-radio-disabled {
      &.mat-radio-checked .mat-radio-outer-circle,
      .mat-radio-outer-circle {
        border-color: mat-color($foreground, disabled);
      }

      .mat-radio-ripple .mat-ripple-element,
      .mat-radio-inner-circle {
        background-color: mat-color($foreground, disabled);
      }

      .mat-radio-label-content {
        color: mat-color($foreground, disabled);
      }
    }

    // Switch this to a solid color since we're using `opacity`
    // to control how opaque the ripple should be.
    .mat-ripple-element {
      background-color: map_get($foreground, base);
    }
  }
}

@mixin mat-radio-typography($config) {
  .mat-radio-button {
    font-family: mat-font-family($config);
  }
}








@mixin mat-select-theme($theme) {
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .mat-select-value {
    color: mat-color($foreground, text);
  }

  .mat-select-placeholder {
    color: _mat-control-placeholder-color($theme);
  }

  .mat-select-disabled .mat-select-value {
    color: mat-color($foreground, disabled-text);
  }

  .mat-select-arrow {
    color: mat-color($foreground, secondary-text);
  }

  .mat-select-panel {
    background: mat-color($background, card);
    @include _mat-theme-overridable-elevation(4, $theme);

    .mat-option.mat-selected:not(.mat-option-multiple) {
      background: mat-color($background, hover, 0.12);
    }
  }

  .mat-form-field {
    &.mat-focused {
      &.mat-primary .mat-select-arrow {
        color: mat-color($primary, text);
      }

      &.mat-accent .mat-select-arrow {
        color: mat-color($accent, text);
      }

      &.mat-warn .mat-select-arrow {
        color: mat-color($warn, text);
      }
    }

    .mat-select.mat-select-invalid .mat-select-arrow {
      color: mat-color($warn, text);
    }

    .mat-select.mat-select-disabled .mat-select-arrow {
      color: mat-color($foreground, disabled-text);
    }
  }
}

@mixin mat-select-typography($config) {
  // The unit-less line-height from the font config.
  $line-height: mat-line-height($config, input);

  .mat-select {
    font-family: mat-font-family($config);
  }

  .mat-select-trigger {
    height: $line-height * 1em;
  }
}






@mixin mat-sidenav-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  $drawer-background-color: mat-color($background, dialog);
  $drawer-container-background-color:  mat-color($background, background);
  $drawer-push-background-color: mat-color($background, dialog);
  $drawer-side-border: solid 1px mat-color($foreground, divider);

  .mat-drawer-container {
    background-color: $drawer-container-background-color;
    color: mat-color($foreground, text);
  }

  .mat-drawer {
    background-color: $drawer-background-color;
    color: mat-color($foreground, text);

    &.mat-drawer-push {
      background-color: $drawer-push-background-color;
    }

    &:not(.mat-drawer-side) {
      // The elevation of z-16 is noted in the design specifications.
      // See https://material.io/design/components/navigation-drawer.html
      @include _mat-theme-elevation(16, $theme);
    }
  }

  .mat-drawer-side {
    border-right: $drawer-side-border;

    &.mat-drawer-end {
      border-left: $drawer-side-border;
      border-right: none;
    }
  }

  [dir='rtl'] .mat-drawer-side {
    border-left: $drawer-side-border;
    border-right: none;

    &.mat-drawer-end {
      border-left: none;
      border-right: $drawer-side-border;
    }
  }

  .mat-drawer-backdrop.mat-drawer-shown {
    $opacity: 0.6;
    $backdrop-color: mat-color($background, card, $opacity);

    @if (type-of($backdrop-color) == color) {
      // We use invert() here to have the darken the background color expected to be used. If the
      // background is light, we use a dark backdrop. If the background is dark,
      // we use a light backdrop.
      background-color: invert($backdrop-color);
    }
    @else {
      // If we couldn't resolve the backdrop color to a color value, fall back to using
      // `opacity` to make it opaque since its end value could be a solid color.
      background-color: $backdrop-color;
      opacity: $opacity;
    }
  }
}

@mixin mat-sidenav-typography($config) { }






@mixin _mat-slide-toggle-checked($palette, $thumb-checked-hue) {
  &.mat-checked {
    .mat-slide-toggle-thumb {
      background-color: mat-color($palette, $thumb-checked-hue);
    }

    .mat-slide-toggle-bar {
      // Opacity is determined from the specs for the selection controls.
      // See: https://material.io/design/components/selection-controls.html#specs
      background-color: mat-color($palette, $thumb-checked-hue, 0.54);
    }

    .mat-ripple-element {
      // Set no opacity for the ripples because the ripple opacity will be adjusted dynamically
      // based on the type of interaction with the slide-toggle (e.g. for hover, focus)
      background-color: mat-color($palette, $thumb-checked-hue);
    }
  }
}

@mixin mat-slide-toggle-theme($theme) {
  $is-dark: map_get($theme, is-dark);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  // Color hues are based on the specs which briefly show the hues that are applied to a switch.
  // The 2018 specs no longer describe how dark switches should look like. Due to the lack of
  // information for dark themed switches, we partially keep the old behavior that is based on
  // the previous specifications. For the checked color we always use the `default` hue because
  // that follows MDC and also makes it easier for people to create a custom theme without needing
  // to specify each hue individually.
  $thumb-unchecked-hue: if($is-dark, 400, 50);
  $thumb-checked-hue: default;

  $bar-unchecked-color: mat-color($foreground, disabled);
  $ripple-unchecked-color: mat-color($foreground, base);

  .mat-slide-toggle {
    @include _mat-slide-toggle-checked($accent, $thumb-checked-hue);

    &.mat-primary {
      @include _mat-slide-toggle-checked($primary, $thumb-checked-hue);
    }

    &.mat-warn {
      @include _mat-slide-toggle-checked($warn, $thumb-checked-hue);
    }

    &:not(.mat-checked) .mat-ripple-element {
      // Set no opacity for the ripples because the ripple opacity will be adjusted dynamically
      // based on the type of interaction with the slide-toggle (e.g. for hover, focus)
      background-color: $ripple-unchecked-color;
    }
  }

  .mat-slide-toggle-thumb {
    @include _mat-theme-elevation(1, $theme);
    background-color: mat-color($mat-grey, $thumb-unchecked-hue);
  }

  .mat-slide-toggle-bar {
    background-color: $bar-unchecked-color;
  }
}

@mixin mat-slide-toggle-typography($config) {
  .mat-slide-toggle-content {
    font-family: mat-font-family($config);
  }
}





@mixin _mat-slider-inner-content-theme($palette) {
  .mat-slider-track-fill,
  .mat-slider-thumb,
  .mat-slider-thumb-label {
    background-color: mat-color($palette);
  }

  .mat-slider-thumb-label-text {
    color: mat-color($palette, default-contrast);
  }
}

@mixin mat-slider-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  $mat-slider-off-color: mat-color($foreground, slider-off);
  $mat-slider-off-focused-color: mat-color($foreground, slider-off-active);
  $mat-slider-disabled-color: mat-color($foreground, slider-off);
  $mat-slider-labeled-min-value-thumb-color: mat-color($foreground, slider-min);
  $mat-slider-labeled-min-value-thumb-label-color: mat-color($foreground, slider-off);
  $mat-slider-focus-ring-color: mat-color($accent, default, 0.2);
  $mat-slider-focus-ring-min-value-color: mat-color($foreground, base, 0.12);
  $mat-slider-tick-color: mat-color($foreground, base, 0.7);
  $mat-slider-tick-size: 2px;

  .mat-slider-track-background {
    background-color: $mat-slider-off-color;
  }

  .mat-primary {
    @include _mat-slider-inner-content-theme($primary);
  }

  .mat-accent {
    @include _mat-slider-inner-content-theme($accent);
  }

  .mat-warn {
    @include _mat-slider-inner-content-theme($warn);
  }

  .mat-slider-focus-ring {
    background-color: $mat-slider-focus-ring-color;
  }

  .mat-slider:hover,
  .cdk-focused {
    .mat-slider-track-background {
      background-color: $mat-slider-off-focused-color;
    }
  }

  .mat-slider-disabled {
    .mat-slider-track-background,
    .mat-slider-track-fill,
    .mat-slider-thumb {
      background-color: $mat-slider-disabled-color;
    }

    &:hover {
      .mat-slider-track-background {
        background-color: $mat-slider-disabled-color;
      }
    }
  }

  .mat-slider-min-value {
    .mat-slider-focus-ring {
      background-color: $mat-slider-focus-ring-min-value-color;
    }

    &.mat-slider-thumb-label-showing {
      .mat-slider-thumb,
      .mat-slider-thumb-label {
        background-color: $mat-slider-labeled-min-value-thumb-color;
      }

      &.cdk-focused {
        .mat-slider-thumb,
        .mat-slider-thumb-label {
          background-color: $mat-slider-labeled-min-value-thumb-label-color;
        }
      }
    }

    &:not(.mat-slider-thumb-label-showing) {
      .mat-slider-thumb {
        border-color: $mat-slider-off-color;
        background-color: transparent;
      }

      &:hover,
      &.cdk-focused {
        .mat-slider-thumb {
          border-color: $mat-slider-off-focused-color;
        }

        &.mat-slider-disabled .mat-slider-thumb {
          border-color: $mat-slider-disabled-color;
        }
      }
    }
  }

  .mat-slider-has-ticks .mat-slider-wrapper::after {
    border-color: $mat-slider-tick-color;
  }

  .mat-slider-horizontal .mat-slider-ticks {
    background-image: repeating-linear-gradient(to right, $mat-slider-tick-color,
        $mat-slider-tick-color $mat-slider-tick-size, transparent 0, transparent);
    // Firefox doesn't draw the gradient correctly with 'to right'
    // (see https://bugzilla.mozilla.org/show_bug.cgi?id=1314319).
    background-image: -moz-repeating-linear-gradient(0.0001deg, $mat-slider-tick-color,
        $mat-slider-tick-color $mat-slider-tick-size, transparent 0, transparent);
  }

  .mat-slider-vertical .mat-slider-ticks {
    background-image: repeating-linear-gradient(to bottom, $mat-slider-tick-color,
        $mat-slider-tick-color $mat-slider-tick-size, transparent 0, transparent);
  }
}

@mixin mat-slider-typography($config) {
  .mat-slider-thumb-label-text {
    font: {
      family: mat-font-family($config);
      size: mat-font-size($config, caption);
      weight: mat-font-weight($config, body-2);
    }
  }
}





@mixin mat-stepper-theme($theme) {
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);
  $primary: map-get($theme, primary);
  $warn: map-get($theme, warn);

  .mat-step-header {
    &.cdk-keyboard-focused,
    &.cdk-program-focused,
    &:hover {
      background-color: mat-color($background, hover);
    }

    // On touch devices the :hover state will linger on the element after a tap.
    // Reset it via `@media` after the declaration, because the media query isn't
    // supported by all browsers yet.
    @media (hover: none) {
      &:hover {
        background: none;
      }
    }

    .mat-step-label,
    .mat-step-optional {
      // TODO(josephperrott): Update to using a corrected disabled-text contrast
      // instead of secondary-text.
      color: mat-color($foreground, secondary-text);
    }

    .mat-step-icon {
      // TODO(josephperrott): Update to using a corrected disabled-text contrast
      // instead of secondary-text.
      background-color: mat-color($foreground, secondary-text);
      color: mat-color($primary, default-contrast);
    }

    .mat-step-icon-selected,
    .mat-step-icon-state-done,
    .mat-step-icon-state-edit {
      background-color: mat-color($primary);
      color: mat-color($primary, default-contrast);
    }

    .mat-step-icon-state-error {
      background-color: transparent;
      color: mat-color($warn, text);
    }

    .mat-step-label.mat-step-label-active {
      color: mat-color($foreground, text);
    }

    .mat-step-label.mat-step-label-error {
      color: mat-color($warn, text);
    }
  }

  .mat-stepper-horizontal, .mat-stepper-vertical {
    background-color: mat-color($background, card);
  }

  .mat-stepper-vertical-line::before {
    border-left-color: mat-color($foreground, divider);
  }

  .mat-horizontal-stepper-header::before,
  .mat-horizontal-stepper-header::after,
  .mat-stepper-horizontal-line {
    border-top-color: mat-color($foreground, divider);
  }
}

@mixin mat-stepper-typography($config) {
  .mat-stepper-vertical, .mat-stepper-horizontal {
    font-family: mat-font-family($config);
  }

  .mat-step-label {
    font: {
      size: mat-font-size($config, body-1);
      weight: mat-font-weight($config, body-1);
    };
  }

  .mat-step-sub-label-error {
    font-weight: normal;
  }

  .mat-step-label-error {
    font-size: mat-font-size($config, body-2);
  }

  .mat-step-label-selected {
    font: {
      size: mat-font-size($config, body-2);
      weight: mat-font-weight($config, body-2);
    };
  }
}

@mixin mat-sort-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .mat-sort-header-arrow {
    $table-background: mat-color($background, 'card');
    $text-color: mat-color($foreground, secondary-text);

    // Because the arrow is made up of multiple elements that are stacked on top of each other,
    // we can't use the semi-trasparent color from the theme directly. If the value is a color
    // *type*, we convert it into a solid color by taking the opacity from the rgba value and
    // using the value to determine the percentage of the background to put into foreground
    // when mixing the colors together. Otherwise, if it resolves to something different
    // (e.g. it resolves to a CSS variable), we use the color directly.
    @if (type-of($table-background) == color and type-of($text-color) == color) {
      $text-opacity: opacity($text-color);
      color: mix($table-background, rgba($text-color, 1), (1 - $text-opacity) * 100%);
    }
    @else {
      color: $text-color;
    }
  }
}

@mixin mat-sort-typography($config) { }





@mixin mat-tabs-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $header-border: 1px solid mat-color($foreground, divider);

  .mat-tab-nav-bar,
  .mat-tab-header {
    border-bottom: $header-border;
  }

  .mat-tab-group-inverted-header {
    .mat-tab-nav-bar,
    .mat-tab-header {
      border-top: $header-border;
      border-bottom: none;
    }
  }

  .mat-tab-label, .mat-tab-link {
    color: mat-color($foreground, text);

    &.mat-tab-disabled {
      color: mat-color($foreground, disabled-text);
    }
  }

  .mat-tab-header-pagination-chevron {
    border-color: mat-color($foreground, text);
  }

  .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
    border-color: mat-color($foreground, disabled-text);
  }

  // Remove header border when there is a background color
  .mat-tab-group[class*='mat-background-'] .mat-tab-header,
  .mat-tab-nav-bar[class*='mat-background-'] {
    border-bottom: none;
    border-top: none;
  }

  .mat-tab-group, .mat-tab-nav-bar {
    $theme-colors: (
      primary: $primary,
      accent: $accent,
      warn: $warn
    );

    @each $name, $color in $theme-colors {
      // Set the foreground color of the tabs
      &.mat-#{$name} {
        @include _mat-tab-label-focus($color);
        @include _mat-ink-bar($color);

        // Override ink bar when background color is the same
        &.mat-background-#{$name} {
          @include _mat-ink-bar($color, default-contrast);
        }
      }
    }

    @each $name, $color in $theme-colors {
      // Set background color of the tabs and override focus color
      &.mat-background-#{$name} {
        @include _mat-tab-label-focus($color);
        @include _mat-tabs-background($color);
      }
    }
  }
}

@mixin _mat-ink-bar($color, $hue: default) {
  .mat-ink-bar {
    background-color: mat-color($color, $hue);
  }
}

@mixin _mat-tab-label-focus($tab-focus-color) {
  .mat-tab-label,
  .mat-tab-link {
    &.cdk-keyboard-focused,
    &.cdk-program-focused {
      &:not(.mat-tab-disabled) {
        background-color: mat-color($tab-focus-color, lighter, 0.3);
      }
    }
  }
}

@mixin _mat-tabs-background($background-color) {
  // Set background color for the tab group
  .mat-tab-header, .mat-tab-links, .mat-tab-header-pagination {
    background-color: mat-color($background-color);
  }

  // Set labels to contrast against background
  .mat-tab-label, .mat-tab-link {
    color: mat-color($background-color, default-contrast);

    &.mat-tab-disabled {
      color: mat-color($background-color, default-contrast, 0.4);
    }
  }

  // Set pagination chevrons to contrast background
  .mat-tab-header-pagination-chevron {
    border-color: mat-color($background-color, default-contrast);
  }

  .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
    border-color: mat-color($background-color, default-contrast, 0.4);
  }

  // Set ripples color to be the contrast color of the new background. Otherwise the ripple
  // color will be based on the app background color.
  .mat-ripple-element {
    background-color: mat-color($background-color, default-contrast, 0.12);
  }
}

@mixin mat-tabs-typography($config) {
  .mat-tab-group {
    font-family: mat-font-family($config);
  }

  .mat-tab-label, .mat-tab-link {
    font: {
      family: mat-font-family($config, button);
      size: mat-font-size($config, button);
      weight: mat-font-weight($config, button);
    }
  }
}






@mixin _mat-toolbar-color($palette) {
  background: mat-color($palette);
  color: mat-color($palette, default-contrast);
}

@mixin _mat-toolbar-form-field-overrides {
  .mat-form-field-underline,
  .mat-form-field-ripple,
  .mat-focused .mat-form-field-ripple {
    background-color: currentColor;
  }

  .mat-form-field-label,
  .mat-focused .mat-form-field-label,
  .mat-select-value,
  .mat-select-arrow,
  .mat-form-field.mat-focused .mat-select-arrow {
    color: inherit;
  }

  .mat-input-element {
    caret-color: currentColor;
  }
}

@mixin mat-toolbar-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .mat-toolbar {
    background: mat-color($background, app-bar);
    color: mat-color($foreground, text);

    &.mat-primary {
      @include _mat-toolbar-color($primary);
    }

    &.mat-accent {
      @include _mat-toolbar-color($accent);
    }

    &.mat-warn {
      @include _mat-toolbar-color($warn);
    }

    @include _mat-toolbar-form-field-overrides;
  }
}

@mixin mat-toolbar-typography($config) {
  .mat-toolbar,
  .mat-toolbar h1,
  .mat-toolbar h2,
  .mat-toolbar h3,
  .mat-toolbar h4,
  .mat-toolbar h5,
  .mat-toolbar h6 {
    @include mat-typography-level-to-styles($config, title);
    margin: 0;
  }
}





$mat-tooltip-target-height: 22px;
$mat-tooltip-font-size: 10px;
$mat-tooltip-vertical-padding: ($mat-tooltip-target-height - $mat-tooltip-font-size) / 2;

$mat-tooltip-handset-target-height: 30px;
$mat-tooltip-handset-font-size: 14px;
$mat-tooltip-handset-vertical-padding:
    ($mat-tooltip-handset-target-height - $mat-tooltip-handset-font-size) / 2;

@mixin mat-tooltip-theme($theme) {
  .mat-tooltip {
    background: mat-color($mat-grey, 700, 0.9);
  }
}

@mixin mat-tooltip-typography($config) {
  .mat-tooltip {
    font-family: mat-font-family($config);
    font-size: $mat-tooltip-font-size;
    padding-top: $mat-tooltip-vertical-padding;
    padding-bottom: $mat-tooltip-vertical-padding;
  }

  .mat-tooltip-handset {
    font-size: $mat-tooltip-handset-font-size;
    padding-top: $mat-tooltip-handset-vertical-padding;
    padding-bottom: $mat-tooltip-handset-vertical-padding;
  }
}





@mixin mat-snack-bar-theme($theme) {
  $is-dark-theme: map-get($theme, is-dark);
  $accent: map-get($theme, accent);

  .mat-snack-bar-container {
    // Use the primary text on the dark theme, even though the lighter one uses
    // a secondary, because the contrast on the light primary text is poor.
    color: if($is-dark-theme, $dark-primary-text, $light-secondary-text);
    background: if($is-dark-theme, map-get($mat-grey, 50), #323232);

    @include _mat-theme-elevation(6, $theme);
  }

  .mat-simple-snackbar-action {
    color: if($is-dark-theme, inherit, mat-color($accent, text));
  }
}

@mixin mat-snack-bar-typography($config) {
  .mat-simple-snackbar {
    font: {
      family: mat-font-family($config, body-1);
      size: mat-font-size($config, body-1);
    }
  }

  .mat-simple-snackbar-action {
    line-height: 1;
    font: {
      family: inherit;
      size: inherit;
      weight: mat-font-weight($config, button);
    }
  }
}














// Theme styles that only apply to the fill appearance of the form-field.

@mixin mat-form-field-fill-theme($theme) {
  $foreground: map-get($theme, foreground);
  $is-dark-theme: map-get($theme, is-dark);

  $fill-background: mat-color($foreground, base, if($is-dark-theme, 0.1, 0.04));
  $fill-disabled-background: mat-color($foreground, base, if($is-dark-theme, 0.05, 0.02));
  $underline-color: mat-color($foreground, divider, if($is-dark-theme, 0.5, 0.42));
  $label-disabled-color: mat-color($foreground, disabled-text);

  .mat-form-field-appearance-fill {
    .mat-form-field-flex {
      background-color: $fill-background;
    }

    &.mat-form-field-disabled .mat-form-field-flex {
      background-color: $fill-disabled-background;
    }

    .mat-form-field-underline::before {
      background-color: $underline-color;
    }

    &.mat-form-field-disabled {
      .mat-form-field-label {
        color: $label-disabled-color;
      }

      .mat-form-field-underline::before {
        background-color: transparent;
      }
    }
  }
}

// Used to make instances of the _mat-form-field-label-floating mixin negligibly different,
// and prevent Google's CSS Optimizer from collapsing the declarations. This is needed because some
// of the selectors contain pseudo-classes not recognized in all browsers. If a browser encounters
// an unknown pseudo-class it will discard the entire rule set.
$mat-form-field-fill-dedupe: 0;

// Applies a floating label above the form field control itself.
@mixin _mat-form-field-fill-label-floating($font-scale, $infix-padding, $infix-margin-top) {
  transform: translateY(-$infix-margin-top - $infix-padding + $mat-form-field-fill-dedupe)
             scale($font-scale);
  width: 100% / $font-scale + $mat-form-field-fill-dedupe;

  $mat-form-field-fill-dedupe: $mat-form-field-fill-dedupe + 0.00001 !global;
}

@mixin mat-form-field-fill-typography($config) {
  // The unit-less line-height from the font config.
  $line-height: mat-line-height($config, input);
  // The amount to scale the font for the floating label and subscript.
  $subscript-font-scale: 0.75;
  // The padding on top of the infix.
  $infix-padding-top: 0.25em;
  // The padding below the infix.
  $infix-padding-bottom: 0.75em;
  // The margin applied to the form-field-infix to reserve space for the floating label.
  $infix-margin-top: 1em * $line-height * $subscript-font-scale;
  // The amount we offset the label from the input text in the fill appearance.
  $fill-appearance-label-offset: -0.5em;

  .mat-form-field-appearance-fill {
    .mat-form-field-infix {
      padding: $infix-padding-top 0 $infix-padding-bottom 0;
    }

    .mat-form-field-label {
      top: $infix-margin-top + $infix-padding-top;
      margin-top: $fill-appearance-label-offset;
    }

    &.mat-form-field-can-float {
      &.mat-form-field-should-float .mat-form-field-label,
      .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
        @include _mat-form-field-fill-label-floating(
                $subscript-font-scale, $infix-padding-top + $fill-appearance-label-offset,
                $infix-margin-top);
      }

      // Server-side rendered matInput with a label attribute but label not shown
      // (used as a pure CSS stand-in for mat-form-field-should-float).
      .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper
      .mat-form-field-label {
        @include _mat-form-field-fill-label-floating(
                $subscript-font-scale, $infix-padding-top + $fill-appearance-label-offset,
                $infix-margin-top);
      }
    }
  }
}







// Theme styles that only apply to the legacy appearance of the form-field.

@mixin mat-form-field-legacy-theme($theme) {
  $foreground: map-get($theme, foreground);
  $is-dark-theme: map-get($theme, is-dark);

  $label-color: mat-color($foreground, secondary-text);
  $underline-color: mat-color($foreground, divider, if($is-dark-theme, 0.7, 0.42));

  .mat-form-field-appearance-legacy {
    .mat-form-field-label {
      color: $label-color;
    }

    .mat-hint {
      color: $label-color;
    }

    .mat-form-field-underline {
      background-color: $underline-color;
    }

    &.mat-form-field-disabled .mat-form-field-underline {
      @include mat-control-disabled-underline($underline-color);
    }
  }
}

// Used to make instances of the _mat-form-field-label-floating mixin negligibly different,
// and prevent Google's CSS Optimizer from collapsing the declarations. This is needed because some
// of the selectors contain pseudo-classes not recognized in all browsers. If a browser encounters
// an unknown pseudo-class it will discard the entire rule set.
$mat-form-field-legacy-dedupe: 0;

// Applies a floating label above the form field control itself.
@mixin _mat-form-field-legacy-label-floating($font-scale, $infix-padding, $infix-margin-top) {
  // We use perspective to fix the text blurriness as described here:
  // http://www.useragentman.com/blog/2014/05/04/fixing-typography-inside-of-2-d-css-transforms/
  // This results in a small jitter after the label floats on Firefox, which the
  // translateZ fixes.
  transform: translateY(-$infix-margin-top - $infix-padding) scale($font-scale) perspective(100px)
  translateZ(0.001px + $mat-form-field-legacy-dedupe);
  // The tricks above used to smooth out the animation on chrome and firefox actually make things
  // worse on IE, so we don't include them in the IE version.
  -ms-transform: translateY(-$infix-margin-top - $infix-padding + $mat-form-field-legacy-dedupe)
                  scale($font-scale);

  width: 100% / $font-scale + $mat-form-field-legacy-dedupe;

  $mat-form-field-legacy-dedupe: $mat-form-field-legacy-dedupe + 0.00001 !global;
}

// Same as mixin above, but omits the translateZ for printing purposes.
@mixin _mat-form-field-legacy-label-floating-print($font-scale, $infix-padding, $infix-margin-top) {
  // This results in a small jitter after the label floats on Firefox, which the
  // translateZ fixes.
  transform: translateY(-$infix-margin-top - $infix-padding + $mat-form-field-legacy-dedupe)
                  scale($font-scale);
  // The tricks above used to smooth out the animation on chrome and firefox actually make things
  // worse on IE, so we don't include them in the IE version.
  $mat-form-field-legacy-dedupe: $mat-form-field-legacy-dedupe + 0.00001 !global;
}

@mixin mat-form-field-legacy-typography($config) {
  // The unit-less line-height from the font config.
  $line-height: mat-line-height($config, input);
  // The amount to scale the font for the floating label and subscript.
  $subscript-font-scale: 0.75;
  // The amount of space between the top of the line and the top of the actual text
  // (as a fraction of the font-size).
  $line-spacing: ($line-height - 1) / 2;
  // The padding on the infix. Mocks show half of the text size, but seem to measure from the edge
  // of the text itself, not the edge of the line; therefore we subtract off the line spacing.
  $infix-padding: 0.5em - $line-spacing;
  // The margin applied to the form-field-infix to reserve space for the floating label.
  $infix-margin-top: 1em * $line-height * $subscript-font-scale;
  // The space between the bottom of the .mat-form-field-flex area and the subscript wrapper.
  // Mocks show half of the text size, but this margin is applied to an element with the subscript
  // text font size, so we need to divide by the scale factor to make it half of the original text
  // size. We again need to subtract off the line spacing since the mocks measure to the edge of the
  // text, not the  edge of the line.
  $subscript-margin-top: 0.5em / $subscript-font-scale - ($line-spacing * 2);
  // The padding applied to the form-field-wrapper to reserve space for the subscript, since it's
  // absolutely positioned. This is a combination of the subscript's margin and line-height, but we
  // need to multiply by the subscript font scale factor since the wrapper has a larger font size.
  $wrapper-padding-bottom: ($subscript-margin-top + $line-height) * $subscript-font-scale;

  .mat-form-field-appearance-legacy {
    .mat-form-field-wrapper {
      padding-bottom: $wrapper-padding-bottom;
    }

    .mat-form-field-infix {
      padding: $infix-padding 0;
    }

    &.mat-form-field-can-float {
      &.mat-form-field-should-float .mat-form-field-label,
      .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
        @include _mat-form-field-legacy-label-floating(
                $subscript-font-scale, $infix-padding, $infix-margin-top);
      }

      // @breaking-change 8.0.0 will rely on AutofillMonitor instead.
      .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper
      .mat-form-field-label {
        @include _mat-form-field-legacy-label-floating(
                $subscript-font-scale, $infix-padding, $infix-margin-top);
      }

      // Server-side rendered matInput with a label attribute but label not shown
      // (used as a pure CSS stand-in for mat-form-field-should-float).
      .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper
      .mat-form-field-label {
        @include _mat-form-field-legacy-label-floating(
                $subscript-font-scale, $infix-padding, $infix-margin-top);
      }
    }

    .mat-form-field-label {
      top: $infix-margin-top + $infix-padding;
    }

    .mat-form-field-underline {
      // We want the underline to start at the end of the content box, not the padding box,
      // so we move it up by the padding amount.
      bottom: $wrapper-padding-bottom;
    }

    .mat-form-field-subscript-wrapper {
      margin-top: $subscript-margin-top;

      // We want the subscript to start at the end of the content box, not the padding box,
      // so we move it up by the padding amount (adjusted for the smaller font size);
      top: calc(100% - #{$wrapper-padding-bottom / $subscript-font-scale});
    }
  }

  // translateZ causes the label to not appear while printing, so we override it to not
  // apply translateZ while printing
  @media print {
    .mat-form-field-appearance-legacy {
      &.mat-form-field-can-float {
        &.mat-form-field-should-float .mat-form-field-label,
        .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
          @include _mat-form-field-legacy-label-floating-print(
                  $subscript-font-scale, $infix-padding, $infix-margin-top);
        }

        // @breaking-change 8.0.0 will rely on AutofillMonitor instead.
        .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper
        .mat-form-field-label {
          @include _mat-form-field-legacy-label-floating-print(
                  $subscript-font-scale, $infix-padding, $infix-margin-top);
        }

        // Server-side rendered matInput with a label attribute but label not shown
        // (used as a pure CSS stand-in for mat-form-field-should-float).
        .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper
        .mat-form-field-label {
          @include _mat-form-field-legacy-label-floating-print(
                  $subscript-font-scale, $infix-padding, $infix-margin-top);
        }
      }
    }
  }
}







// Theme styles that only apply to the outline appearance of the form-field.

@mixin mat-form-field-outline-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);
  $is-dark-theme: map-get($theme, is-dark);

  $label-disabled-color: mat-color($foreground, disabled-text);
  $outline-color: mat-color($foreground, divider, if($is-dark-theme, 0.3, 0.12));
  $outline-color-hover: mat-color($foreground, divider, if($is-dark-theme, 1, 0.87));
  $outline-color-primary: mat-color($primary);
  $outline-color-accent: mat-color($accent);
  $outline-color-warn: mat-color($warn);
  $outline-color-disabled: mat-color($foreground, divider, if($is-dark-theme, 0.15, 0.06));

  .mat-form-field-appearance-outline {
    .mat-form-field-outline {
      color: $outline-color;
    }

    .mat-form-field-outline-thick {
      color: $outline-color-hover;
    }

    &.mat-focused {
      .mat-form-field-outline-thick {
        color: $outline-color-primary;
      }

      &.mat-accent .mat-form-field-outline-thick {
        color: $outline-color-accent;
      }

      &.mat-warn .mat-form-field-outline-thick {
        color: $outline-color-warn;
      }
    }

    // Class repeated so that rule is specific enough to override focused accent color case.
    &.mat-form-field-invalid.mat-form-field-invalid {
      .mat-form-field-outline-thick {
        color: $outline-color-warn;
      }
    }

    &.mat-form-field-disabled {
      .mat-form-field-label {
        color: $label-disabled-color;
      }

      .mat-form-field-outline {
        color: $outline-color-disabled;
      }
    }
  }
}

// Used to make instances of the _mat-form-field-label-floating mixin negligibly different,
// and prevent Google's CSS Optimizer from collapsing the declarations. This is needed because some
// of the selectors contain pseudo-classes not recognized in all browsers. If a browser encounters
// an unknown pseudo-class it will discard the entire rule set.
$mat-form-field-outline-dedupe: 0;

// Applies a floating label above the form field control itself.
@mixin _mat-form-field-outline-label-floating($font-scale, $infix-padding, $infix-margin-top) {
  transform: translateY(-$infix-margin-top - $infix-padding + $mat-form-field-outline-dedupe)
  scale($font-scale);
  width: 100% / $font-scale + $mat-form-field-outline-dedupe;

  $mat-form-field-outline-dedupe: $mat-form-field-outline-dedupe + 0.00001 !global;
}

@mixin mat-form-field-outline-typography($config) {
  // The unit-less line-height from the font config.
  $line-height: mat-line-height($config, input);
  // The amount to scale the font for the floating label and subscript.
  $subscript-font-scale: 0.75;
  // The padding above and below the infix.
  $infix-padding: 1em;
  // The margin applied to the form-field-infix to reserve space for the floating label.
  $infix-margin-top: 1em * $line-height * $subscript-font-scale;
  // The space between the bottom of the .mat-form-field-flex area and the subscript wrapper.
  // Mocks show half of the text size, but this margin is applied to an element with the subscript
  // text font size, so we need to divide by the scale factor to make it half of the original text
  // size.
  $subscript-margin-top: 0.5em / $subscript-font-scale;
  // The padding applied to the form-field-wrapper to reserve space for the subscript, since it's
  // absolutely positioned. This is a combination of the subscript's margin and line-height, but we
  // need to multiply by the subscript font scale factor since the wrapper has a larger font size.
  $wrapper-padding-bottom: ($subscript-margin-top + $line-height) * $subscript-font-scale;
  // The amount we offset the label from the input text in the outline appearance.
  $outline-appearance-label-offset: -0.25em;

  .mat-form-field-appearance-outline {
    .mat-form-field-infix {
      padding: $infix-padding 0 $infix-padding 0;
    }

    .mat-form-field-label {
      top: $infix-margin-top + $infix-padding;
      margin-top: $outline-appearance-label-offset;
    }

    &.mat-form-field-can-float {
      &.mat-form-field-should-float .mat-form-field-label,
      .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
        @include _mat-form-field-outline-label-floating(
                $subscript-font-scale, $infix-padding + $outline-appearance-label-offset,
                $infix-margin-top);
      }

      // Server-side rendered matInput with a label attribute but label not shown
      // (used as a pure CSS stand-in for mat-form-field-should-float).
      .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper
      .mat-form-field-label {
        @include _mat-form-field-outline-label-floating(
                $subscript-font-scale, $infix-padding + $outline-appearance-label-offset,
                $infix-margin-top);
      }
    }
  }
}







// Theme styles that only apply to the standard appearance of the form-field.

@mixin mat-form-field-standard-theme($theme) {
  $foreground: map-get($theme, foreground);
  $is-dark-theme: map-get($theme, is-dark);

  $underline-color: mat-color($foreground, divider, if($is-dark-theme, 0.7, 0.42));

  .mat-form-field-appearance-standard {
    .mat-form-field-underline {
      background-color: $underline-color;
    }

    &.mat-form-field-disabled .mat-form-field-underline {
      @include mat-control-disabled-underline($underline-color);
    }
  }
}

@mixin mat-form-field-standard-typography($config) {}


// Theme styles that apply to all appearances of the form-field.
@mixin mat-form-field-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark-theme: map-get($theme, is-dark);

  // Label colors. Required is used for the `*` star shown in the label.
  $label-color: mat-color($foreground, secondary-text, if($is-dark-theme, 0.7, 0.6));
  $focused-label-color: mat-color($primary, text);
  $required-label-color: mat-color($accent, text);

  // Underline colors.
  $underline-color-base: mat-color($foreground, divider, if($is-dark-theme, 1, 0.87));
  $underline-color-accent: mat-color($accent, text);
  $underline-color-warn: mat-color($warn, text);
  $underline-focused-color: mat-color($primary, text);

  .mat-form-field-label {
    color: $label-color;
  }

  .mat-hint {
    color: $label-color;
  }

  .mat-form-field.mat-focused .mat-form-field-label {
    color: $focused-label-color;

    &.mat-accent {
      color: $underline-color-accent;
    }

    &.mat-warn {
      color: $underline-color-warn;
    }
  }

  .mat-focused .mat-form-field-required-marker {
    color: $required-label-color;
  }

  .mat-form-field-ripple {
    background-color: $underline-color-base;
  }

  .mat-form-field.mat-focused {
    .mat-form-field-ripple {
      background-color: $underline-focused-color;

      &.mat-accent {
        background-color: $underline-color-accent;
      }

      &.mat-warn {
        background-color: $underline-color-warn;
      }
    }
  }

  .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) {
    .mat-form-field-infix::after {
      color: $underline-focused-color;
    }

    &.mat-accent .mat-form-field-infix::after {
      color: $underline-color-accent;
    }

    &.mat-warn .mat-form-field-infix::after {
      color: $underline-color-warn;
    }
  }

  // Styling for the error state of the form field. Note that while the same can be
  // achieved with the ng-* classes, we use this approach in order to ensure that the same
  // logic is used to style the error state and to show the error messages.
  .mat-form-field.mat-form-field-invalid {
    .mat-form-field-label {
      color: $underline-color-warn;

      &.mat-accent,
      .mat-form-field-required-marker {
        color: $underline-color-warn;
      }
    }

    .mat-form-field-ripple,
    .mat-form-field-ripple.mat-accent {
      background-color: $underline-color-warn;
    }
  }

  .mat-error {
    color: $underline-color-warn;
  }

  @include mat-form-field-legacy-theme($theme);
  @include mat-form-field-standard-theme($theme);
  @include mat-form-field-fill-theme($theme);
  @include mat-form-field-outline-theme($theme);
}

// Used to make instances of the _mat-form-field-label-floating mixin negligibly different,
// and prevent Google's CSS Optimizer from collapsing the declarations. This is needed because some
// of the selectors contain pseudo-classes not recognized in all browsers. If a browser encounters
// an unknown pseudo-class it will discard the entire rule set.
$mat-form-field-dedupe: 0;

// Applies a floating label above the form field control itself.
@mixin _mat-form-field-label-floating($font-scale, $infix-padding, $infix-margin-top) {
  transform: translateY(-$infix-margin-top - $infix-padding + $mat-form-field-dedupe)
             scale($font-scale);
  width: 100% / $font-scale + $mat-form-field-dedupe;

  $mat-form-field-dedupe: $mat-form-field-dedupe + 0.00001 !global;
}

@mixin mat-form-field-typography($config) {
  // The unit-less line-height from the font config.
  $line-height: mat-line-height($config, input);

  // The amount to scale the font for the floating label and subscript.
  $subscript-font-scale: 0.75;
  // The amount to scale the font for the prefix and suffix icons.
  $prefix-suffix-icon-font-scale: 1.5;

  // The padding on the infix. Mocks show half of the text size.
  $infix-padding: 0.5em;
  // The margin applied to the form-field-infix to reserve space for the floating label.
  $infix-margin-top: 1em * $line-height * $subscript-font-scale;
  // Font size to use for the label and subscript text.
  $subscript-font-size: $subscript-font-scale * 100%;
  // Font size to use for the for the prefix and suffix icons.
  $prefix-suffix-icon-font-size: $prefix-suffix-icon-font-scale * 100%;
  // The space between the bottom of the .mat-form-field-flex area and the subscript wrapper.
  // Mocks show half of the text size, but this margin is applied to an element with the subscript
  // text font size, so we need to divide by the scale factor to make it half of the original text
  // size.
  $subscript-margin-top: 0.5em / $subscript-font-scale;
  // The padding applied to the form-field-wrapper to reserve space for the subscript, since it's
  // absolutely positioned. This is a combination of the subscript's margin and line-height, but we
  // need to multiply by the subscript font scale factor since the wrapper has a larger font size.
  $wrapper-padding-bottom: ($subscript-margin-top + $line-height) * $subscript-font-scale;

  .mat-form-field {
    @include mat-typography-level-to-styles($config, input);
  }

  .mat-form-field-wrapper {
    padding-bottom: $wrapper-padding-bottom;
  }

  .mat-form-field-prefix,
  .mat-form-field-suffix {
    // Allow icons in a prefix or suffix to adapt to the correct size.
    .mat-icon {
      font-size: $prefix-suffix-icon-font-size;
      line-height: $line-height;
    }

    // Allow icon buttons in a prefix or suffix to adapt to the correct size.
    .mat-icon-button {
      height: $prefix-suffix-icon-font-scale * 1em;
      width: $prefix-suffix-icon-font-scale * 1em;

      .mat-icon {
        height: $line-height * 1em;
        line-height: $line-height;
      }
    }
  }

  .mat-form-field-infix {
    padding: $infix-padding 0;
    // Throws off the baseline if we do it as a real margin, so we do it as a border instead.
    border-top: $infix-margin-top solid transparent;
  }

  .mat-form-field-can-float {
    &.mat-form-field-should-float .mat-form-field-label,
    .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
      @include _mat-form-field-label-floating(
              $subscript-font-scale, $infix-padding, $infix-margin-top);
    }

    // Server-side rendered matInput with a label attribute but label not shown
    // (used as a pure CSS stand-in for mat-form-field-should-float).
    .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper
        .mat-form-field-label {
      @include _mat-form-field-label-floating(
              $subscript-font-scale, $infix-padding, $infix-margin-top);
    }
  }

  .mat-form-field-label-wrapper {
    top: -$infix-margin-top;
    padding-top: $infix-margin-top;
  }

  .mat-form-field-label {
    top: $infix-margin-top + $infix-padding;
  }

  .mat-form-field-underline {
    // We want the underline to start at the end of the content box, not the padding box,
    // so we move it up by the padding amount.
    bottom: $wrapper-padding-bottom;
  }

  .mat-form-field-subscript-wrapper {
    font-size: $subscript-font-size;
    margin-top: $subscript-margin-top;

    // We want the subscript to start at the end of the content box, not the padding box,
    // so we move it up by the padding amount (adjusted for the smaller font size);
    top: calc(100% - #{$wrapper-padding-bottom / $subscript-font-scale});
  }

  @include mat-form-field-legacy-typography($config);
  @include mat-form-field-standard-typography($config);
  @include mat-form-field-fill-typography($config);
  @include mat-form-field-outline-typography($config);
}





@mixin mat-tree-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .mat-tree {
    background: mat-color($background, 'card');
  }

  .mat-tree-node,
  .mat-nested-tree-node {
    color: mat-color($foreground, text);
  }
}

@mixin mat-tree-typography($config) {
  .mat-tree {
    font-family: mat-font-family($config);
  }

  .mat-tree-node,
  .mat-nested-tree-node {
    font-weight: mat-font-weight($config, body-1);
    font-size: mat-font-size($config, body-1);
  }
}



// Includes all of the typographic styles.
@mixin angular-material-typography($config: null) {
  @if $config == null {
    $config: mat-typography-config();
  }

  @include mat-badge-typography($config);
  @include mat-base-typography($config);
  @include mat-autocomplete-typography($config);
  @include mat-bottom-sheet-typography($config);
  @include mat-button-typography($config);
  @include mat-button-toggle-typography($config);
  @include mat-card-typography($config);
  @include mat-checkbox-typography($config);
  @include mat-chips-typography($config);
  @include mat-table-typography($config);
  @include mat-datepicker-typography($config);
  @include mat-dialog-typography($config);
  @include mat-expansion-panel-typography($config);
  @include mat-form-field-typography($config);
  @include mat-grid-list-typography($config);
  @include mat-icon-typography($config);
  @include mat-input-typography($config);
  @include mat-menu-typography($config);
  @include mat-paginator-typography($config);
  @include mat-progress-bar-typography($config);
  @include mat-progress-spinner-typography($config);
  @include mat-radio-typography($config);
  @include mat-select-typography($config);
  @include mat-sidenav-typography($config);
  @include mat-slide-toggle-typography($config);
  @include mat-slider-typography($config);
  @include mat-stepper-typography($config);
  @include mat-sort-typography($config);
  @include mat-tabs-typography($config);
  @include mat-toolbar-typography($config);
  @include mat-tooltip-typography($config);
  @include mat-list-typography($config);
  @include mat-option-typography($config);
  @include mat-optgroup-typography($config);
  @include mat-snack-bar-typography($config);
  @include mat-tree-typography($config);
}


// Mixin that renders all of the core styles that are not theme-dependent.
@mixin mat-core($typography-config: null) {
  @include angular-material-typography($typography-config);
  @include mat-ripple();
  @include cdk-a11y();
  @include cdk-overlay();
  @include cdk-text-field();
}

// Mixin that renders all of the core styles that depend on the theme.
@mixin mat-core-theme($theme) {
  @include mat-ripple-theme($theme);
  @include mat-option-theme($theme);
  @include mat-optgroup-theme($theme);
  @include mat-pseudo-checkbox-theme($theme);

  // Provides external CSS classes for each elevation value. Each CSS class is formatted as
  // `mat-elevation-z$zValue` where `$zValue` corresponds to the z-space to which the element is
  // elevated.
  @for $zValue from 0 through 24 {
    .#{$_mat-elevation-prefix}#{$zValue} {
      @include _mat-theme-elevation($zValue, $theme);
    }
  }

  // Wrapper element that provides the theme background when the user's content isn't
  // inside of a `mat-sidenav-container`. Note that we need to exclude the ampersand
  // selector in case the mixin is included at the top level.
  .mat-app-background#{if(&, ', &.mat-app-background', '')} {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    background-color: mat-color($background, background);
    color: mat-color($foreground, text);
  }

  // Marker that is used to determine whether the user has added a theme to their page.
  @at-root {
    .mat-theme-loaded-marker {
      display: none;
    }
  }
}
















@mixin mat-divider-theme($theme) {
  $foreground: map-get($theme, foreground);

  .mat-divider {
    border-top-color: mat-color($foreground, divider);
  }

  .mat-divider-vertical {
    border-right-color: mat-color($foreground, divider);
  }
}

























// Create a theme.
@mixin angular-material-theme($theme) {
  @include mat-core-theme($theme);
  @include mat-autocomplete-theme($theme);
  @include mat-badge-theme($theme);
  @include mat-bottom-sheet-theme($theme);
  @include mat-button-theme($theme);
  @include mat-button-toggle-theme($theme);
  @include mat-card-theme($theme);
  @include mat-checkbox-theme($theme);
  @include mat-chips-theme($theme);
  @include mat-table-theme($theme);
  @include mat-datepicker-theme($theme);
  @include mat-dialog-theme($theme);
  @include mat-divider-theme($theme);
  @include mat-expansion-panel-theme($theme);
  @include mat-form-field-theme($theme);
  @include mat-grid-list-theme($theme);
  @include mat-icon-theme($theme);
  @include mat-input-theme($theme);
  @include mat-list-theme($theme);
  @include mat-menu-theme($theme);
  @include mat-paginator-theme($theme);
  @include mat-progress-bar-theme($theme);
  @include mat-progress-spinner-theme($theme);
  @include mat-radio-theme($theme);
  @include mat-select-theme($theme);
  @include mat-sidenav-theme($theme);
  @include mat-slide-toggle-theme($theme);
  @include mat-slider-theme($theme);
  @include mat-stepper-theme($theme);
  @include mat-sort-theme($theme);
  @include mat-tabs-theme($theme);
  @include mat-toolbar-theme($theme);
  @include mat-tooltip-theme($theme);
  @include mat-tree-theme($theme);
  @include mat-snack-bar-theme($theme);
}
